import { ReactElement } from "react";

import { Swap } from "./Swap";

export function Trade(): ReactElement {
  return (
    <div className="relative w-full flex-1 space-y-8 z-10 flex flex-col justify-center">
      <Swap className="mx-auto" />
    </div>
  );
}
