import { Coin } from "sdk/entities/coin";

import { Action, ActionType } from "./actions";
import { Field } from "./liquidityReducer";

export function typeInput(
  dispatch: React.Dispatch<Action>,
  field: Field,
  typedValue: string,
  noLiquidity: boolean,
): void {
  dispatch({ type: ActionType.TYPE_INPUT, field, typedValue, noLiquidity });
}

export function setCoins(dispatch: React.Dispatch<Action>, field: Field, coin?: Coin): void {
  dispatch({ type: ActionType.SET_COINS, field, coin });
}

export function set2Coins(dispatch: React.Dispatch<Action>, coinA: Coin | undefined, coinB: Coin | undefined): void {
  dispatch({ type: ActionType.SET_2_COINS, coinA, coinB });
}

export function resetForm(dispatch: React.Dispatch<Action>): void {
  dispatch({ type: ActionType.RESET_FORM });
}

export function revertRate(dispatch: React.Dispatch<Action>): void {
  dispatch({ type: ActionType.REVERT_RATE });
}
