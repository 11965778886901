import { Coin } from "sdk/entities/coin";

import { Action, ActionType, Field } from "./actions";

export type State = {
  readonly typedValue: string;
  readonly [Field.INPUT]: {
    readonly coin: Coin | undefined;
  };
  readonly [Field.OUTPUT]: {
    readonly coin: Coin | undefined;
  };
};

export const initialState: State = {
  typedValue: "",
  [Field.INPUT]: {
    coin: undefined,
  },
  [Field.OUTPUT]: {
    coin: undefined,
  },
};

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.SELECT_COIN: {
      const otherField = action.field === Field.INPUT ? Field.OUTPUT : Field.INPUT;
      if (action.coin?.equals(state[otherField].coin)) {
        return {
          ...state,
          [action.field]: { coin: action.coin },
          [otherField]: { coin: state[action.field].coin },
          typedValue: initialState.typedValue,
        };
      }

      return {
        ...state,
        [action.field]: { coin: action.coin },
      };
    }
    case ActionType.TYPE_INPUT: {
      return {
        ...state,
        typedValue: action.typedValue,
      };
    }
    case ActionType.SWITCH_COINS: {
      return {
        ...state,
        [Field.INPUT]: state[Field.OUTPUT],
        [Field.OUTPUT]: state[Field.INPUT],
      };
    }
    case ActionType.RESET: {
      return {
        ...initialState,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
