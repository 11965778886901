import { Fragment, ReactElement } from "react";
import { WisppointResponse } from "api/type";
import { CheckIcon } from "components/atoms/icons/CheckIcon";
import { CloseIcon } from "components/atoms/icons/CloseIcon";
import { toCommaSeparated } from "sdk/utils/formatNumber";
import { shortenAddress } from "utils";

type Props = {
  data: WisppointResponse;
};

export function WisppointDetail({ data }: Props): ReactElement {
  const rolePoint = (data.roleWispSwapPoint ?? 0) + (data.accumulateRoleWispSwapPoint ?? 0);
  const totalPoint =
    rolePoint +
    (data.onchainActivityBonusWisppoint ?? 0) +
    (data.crew3Wisppoint ?? 0) +
    (data.mee6Wisppoint ?? 0) +
    (data.discordNameWisppoint ?? 0);
  return (
    <Fragment>
      <div className="flex items-center justify-between space-x-5">
        <div className="aspect-square shrink-0">
          <img
            className="w-20 h-20 rounded-full"
            src={data.avatarUrl || "https://pbs.twimg.com/profile_images/1601870692924157952/mHal1SHi_400x400.jpg"}
          />
        </div>
        <div className="flex-1 space-y-2">
          <div className="font-medium text-lg">{data.discordName}</div>
          <div className="font-semibold">
            <span className="text-4xl text-pGreen-500">{toCommaSeparated(totalPoint.toString())}</span>
            <br className="sm:hidden" />
            <span className="sm:ml-2 text-xl text-pGreen-400">Wisppoint</span>
          </div>
          <div className="text-pNeutral-500">{shortenAddress(data.address, 9)}</div>
        </div>
      </div>
      <div className="mt-6 space-y-2">
        <div className="border border-white border-opacity-10 rounded-md p-3 flex items-start justify-between bg-dark-600 hover:bg-dark-400 space-x-3">
          <div>
            <div className="font-medium">Onchain Activity Bonus</div>
            <div className="text-pNeutral-500 text-sm mt-2">
              <p>Today tasks:</p>
              <ul>
                <li className="flex items-center">
                  {data.todayTransactions?.tokenSwappedCount >= 5 ? (
                    <CheckIcon className="w-4 h-4 text-pGreen-500 mr-2 shrink-0" />
                  ) : (
                    <CloseIcon className="w-3 h-3 text-pRed-500 mr-3 shrink-0" />
                  )}{" "}
                  <span>
                    1. Swap token on &nbsp;<span className="text-pGreen-400">Trade</span>&nbsp; (
                    {data.todayTransactions?.tokenSwappedCount ?? 0} / 5)
                  </span>
                </li>
                <li className="flex items-center">
                  {data.todayTransactions?.liquidityAddedCount >= 5 ? (
                    <CheckIcon className="w-4 h-4 text-pGreen-500 mr-2 shrink-0" />
                  ) : (
                    <CloseIcon className="w-3 h-3 text-pRed-500 mr-3 shrink-0" />
                  )}{" "}
                  <span>
                    2. Add liquidity on &nbsp;<span className="text-pGreen-400">Pools</span>&nbsp; (
                    {data.todayTransactions?.liquidityAddedCount ?? 0} / 5)
                  </span>
                </li>
                <li className="flex items-center">
                  {(data.todayTransactions?.betBearCount ?? 0) + (data.todayTransactions?.betBullCount ?? 0) >= 5 ? (
                    <CheckIcon className="w-4 h-4 text-pGreen-500 mr-2 shrink-0" />
                  ) : (
                    <CloseIcon className="w-3 h-3 text-pRed-500 mr-3 shrink-0" />
                  )}{" "}
                  <span>
                    3. Make predictions on &nbsp;<span className="text-pGreen-400">Prediction</span>&nbsp; (
                    {(data.todayTransactions?.betBearCount ?? 0) + (data.todayTransactions?.betBullCount ?? 0)} / 5)
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="font-medium text-pGreen-500 text-lg">
            +{toCommaSeparated(data.onchainActivityBonusWisppoint.toString())}
          </div>
        </div>
        <div className="border border-white border-opacity-10 rounded-md p-3 flex items-start justify-between bg-dark-600 hover:bg-dark-400 space-x-3">
          <div>
            <div className="font-medium">ZEALY</div>
            <div className="text-pNeutral-500 text-sm">
              {data.crew3Xp ? toCommaSeparated(data.crew3Xp.toString()) : "ZEALY xp"} * {data.crew3Multiplier}
            </div>
          </div>
          <div className="font-medium text-pGreen-500 text-lg">
            +{toCommaSeparated(data.crew3Wisppoint?.toString() ?? "0")}
          </div>
        </div>
        <div className="border border-white border-opacity-10 rounded-md p-3 flex items-start justify-between bg-dark-600 hover:bg-dark-400 space-x-3">
          <div>
            <div className="font-medium">MEE6</div>
            <div className="text-pNeutral-500 text-sm">
              {data.mee6Xp ? toCommaSeparated(data.mee6Xp.toString()) : "MEE6 xp"} * {data.mee6Multiplier}
            </div>
          </div>
          <div className="font-medium text-pGreen-500 text-lg">
            +{toCommaSeparated(data.mee6Wisppoint?.toString() ?? "0")}
          </div>
        </div>
        <div className="border border-white border-opacity-10 rounded-md p-3 flex items-start justify-between bg-dark-600 hover:bg-dark-400 space-x-3">
          <div>
            <div className="font-medium">Discord Name Suffix</div>
            <div className="text-pNeutral-500 text-sm">No.days * 30</div>
          </div>
          <div className="font-medium text-pGreen-500 text-lg">
            +{toCommaSeparated(data.discordNameWisppoint?.toString() ?? "0")}
          </div>
        </div>
        <div className="border border-white border-opacity-10 rounded-md p-3 flex items-start justify-between bg-dark-600 hover:bg-dark-400 space-x-3">
          <div>
            <div className="font-medium">Discord Role Point</div>
            <div className="text-pNeutral-500 text-sm mt-1">{data.roles?.map((role) => role.name).join(", ")}</div>
          </div>
          <div className="font-medium text-pGreen-500 text-lg">+{toCommaSeparated(rolePoint.toString())}</div>
        </div>
        <div className="mt-5 text-sm text-pNeutral-500">
          <span className="text-orange-600 font-medium mr-1">Note:</span>Your point is refreshed every 1 hour.
        </div>
      </div>
    </Fragment>
  );
}
