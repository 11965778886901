import { ReactElement, useEffect, useMemo, useReducer, useState } from "react";
import { TransactionBlock } from "@mysten/sui.js";
import { useWallet } from "@suiet/wallet-kit";
import CoinAPI from "api/coin";
import clsx from "clsx";
import { AssetLogo } from "components/atoms/AssetLogo";
import { Button } from "components/atoms/Button";
import { ArrowLeftIcon } from "components/atoms/icons/ArrowLeftIcon";
import { SettingsIcon } from "components/atoms/icons/SettingsIcon";
import { RangeSlider } from "components/atoms/RangeSlider";
import { ToggleButton, ToggleButtonGroup } from "components/atoms/ToggleButton";
import { SettingsModal } from "components/molecules/SettingsModal";
import { useWalletHelper } from "contexts/WalletHelperContext";
import { useWispSettings } from "contexts/WispSettingsContext";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Amount } from "sdk/entities/amount";
import { Coin } from "sdk/entities/coin";
import { calculateSlippageAmount } from "sdk/utils/calculateSlippageAmount";
import { SUI } from "utils/coins";
import { PATH, WISP_CONFIG } from "utils/constant";
import { extractGasPayment, getSuiObjectRefs } from "utils/getCoinObjectIds";

import { useRemoveLiquidity } from "./hooks/hooks";
import * as ActionHandlers from "./reducers/actionHandlers";
import * as Reducers from "./reducers/reducers";

const percentList = [25, 50, 75, 100];

export function RemoveLiquidity({ className }: { className?: string }): ReactElement {
  const suietWallet = useWallet();

  const adapter = suietWallet.adapter;
  const address = suietWallet.address;

  const { settings } = useWispSettings();
  const { setOpenSelectWallet, signAndExecuteTransaction } = useWalletHelper();

  const [isOpenSettingModal, setIsOpenSettingModal] = useState<boolean>(false);
  const [isBuildingTx, setIsBuildingTx] = useState<boolean>(false);

  const [params] = useSearchParams();
  const coinAType = params.get("coinA");
  const coinBType = params.get("coinB");
  const navigate = useNavigate();

  useEffect(() => {
    if (!coinAType || !coinBType) {
      return;
    }
    CoinAPI.getCoinInfos(settings.networkEnv, [coinAType, coinBType]).then((resp) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const coins = resp.data?.map((piece: any) => {
        return new Coin(piece.package_addr, piece.module, piece.type, piece.decimal ?? 0, piece.treasury_addr, {
          imageUrl: piece.icon_url,
          ticker: piece.symbol,
          projectName: piece.symbol,
          description: piece.description,
        });
      });
      if (coins.length >= 2) {
        ActionHandlers.setAssets(dispatch, coins[0] ?? undefined, coins[1] ?? undefined);
      }
    });
  }, [coinAType, coinBType, settings.networkEnv]);

  const [state, dispatch] = useReducer(Reducers.reducer, Reducers.initialState);
  const { assetA, assetB } = state;
  const { amountAssetA, amountAssetB, removeLPPercentage, liquidity, error } = useRemoveLiquidity(
    state,
    settings.customRPC,
    settings.networkEnv,
  );

  const minimumAmountAReceived: Amount | null = useMemo(() => {
    if (!amountAssetA || !assetA) {
      return null;
    }
    return Amount.fromRawAmount(assetA, calculateSlippageAmount(amountAssetA, settings.slippageTolerance)[0]);
  }, [amountAssetA, assetA, settings.slippageTolerance]);

  const minimumAmountBReceived: Amount | null = useMemo(() => {
    if (!amountAssetB || !assetB) {
      return null;
    }
    return Amount.fromRawAmount(assetB, calculateSlippageAmount(amountAssetB, settings.slippageTolerance)[0]);
  }, [amountAssetB, assetB, settings.slippageTolerance]);

  function handleUserInput(field: Reducers.Field, typedValue: string): void {
    ActionHandlers.typeInput(dispatch, field, typedValue);
  }

  function handleLiquidityPercentChange(value: number): void {
    handleUserInput(Reducers.Field.LIQUIDITY_PERCENT, value.toString());
  }

  async function handleRemoveLiquidity(): Promise<void> {
    if (isBuildingTx || !address || !liquidity || !liquidity.coin.coinA?.type || !liquidity.coin.coinB?.type) {
      return;
    }
    try {
      setIsBuildingTx(true);
      const objectRefs = await getSuiObjectRefs(settings.customRPC, address, [SUI, liquidity.coin]);
      const suiObjectRefs = objectRefs[SUI.type] ?? [];
      const liquidityObjectRefs = objectRefs[liquidity.coin.type] ?? [];
      const [suiGas] = extractGasPayment(suiObjectRefs, 100_000_000n);
      const tx = new TransactionBlock();
      tx.moveCall({
        target: `${WISP_CONFIG.dex.swapPackage}::${WISP_CONFIG.dex.swapModuleRouter}::${WISP_CONFIG.dex.functions.removeLiquidity}`,
        typeArguments: [liquidity.coin.coinA.type, liquidity.coin.coinB.type],
        arguments: [
          tx.object(WISP_CONFIG.dex.swapSetting),
          tx.makeMoveVec({
            objects: liquidityObjectRefs.map((i) => {
              return tx.object(i.objectId);
            }),
          }),
          tx.pure(liquidity.quotient.toString()),
          tx.pure("0"),
          tx.pure("0"),
        ],
      });
      tx.setGasBudget(100_000_000);
      tx.setGasPayment(suiGas);
      await signAndExecuteTransaction(tx);
      ActionHandlers.clearForm(dispatch);
      navigate(PATH.POOL);
    } catch (e) {
      console.error(e);
    } finally {
      setIsBuildingTx(false);
    }
  }

  return (
    <div className={clsx("bg-dark-600 p-8 rounded-2xl max-w-md w-full space-y-8", className)}>
      <div className="flex justify-between items-center">
        <div className="flex space-x-5">
          <div className="text-xl font-bold flex items-center">
            <Link to="/pools">
              <ArrowLeftIcon className="mr-4" />
            </Link>
            Remove Liquidity
          </div>
        </div>
        <div className="shrink-0 cursor-pointer" onClick={(): void => setIsOpenSettingModal(true)}>
          <SettingsIcon className="h-6 w-6" />
        </div>
      </div>
      <div>
        <div>
          <div className="mb-4">Amount</div>
          <RangeSlider
            max={100}
            min={0}
            step={1}
            value={Number(removeLPPercentage.multiply(100n).quotient)}
            onChange={handleLiquidityPercentChange}
          />
          <ToggleButtonGroup<number>
            className="mt-5"
            value={Number(removeLPPercentage.multiply(100n).quotient)}
            onChange={handleLiquidityPercentChange}
          >
            {percentList.map((percent) => (
              <ToggleButton<number> aria-label={`${percent} %`} key={percent} value={percent}>
                {percent}%
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
        {!!minimumAmountAReceived && !!minimumAmountBReceived && (
          <div className="mt-10">
            <div className="mb-4">You will receive</div>
            <div className="p-4 border border-white border-opacity-20 rounded-lg">
              <div className="flex justify-between items-center space-x-4 py-2">
                <div className="flex items-center space-x-3">
                  <AssetLogo asset={minimumAmountAReceived.coin} />
                  <div>{minimumAmountAReceived.coin.name}</div>
                </div>
                <div>{minimumAmountAReceived.toExact()}</div>
              </div>
              <div className="flex justify-between items-center space-x-4 py-2">
                <div className="flex items-center space-x-3">
                  <AssetLogo asset={minimumAmountBReceived.coin} />
                  <div>{minimumAmountBReceived.coin.name}</div>
                </div>
                <div>{minimumAmountBReceived.toExact()}</div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        {!adapter ? (
          <Button
            className="px-6 py-3 w-full text-xl font-semibold font-Poppins whitespace-pre-wrap"
            onClick={(): void => setOpenSelectWallet(true)}
          >
            Connect Wallet
          </Button>
        ) : (
          <Button
            className="px-6 py-3 w-full text-xl font-semibold font-Poppins whitespace-pre-wrap"
            disabled={Boolean(error || isBuildingTx)}
            isLoading={isBuildingTx}
            onClick={handleRemoveLiquidity}
          >
            {error ?? (isBuildingTx ? "Executing" : "Remove")}
          </Button>
        )}
      </div>
      <SettingsModal isOpen={isOpenSettingModal} onClose={(): void => setIsOpenSettingModal(false)} />
    </div>
  );
}
