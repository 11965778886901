import { Coin } from "sdk/entities/coin";

import { Action, ActionType } from "./actions";

export enum Field {
  LIQUIDITY_PERCENT = "LIQUIDITY_PERCENT",
  LIQUIDITY = "LIQUIDITY",
  ASSET_A = "ASSET_A",
  ASSET_B = "ASSET_B",
}

export interface State {
  readonly assetA?: Coin;
  readonly assetB?: Coin;
  readonly independentField: Field;
  readonly typedValue: string;
}

export const initialState: State = {
  independentField: Field.LIQUIDITY_PERCENT,
  typedValue: "0",
};

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.TYPE_INPUT: {
      return {
        ...state,
        independentField: action.field,
        typedValue: action.typedValue,
      };
    }
    case ActionType.SET_ASSET: {
      return {
        ...state,
        assetA: action.assetA,
        assetB: action.assetB,
      };
    }
    case ActionType.CLEAR_FORM: {
      return {
        ...initialState,
      };
    }
  }
}
