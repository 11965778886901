import { ReactElement } from "react";
import clsx from "clsx";
import { DownIcon } from "components/atoms/icons/DownIcon";
import { ExpiredStatusIcon } from "components/atoms/icons/ExpiredStatusIcon";
import { LiveStatusIcon } from "components/atoms/icons/LiveStatusIcon";
import { UpIcon } from "components/atoms/icons/UpIcon";
import { useProgress } from "hooks/useProgress";
import { toCommaSeparated } from "sdk/utils/formatNumber";

import { PRICE_STATUS, ROUND_STATUS } from "../constant";
import { Round } from "../types";
import { getStatusText } from "../utils";
import { Payout } from "./Payout";
import { Progress } from "./Progress";

type Props = {
  round: Round;
  className?: string;
};

export function NormalPayoutCardContent({ round, className }: Props): ReactElement {
  const progress = useProgress(round.status === ROUND_STATUS.LIVE ? round.closeTime : null);

  const isOvertime = round.status === ROUND_STATUS.LIVE && round.closeTime.getTime() < Date.now();

  return (
    <div
      className={clsx(
        "rounded-2xl bg-dark-600 overflow-hidden",
        round.status !== ROUND_STATUS.LIVE && "opacity-50",
        className,
      )}
    >
      <div className={"flex justify-between items-center space-x-2 p-4 bg-[#1E5473]"}>
        <span>{round.status !== ROUND_STATUS.LIVE ? <ExpiredStatusIcon /> : <LiveStatusIcon />}</span>
        <span className="flex-1 uppercase font-semibold">{getStatusText(round.status)}</span>
        <div className="font-semibold text-sm ml-2">#{round.epoch}</div>
      </div>
      {round.status === ROUND_STATUS.LIVE && <Progress cls={{ track: "-mt-0.5" }} progress={progress} />}
      <div className="px-6">
        <Payout
          isActive={round.priceStatus === PRICE_STATUS.BULL}
          payout={round.upPayout ? round.upPayout.toFixed(2) : "0"}
          type="up"
        />
        {isOvertime ? (
          <div className="border-2 border-white border-opacity-20 rounded-3xl px-5 py-7 mx-5 text-center">
            Round processing...
          </div>
        ) : (
          <div className="space-y-3">
            <div className="flex space-x-2 justify-between items-center">
              <span className="grow font-semibold opacity-30 text-pNeutral-800 text-sm">Last Price</span>
              {round.priceStatus !== PRICE_STATUS.UN_CHANGE && (
                <div
                  className={clsx("px-2 py-1 flex justify-between items-center space-x-1 text-dark-600 rounded", {
                    "bg-pRed-500": round.priceStatus === PRICE_STATUS.BEAR,
                    "bg-pGreen-500": round.priceStatus === PRICE_STATUS.BULL,
                  })}
                >
                  <span>{round.priceStatus === PRICE_STATUS.BEAR ? <DownIcon /> : <UpIcon />}</span>
                  <span className="font-semibold text-xs">
                    ${toCommaSeparated(round.deltaChange?.toFixed(2) ?? "-")}
                  </span>
                </div>
              )}
              <span className="font-semibold text-sm text-pNeutral-800">
                $
                {toCommaSeparated(
                  (round.closePrice && round.closePrice > 0 ? round.closePrice : undefined)?.toFixed(2) ??
                    round.currentPrice?.toFixed(2) ??
                    "-",
                )}
              </span>
            </div>

            <div className="flex space-x-2 justify-between items-center">
              <span className="grow font-semibold opacity-30 text-pNeutral-800 text-sm">Locked Price</span>
              <span className="font-semibold text-sm text-pNeutral-800">
                ${toCommaSeparated(round.lockPrice?.toString() ?? "-")}
              </span>
            </div>

            <div className="space-y-1">
              <div className="font-semibold opacity-30 text-pNeutral-800 text-sm">Total Commit Amount</div>
              <div className="flex space-x-2 justify-between items-center">
                <span className="grow font-semibold opacity-30 text-pNeutral-800 text-sm">Up</span>
                <span className="font-semibold text-sm text-pGreen-500">
                  {round.upAmount ? round.upAmount.toExact() : "--"}{" "}
                  {round.upAmount?.coin.metadata?.ticker ?? "WispSUI"}
                </span>
              </div>
              <div className="flex space-x-2 justify-between items-center">
                <span className="grow font-semibold opacity-30 text-pNeutral-800 text-sm">Down</span>
                <span className="font-semibold text-sm text-pRed-500">
                  {round.downAmount ? round.downAmount.toExact() : "--"}{" "}
                  {round.downAmount?.coin.metadata?.ticker ?? "WispSUI"}
                </span>
              </div>
            </div>

            <div className="flex space-x-2 justify-between items-center">
              <span className="grow font-semibold opacity-30 text-pNeutral-800 text-sm">Prize Amount</span>
              <span className="font-semibold text-sm text-pNeutral-800">
                {round.rewardAmount ? round.rewardAmount.toExact() : "--"}{" "}
                {round.rewardAmount?.coin.metadata?.ticker ?? "WispSUI"}
              </span>
            </div>
          </div>
        )}
        <Payout
          isActive={round.priceStatus === PRICE_STATUS.BEAR}
          payout={round.downPayout ? round.downPayout.toFixed(2) : "0"}
          type="down"
        />
      </div>
    </div>
  );
}
