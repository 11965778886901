import { AxiosResponse } from "axios";
import { NetworkEnv } from "utils/constant";

import http from "./http";

function getTradeHistory(networkEnv: NetworkEnv): Promise<AxiosResponse> {
  return http[networkEnv].get("/transactions/swap");
}

const DexAPI = {
  getTradeHistory,
};

export default DexAPI;
