export enum ROUND_STATUS {
  LIVE = 1,
  EXPIRED = 2,
  LATER = 3,
  NEXT = 4,
  CANCEL = 10,
}

export enum PRICE_STATUS {
  BEAR,
  UN_CHANGE,
  BULL,
}

export const TIME_PER_PHASE = 300_000; // 5 mins

export enum PREDICTION_HISTORY_FILTER {
  "ALL" = 1,
  "COLLECTED" = 2,
  "UNCOLLECTED" = 3,
}

export enum USER_POSITION {
  BEAR = "BEAR",
  BULL = "BULL",
}

export enum HISTORY_CLAIM {
  UNCLAIMMED = 0,
  CLAIMED = 1,
}

export enum BET_STATUS {
  WIN = 1,
  LOSE = 2,
  DRAW = 0,
  WAITING = 3,
}

export enum LeaderboardRankBy {
  NET_WINNINGS = 1,
  ROUND_PLAYED = 2,
  TOTAL = 3,
  WIN_RATE = 4,
}
