import { ReactElement, useEffect, useMemo, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { useWallet } from "@suiet/wallet-kit";
import clsx from "clsx";
import { Button } from "components/atoms/Button";
import { Modal } from "components/atoms/Modal";
import { Switch } from "components/atoms/Switch";
import { useWalletHelper } from "contexts/WalletHelperContext";
import { BOOST_RATE } from "modules/earn/constants";
import { Amount } from "sdk/entities/amount";
import { toCommaSeparated } from "sdk/utils/formatNumber";
import { isTooManyDecimals, tryParseAmount } from "sdk/utils/tryParseAmount";
import { FarmWithNft } from "types/farm";
import { VeWISP } from "utils/coins";

import { AssetInput } from "./AssetInput";

type Props = {
  farm: FarmWithNft | undefined;
  isOpen: boolean;
  isLoading: boolean;
  veWispBalance: Amount;
  onClose: () => void;
  onBoostClick: (amount: Amount, farm: FarmWithNft, period: number) => void;
};

export function BoostModal({ farm, isOpen, isLoading, veWispBalance, onClose, onBoostClick }: Props): ReactElement {
  const { adapter } = useWallet();
  const { setOpenSelectWallet } = useWalletHelper();
  const [typedValue, setTypedValue] = useState("");

  const [isLock, setIsLock] = useState<boolean>(false);
  const [lockPeriod, setLockPeriod] = useState<number>(0);

  useEffect(() => {
    return () => {
      if (!isOpen) {
        setTypedValue("");
        setIsLock(false);
        setLockPeriod(0);
      }
    };
  }, [isOpen]);

  const amount = useMemo(() => {
    if (!typedValue) {
      return undefined;
    }
    return tryParseAmount(typedValue, VeWISP);
  }, [typedValue]);

  const errorMsg: string | null = useMemo(() => {
    if (!veWispBalance || veWispBalance.equalTo(0)) {
      return "Empty balance";
    }
    if (!amount) {
      return "Enter an amount";
    }
    if (veWispBalance?.lessThan(amount)) {
      return "Insufficient balance";
    }
    return null;
  }, [amount, veWispBalance]);

  const handleChangeInput = (value: string): void => {
    if (isTooManyDecimals(value, VeWISP)) {
      return;
    }
    setTypedValue(value);
  };

  const handleMaxInput = (): void => {
    if (!veWispBalance) {
      return;
    }
    setTypedValue(veWispBalance.toExact({ groupSeparator: "" }));
  };

  const handleStakeClick = (): void => {
    if (!farm || !amount || !amount.greaterThan(0)) {
      return;
    }
    onBoostClick(amount, farm, isLock ? lockPeriod : 0);
  };

  return (
    <Modal isOpen={isOpen} title="Boost Modal" onClose={onClose}>
      <div className="space-y-5">
        <div>Enter veWisp amount you want to boost</div>
        <AssetInput
          asset={VeWISP}
          balance={veWispBalance}
          label="Amount"
          otherAsset={undefined}
          value={toCommaSeparated(typedValue)}
          fixedCoin
          maxAmount
          onInputChange={handleChangeInput}
          onMaxAmount={handleMaxInput}
          onSelect={(): void => {}}
        />
        <div className="space-y-3">
          <div className="flex items-start justify-between space-x-4">
            <div>
              <div className="text-pNeutral-800 font-semibold">Lock your veWisp</div>
              <p className="text-sm text-pNeutral-500">To get more rewards</p>
            </div>
            <Switch enabled={isLock} onChange={(val): void => setIsLock(val)} />
          </div>
          {isLock && (
            <div>
              <div className="text-pNeutral-800 font-semibold">Locking Period</div>
              <div className="mt-2">
                <RadioGroup
                  className="grid grid-cols-2 gap-4"
                  value={lockPeriod}
                  onChange={(val): void => setLockPeriod(val)}
                >
                  {BOOST_RATE.map((rate) => {
                    return (
                      <RadioGroup.Option key={rate.name} value={rate.name}>
                        {({ checked }): ReactElement => (
                          <div
                            className={clsx("flex items-center space-x-2 cursor-pointer", checked ? "" : "opacity-50")}
                          >
                            {checked ? (
                              <div className="shrink-0 w-4 h-4 rounded-full border border-pGreen-400 bg-pGreen-500"></div>
                            ) : (
                              <div className="shrink-0 w-4 h-4 rounded-full border border-gray-200"></div>
                            )}
                            <div>
                              <div>{rate.string}</div>
                              <p className="text-sm text-pNeutral-500">Earn more {rate.value / 100}% rewards</p>
                            </div>
                          </div>
                        )}
                      </RadioGroup.Option>
                    );
                  })}
                </RadioGroup>
              </div>
            </div>
          )}
        </div>
        <div>
          {!adapter ? (
            <Button
              className="px-6 py-3 w-full text-xl font-Poppins whitespace-pre-wrap"
              onClick={(): void => setOpenSelectWallet(true)}
            >
              Connect Wallet
            </Button>
          ) : (
            <Button
              className="px-6 py-3 w-full text-xl font-Poppins whitespace-pre-wrap"
              disabled={Boolean(errorMsg)}
              isLoading={isLoading}
              onClick={handleStakeClick}
            >
              {errorMsg ?? (isLoading ? "Processing" : "Boost")}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}
