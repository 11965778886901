import { AxiosResponse } from "axios";
import { NetworkEnv } from "utils/constant";

import http from "./http";

type IPair = {
  coinAType: string;
  coinBType: string;
};

function getAllPools(networkEnv: NetworkEnv, searchTerm: string, page: number): Promise<AxiosResponse> {
  return http[networkEnv].post("/pools", {
    searchTerm,
    page,
    pageSize: 10,
  });
}

function getPoolInfos(networkEnv: NetworkEnv, pairs: IPair[]): Promise<AxiosResponse> {
  return http[networkEnv].post("/pools", {
    pairs,
    page: 1,
    pageSize: pairs.length + 10,
  });
}

function getPoolInfosByOneSide(
  networkEnv: NetworkEnv,
  coinTypes: string[],
  searchTerm: string,
): Promise<AxiosResponse> {
  return http[networkEnv].post("/pools", {
    searchTerm,
    coinTypes,
    page: 1,
    pageSize: 20,
  });
}

const LiquidityAPI = {
  getAllPools,
  getPoolInfos,
  getPoolInfosByOneSide,
};

export default LiquidityAPI;
