import { useEffect, useState } from "react";
import { TIME_PER_PHASE } from "modules/prediction/constant";

export function useProgress(date: Date | null): number {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!date) {
      return;
    }
    const interval = setInterval(() => {
      setProgress(((TIME_PER_PHASE - (date.getTime() - Date.now())) / TIME_PER_PHASE) * 100);
    }, 1000);
    return (): void => {
      !!interval && clearInterval(interval);
    };
  }, [date]);

  return progress < 0 ? 0 : progress > 100 ? 100 : progress;
}
