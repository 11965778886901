import { ReactElement } from "react";

import { IconProps } from "./type";

export function ExpiredStatusIcon(props: IconProps): ReactElement<IconProps> {
  return (
    <svg fill="none" height="21" viewBox="0 0 20 21" width="20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.99984 18.8337C5.39734 18.8337 1.6665 15.1028 1.6665 10.5003C1.6665 5.89783 5.39734 2.16699 9.99984 2.16699C14.6023 2.16699 18.3332 5.89783 18.3332 10.5003C18.3332 15.1028 14.6023 18.8337 9.99984 18.8337ZM9.99984 17.167C11.7679 17.167 13.4636 16.4646 14.7139 15.2144C15.9641 13.9641 16.6665 12.2684 16.6665 10.5003C16.6665 8.73222 15.9641 7.03652 14.7139 5.78628C13.4636 4.53604 11.7679 3.83366 9.99984 3.83366C8.23173 3.83366 6.53604 4.53604 5.28579 5.78628C4.03555 7.03652 3.33317 8.73222 3.33317 10.5003C3.33317 12.2684 4.03555 13.9641 5.28579 15.2144C6.53604 16.4646 8.23173 17.167 9.99984 17.167ZM9.99984 9.32199L12.3565 6.96449L13.5357 8.14366L11.1782 10.5003L13.5357 12.857L12.3565 14.0362L9.99984 11.6787L7.64317 14.0362L6.464 12.857L8.8215 10.5003L6.464 8.14366L7.64317 6.96449L9.99984 9.32199Z"
        fill="currentColor"
      />
    </svg>
  );
}
