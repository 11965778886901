import { ReactElement } from "react";

import { Checkbox } from "./Checkbox";

type CheckboxItem = {
  id: number;
  label: string;
};

type Props = {
  checkedOption: CheckboxItem;
  options: CheckboxItem[];
  className?: string;
  onChange: (_: CheckboxItem) => void;
};

export function CheckboxGroup({ checkedOption, options, className, onChange }: Props): ReactElement {
  return (
    <div className={className}>
      {options.map((op) => (
        <Checkbox
          checked={op.id === checkedOption.id}
          key={op.id}
          label={op.label}
          onClick={(): void => onChange(op)}
        />
      ))}
    </div>
  );
}
