import { ReactElement, ReactNode, useMemo, useReducer, useState } from "react";
import { TransactionArgument, TransactionBlock } from "@mysten/sui.js";
import { useWallet } from "@suiet/wallet-kit";
import { Button } from "components/atoms/Button";
import { NoteIcon } from "components/atoms/icons/NoteIcon";
import { PlusIcon } from "components/atoms/icons/PlusIcon";
import { RevertIcon } from "components/atoms/icons/RevertIcon";
import { Tooltip, TooltipButton, TooltipContent } from "components/atoms/Tooltip";
import { AssetInput } from "components/molecules/AssetInput";
import { PoolInput } from "components/molecules/PoolInput";
import { useWalletHelper } from "contexts/WalletHelperContext";
import { useWispSettings } from "contexts/WispSettingsContext";
import useDebounce from "hooks/useDebounce";
import { usePoolsByOneSide } from "hooks/usePools";
import { Coin } from "sdk/entities/coin";
import { Pool } from "sdk/entities/pool";
import { ONE_BIPS } from "sdk/misc";
import { calculateSlippageAmount } from "sdk/utils/calculateSlippageAmount";
import { toCommaSeparated } from "sdk/utils/formatNumber";
import { computeRealizedLPFeePercent, WarningSeverity, warningSeverity } from "sdk/utils/prices";
import { isTooManyDecimals } from "sdk/utils/tryParseAmount";
import { SUI } from "utils/coins";
import { WISP_CONFIG } from "utils/constant";
import { extractGasPayment, getSuiObjectRefs } from "utils/getCoinObjectIds";

import { useZapIn } from "./hooks/hooks";
import * as ActionHandler from "./reducers/actionHandlers";
import { initialState, reducer } from "./reducers/zapReducers";

export function ZapInForm(): ReactElement {
  const suietWallet = useWallet();
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const adapter = suietWallet.adapter;
  const address = suietWallet.address;

  const { settings } = useWispSettings();
  const { fungibleBalances, setOpenSelectWallet, signAndExecuteTransaction } = useWalletHelper();

  const [state, dispatch] = useReducer(reducer, initialState);
  const { asset, pool, typedValue } = state;
  const { balance, amount, trade, liquidityMinted, poolTokenPercentage, error, poolOnChain } = useZapIn(
    state,
    settings.customRPC,
  );

  const [isBuildingTx, setIsBuildingTx] = useState<boolean>(false);

  const coinTypes = useMemo(() => {
    return asset ? [asset.type] : [];
  }, [asset]);

  const { pools } = usePoolsByOneSide({
    coinTypes: coinTypes,
    networkEnv: settings.networkEnv,
    rpcEndpoint: settings.customRPC,
    searchTerm: debouncedSearchTerm,
  });

  const assetBalances = useMemo(() => {
    return fungibleBalances ?? [];
  }, [fungibleBalances]);

  const { realizedLPFee, priceImpact } = useMemo(() => {
    if (!trade) return { realizedLPFee: undefined, priceImpact: undefined };

    const realizedLpFeePercent = computeRealizedLPFeePercent(trade);
    const realizedLPFee = trade.inputAmount.multiply(realizedLpFeePercent);
    const priceImpact = trade.priceImpact.subtract(realizedLpFeePercent);
    return { priceImpact, realizedLPFee };
  }, [trade]);

  const minimumLPReceived: bigint | null = useMemo(() => {
    if (!liquidityMinted) {
      return null;
    }
    return calculateSlippageAmount(liquidityMinted, settings.slippageTolerance)[0];
  }, [liquidityMinted, settings.slippageTolerance]);

  const handleSelectAsset = (asset?: Coin): void => {
    ActionHandler.setAsset(dispatch, asset);
  };

  const handleSelectPool = async (pool?: Pool): Promise<void> => {
    ActionHandler.setPool(dispatch, pool);
  };

  const handleInputChange = (value: string): void => {
    if (isTooManyDecimals(value, asset)) {
      return;
    }
    ActionHandler.typeInput(dispatch, value);
  };

  const handleMaxAsset = (): void => {
    handleInputChange(balance?.toExact({ groupSeparator: "" }) ?? "");
  };

  async function handleZapIn(): Promise<void> {
    if (isBuildingTx || !address || !asset || !amount || !poolOnChain) {
      return;
    }
    try {
      setIsBuildingTx(true);
      const objectRefs = await getSuiObjectRefs(settings.customRPC, address, [SUI, asset]);
      const suiObjectRefs = objectRefs[SUI.type] ?? [];
      const coinObjectRefs = objectRefs[asset.type] ?? [];
      const [suiGas] = extractGasPayment(suiObjectRefs, 100_000_000n);
      const isSui = asset.equals(SUI);
      const tx = new TransactionBlock();
      let splitCoin = undefined;
      if (isSui) {
        splitCoin = tx.splitCoins(tx.gas, [tx.pure(amount.quotient.toString())])[0];
      }
      if (!isSui) {
        tx.setGasPayment(suiGas);
      }
      tx.moveCall({
        target: `${WISP_CONFIG.dex.swapPackage}::${WISP_CONFIG.dex.swapModuleRouter}::${WISP_CONFIG.dex.functions.zapIn}`,
        typeArguments: [asset.type, poolOnChain.otherOf(asset).type],
        arguments: [
          tx.object(WISP_CONFIG.dex.swapSetting),
          tx.makeMoveVec({
            objects: isSui
              ? ([splitCoin] as TransactionArgument[])
              : coinObjectRefs.map((i) => {
                  return tx.object(i.objectId);
                }),
          }),
          tx.pure(amount.quotient.toString()),
        ],
      });
      tx.setGasBudget(100_000_000);
      await signAndExecuteTransaction(tx);
      ActionHandler.typeInput(dispatch, "");
    } catch (e) {
      console.error(e);
    } finally {
      setIsBuildingTx(false);
    }
  }

  return (
    <div className="space-y-6">
      <div>
        <AssetInput
          asset={asset}
          balance={balance}
          balances={assetBalances}
          label="Token"
          maxAmount={true}
          otherAsset={undefined}
          value={toCommaSeparated(typedValue)}
          onInputChange={handleInputChange}
          onMaxAmount={handleMaxAsset}
          onSelect={handleSelectAsset}
        />
        <div className="mt-6 mb-2">
          <div className="w-8 h-8 mx-auto">
            <PlusIcon className="text-pGreen-500 w-8 h-8" />
          </div>
        </div>
        <PoolInput
          label="LP"
          pools={pools ?? []}
          searchTerm={searchTerm}
          selectedPool={pool}
          value={liquidityMinted?.toExact()}
          onSearchChange={(val): void => setSearchTerm(val)}
          onSelect={handleSelectPool}
        />
      </div>
      <div>
        {!adapter ? (
          <Button
            className="px-6 py-3 w-full text-xl font-semibold font-Poppins whitespace-pre-wrap"
            onClick={(): void => setOpenSelectWallet(true)}
          >
            Connect Wallet
          </Button>
        ) : (
          <Button
            className="px-6 py-3 w-full text-xl font-semibold font-Poppins whitespace-pre-wrap"
            disabled={Boolean(error || isBuildingTx || !amount)}
            isLoading={isBuildingTx}
            onClick={handleZapIn}
          >
            {isBuildingTx ? "Processing" : error ?? "Zap In"}
          </Button>
        )}
      </div>
      {asset && pool && typedValue ? (
        <div className="space-y-4">
          <ZapInItem
            label="Share of pool"
            value={`${(poolTokenPercentage?.lessThan(ONE_BIPS) ? "<0.01" : poolTokenPercentage?.toFixed(2)) ?? "0"}%`}
          />
          {!!priceImpact && (
            <ZapInItem
              label="Price Impact"
              value={
                <span className={priceImpactCls(warningSeverity(priceImpact))}>
                  {priceImpact ? (priceImpact.toFixed(2) === "0.00" ? "<0.01" : priceImpact.toFixed(2)) : "--"}%
                </span>
              }
            />
          )}
          {!!realizedLPFee && (
            <ZapInItem
              label="LP Fee"
              value={<span>{realizedLPFee ? `${realizedLPFee.toExact()} ${realizedLPFee.coin.name}` : "-"}</span>}
            />
          )}
          {!!minimumLPReceived && (
            <ZapInItem label="Minimum LP tokens received" value={toCommaSeparated(minimumLPReceived.toString())} />
          )}
          <ZapInItem
            label="Rate"
            value={
              <div
                className="cursor-pointer flex items-center justify-end space-x-3"
                onClick={(): void => ActionHandler.revertRate(dispatch)}
              >
                {!state.isRateReverted ? (
                  <span className="text-right">{`1 ${pool.coin0.name} = ${
                    pool.coin0Price.toSignificant(6, { groupSeparator: "," }) ?? "-"
                  } ${pool.coin1.name}`}</span>
                ) : (
                  <span className="text-right">{`1 ${pool.coin1.name} = ${
                    pool.coin1Price.toSignificant(6, { groupSeparator: "," }) ?? "-"
                  } ${pool.coin0.name}`}</span>
                )}
                <RevertIcon className="w-4 h-4 text-pGreen-500" />
              </div>
            }
          />
        </div>
      ) : null}
    </div>
  );
}

function ZapInItem({ label, value, tooltip }: { label: ReactNode; value: ReactNode; tooltip?: string }): ReactElement {
  return (
    <div className="flex items-center justify-between space-x-4 text-sm font-semibold text-pNeutral-800">
      <div className="flex items-center space-x-1.5">
        <span>{label}</span>
        <div>
          {!!tooltip && (
            <Tooltip>
              <TooltipButton className="h-4 w-4">
                <NoteIcon className="w-4 h-4" />
              </TooltipButton>
              <TooltipContent>
                <div>{tooltip}</div>
              </TooltipContent>
            </Tooltip>
          )}
        </div>
      </div>
      {typeof value === "string" ? <div className="text-right">{value}</div> : value}
    </div>
  );
}

function priceImpactCls(severity?: WarningSeverity): string {
  switch (severity) {
    case 0:
      return "text-green-500";
    case 1:
      return "text-yellow-500";
    case 2:
      return "text-orange-500";
    case 3:
      return "text-red-400";
    case 4:
      return "text-red-600";
    default:
      return "";
  }
}
