import { ROUND_STATUS } from "./constant";

export function getStatusText(status: ROUND_STATUS): string {
  switch (status) {
    case ROUND_STATUS.CANCEL: {
      return "Cancel";
    }
    case ROUND_STATUS.EXPIRED: {
      return "Expired";
    }
    case ROUND_STATUS.LATER: {
      return "Later";
    }
    case ROUND_STATUS.LIVE: {
      return "Live";
    }
    case ROUND_STATUS.NEXT: {
      return "Next";
    }
  }
}
