import { ReactElement } from "react";
import { Disclosure } from "@headlessui/react";
import clsx from "clsx";
import { ChevronDownIcon } from "components/atoms/icons/ChevronDownIcon";
import { ChevronUpIcon } from "components/atoms/icons/ChevronUpIcon";
import { DownIcon } from "components/atoms/icons/DownIcon";
import { UpIcon } from "components/atoms/icons/UpIcon";
import { WinningCupIcon } from "components/atoms/icons/WinningCupIcon";
import { BetRound } from "hooks/useBetHistory";
import { toCommaSeparated } from "sdk/utils/formatNumber";

import { BET_STATUS, PRICE_STATUS, USER_POSITION } from "./constant";

type Props = {
  betRound: BetRound;
  className?: string;
};

export function HistoryItem({ className, betRound }: Props): ReactElement {
  return (
    <div className={className}>
      <Disclosure>
        <Disclosure.Button as="div" className="flex space-x-3 py-3">
          {({ open }): ReactElement => {
            return (
              <>
                <div className="flex-1">
                  <div className="uppercase opacity-30 font-medium text-xs">Round</div>
                  <div className="font-semibold">{betRound.epoch}</div>
                </div>
                <div className="basis-3/5">
                  <div className="uppercase opacity-30 font-medium text-xs">Your Result</div>
                  <div
                    className={clsx("font-semibold", {
                      "text-pGreen-500": betRound.status === BET_STATUS.WIN,
                      "text-yellow-600": betRound.status === BET_STATUS.WAITING,
                      "text-white": betRound.status === BET_STATUS.DRAW,
                      "text-pRed-500": betRound.status === BET_STATUS.LOSE,
                    })}
                  >
                    {betRound.status === BET_STATUS.WAITING
                      ? "Pending"
                      : betRound.status === BET_STATUS.DRAW
                      ? "Draw"
                      : betRound.status === BET_STATUS.LOSE
                      ? "Lose"
                      : `+${betRound.winAmount?.toExact()}`}
                  </div>
                </div>
                <div className="flex items-center shrink-0">
                  {open ? <ChevronUpIcon className="w-2 h-2" /> : <ChevronDownIcon className="w-2 h-2" />}
                </div>
              </>
            );
          }}
        </Disclosure.Button>
        <Disclosure.Panel className="pb-4">
          <div>
            <div className="flex items-center justify-between">
              <span className="text-pNeutral-800 font-semibold">Your History</span>
              {betRound.status === BET_STATUS.WIN ? (
                <div className="flex items-center space-x-2 font-semibold">
                  <span className="text-pYellow-300">WIN</span>
                  <WinningCupIcon className="h-5 w-5" />
                </div>
              ) : (
                <div />
              )}
            </div>
            <div className="mt-3 p-4 space-y-2 border border-white border-opacity-10 rounded-lg">
              <div className="flex items-center justify-between">
                <div className="text-pNeutral-800 text-opacity-50 text-sm">Your direction</div>
                <div
                  className={clsx(
                    "flex items-center space-x-1 rounded-md text-dark-400 px-2",
                    betRound.position === USER_POSITION.BULL ? "bg-pGreen-500" : "bg-pRed-500",
                  )}
                >
                  {betRound.position === USER_POSITION.BULL ? <UpIcon /> : <DownIcon />}
                  <span className="font-semibold text-sm">
                    {betRound.position === USER_POSITION.BULL ? "UP" : "DOWN"}
                  </span>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="text-pNeutral-800 text-opacity-50 text-sm">Your position</div>
                <div className="font-semibold">
                  {betRound.amount?.toExact()} {betRound.amount?.coin.metadata?.ticker ?? "SUI"}
                </div>
              </div>
              {betRound.status === BET_STATUS.WIN && (
                <div className="flex items-center justify-between">
                  <div className="text-pNeutral-800 text-opacity-50 text-sm">Your winnings</div>
                  <div className="font-semibold text-pGreen-500">
                    +{betRound.winAmount?.toExact()} {betRound.winAmount?.coin.metadata?.ticker ?? "SUI"}
                  </div>
                </div>
              )}
            </div>
            <div className="mt-5">
              <span className="text-pNeutral-800 font-semibold">Round history</span>
            </div>
            <div className="mt-3 p-4 space-y-2 border border-white border-opacity-10 rounded-lg">
              <div className="text-pNeutral-800 text-opacity-50 text-xs font-medium uppercase">Closed Price</div>
              <div className="flex items-center justify-between">
                <div
                  className={clsx("font-semibold text-lg", {
                    "text-pGreen-500": betRound.round.priceStatus === PRICE_STATUS.BULL,
                    "text-pRed-500": betRound.round.priceStatus === PRICE_STATUS.BEAR,
                  })}
                >
                  $
                  {toCommaSeparated(
                    betRound.round.closePrice && betRound.round.closePrice > 0
                      ? betRound.round.closePrice.toString()
                      : "-",
                  )}
                </div>
                {betRound.round.deltaChange > 0 && (
                  <div
                    className={clsx("flex items-center space-x-1 rounded-md text-dark-400 px-2 py-1", {
                      "bg-pRed-500": betRound.round.priceStatus === PRICE_STATUS.BEAR,
                      "bg-pGreen-500": betRound.round.priceStatus === PRICE_STATUS.BULL,
                    })}
                  >
                    <span>{betRound.round.priceStatus === PRICE_STATUS.BEAR ? <DownIcon /> : <UpIcon />}</span>
                    <span className="font-semibold text-sm">
                      ${toCommaSeparated(betRound.round.deltaChange?.toString() ?? "-")}
                    </span>
                  </div>
                )}
              </div>
              <div className="flex items-center justify-between">
                <div className="text-pNeutral-800 text-opacity-50 text-sm">Locked price</div>
                <div className="font-semibold">${toCommaSeparated(betRound.round.lockPrice?.toString() ?? "-")}</div>
              </div>
              <div className="flex items-center justify-between">
                <div className="text-pNeutral-800 text-opacity-50 text-sm">Prize amount</div>
                <div className="font-semibold">
                  {betRound.round.rewardAmount?.toExact()}{" "}
                  {betRound.round.rewardAmount?.coin?.metadata?.ticker ?? "SUI"}
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="text-pNeutral-800 text-opacity-50 text-sm">Up</div>
                <div className="font-semibold text-xs">
                  {betRound.round.upPayout ? betRound.round.upPayout.toFixed(2) : "0"}x |{" "}
                  {betRound.round.upAmount?.toExact()} {betRound.round.upAmount?.coin?.metadata?.ticker ?? "SUI"}
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="text-pNeutral-800 text-opacity-50 text-sm">Down</div>
                <div className="font-semibold text-xs">
                  {betRound.round.downPayout ? betRound.round.downPayout.toFixed(2) : "0"}x |{" "}
                  {betRound.round.downAmount?.toExact()} {betRound.round.downAmount?.coin?.metadata?.ticker ?? "SUI"}
                </div>
              </div>
            </div>
          </div>
        </Disclosure.Panel>
      </Disclosure>
    </div>
  );
}
