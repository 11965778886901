import { ReactElement, ReactNode, useEffect, useMemo, useReducer, useState } from "react";
import { TransactionArgument, TransactionBlock } from "@mysten/sui.js";
import { useWallet } from "@suiet/wallet-kit";
import CoinAPI from "api/coin";
import { Button } from "components/atoms/Button";
import { NoteIcon } from "components/atoms/icons/NoteIcon";
import { PlusIcon } from "components/atoms/icons/PlusIcon";
import { RevertIcon } from "components/atoms/icons/RevertIcon";
import { Tooltip, TooltipButton, TooltipContent } from "components/atoms/Tooltip";
import { AssetInput } from "components/molecules/AssetInput";
import { useWalletHelper } from "contexts/WalletHelperContext";
import { useWispSettings } from "contexts/WispSettingsContext";
import { useSearchParams } from "react-router-dom";
import { Coin } from "sdk/entities/coin";
import { ONE_BIPS } from "sdk/misc";
import { toCommaSeparated } from "sdk/utils/formatNumber";
import { isTooManyDecimals } from "sdk/utils/tryParseAmount";
import invariant from "tiny-invariant";
import { SUI } from "utils/coins";
import { WISP_CONFIG } from "utils/constant";
import { extractGasPayment, getSuiObjectRefs } from "utils/getCoinObjectIds";

import { useLiquidityInfo } from "./hooks/hooks";
import * as ActionHandlers from "./reducers/actionHandlers";
import { Field, initialState, reducer } from "./reducers/liquidityReducer";

export function AddLiquidity(): ReactElement {
  const suietWallet = useWallet();
  const [params] = useSearchParams();
  const coinAType = params.get("coinA");
  const coinBType = params.get("coinB");

  const adapter = suietWallet.adapter;
  const address = suietWallet.address;

  const { settings } = useWispSettings();
  const { fungibleBalances, setOpenSelectWallet, signAndExecuteTransaction } = useWalletHelper();

  const [state, dispatch] = useReducer(reducer, initialState);
  const [isBuildingTx, setIsBuildingTx] = useState<boolean>(false);

  const { independentField, typedValue, otherTypedValue } = state;
  const {
    coins,
    dependentField,
    balances,
    parsedAmounts,
    price,
    noLiquidity,
    poolTokenPercentage,
    pool,
    error,
    isFetching,
    liquidityMinted,
  } = useLiquidityInfo(state, settings.customRPC, settings.networkEnv);

  const assetBalances = useMemo(() => {
    return fungibleBalances ?? [];
  }, [fungibleBalances]);

  useEffect(() => {
    if (!coinAType || !coinBType) {
      return;
    }
    CoinAPI.getCoinInfos(settings.networkEnv, [coinAType, coinBType]).then((resp) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const coins = resp.data?.map((piece: any) => {
        return new Coin(piece.package_addr, piece.module, piece.type, piece.decimal ?? 0, piece.treasury_addr, {
          imageUrl: piece.icon_url,
          ticker: piece.symbol,
          projectName: piece.symbol,
          description: piece.description,
        });
      });
      if (coins.length >= 2) {
        ActionHandlers.set2Coins(dispatch, coins[0] ?? undefined, coins[1] ?? undefined);
      }
    });
  }, [coinAType, coinBType, settings.networkEnv]);

  const formattedAmounts = {
    [independentField]: toCommaSeparated(typedValue),
    [dependentField]: noLiquidity ? toCommaSeparated(otherTypedValue) : parsedAmounts[dependentField]?.toExact() ?? "",
  };

  const maxAmountCoinA = Boolean(balances[Field.COIN_A]?.coin.equals(coins[Field.COIN_A]));
  const maxAmountCoinB = Boolean(balances[Field.COIN_B]?.coin.equals(coins[Field.COIN_B]));

  function handleSelectCoinA(coin?: Coin): void {
    ActionHandlers.setCoins(dispatch, Field.COIN_A, coin);
  }

  function handleSelectCoinB(coin?: Coin): void {
    ActionHandlers.setCoins(dispatch, Field.COIN_B, coin);
  }

  function handleCoinAInputChange(value: string): void {
    if (isTooManyDecimals(value, coins[Field.COIN_A])) {
      return;
    }
    ActionHandlers.typeInput(dispatch, Field.COIN_A, value, noLiquidity);
  }

  function handleCoinBInputChange(value: string): void {
    if (isTooManyDecimals(value, coins[Field.COIN_B])) {
      return;
    }
    ActionHandlers.typeInput(dispatch, Field.COIN_B, value, noLiquidity);
  }

  function handleMaxCoinA(): void {
    if (maxAmountCoinA) {
      handleCoinAInputChange(balances[Field.COIN_A]?.toExact({ groupSeparator: "" }) ?? "");
    }
  }

  function handleMaxCoinB(): void {
    if (maxAmountCoinB) {
      handleCoinBInputChange(balances[Field.COIN_B]?.toExact({ groupSeparator: "" }) ?? "");
    }
  }

  function resetForm(): void {
    ActionHandlers.typeInput(dispatch, Field.COIN_A, "", noLiquidity);
    ActionHandlers.typeInput(dispatch, Field.COIN_B, "", noLiquidity);
  }

  function handleRevertRate(): void {
    ActionHandlers.revertRate(dispatch);
  }

  async function handleAddButtonClick(): Promise<void> {
    if (isBuildingTx || !address) {
      return;
    }
    try {
      setIsBuildingTx(true);
      const amountA = parsedAmounts[Field.COIN_A];
      const amountB = parsedAmounts[Field.COIN_B];
      invariant(amountA !== undefined && amountB !== undefined, "UNDEFINED");
      invariant(address, "Wallet not found");
      const objectRefs = await getSuiObjectRefs(settings.customRPC, address, [SUI, amountA.coin, amountB.coin]);
      const suiObjectRefs = objectRefs[SUI.type] ?? [];
      const tokenAObjectRefs = objectRefs[amountA.coin.type] ?? [];
      const tokenBObjectRefs = objectRefs[amountB.coin.type] ?? [];
      const aIsSui = amountA.coin.equals(SUI);
      const bIsSui = amountB.coin.equals(SUI);
      const [suiGas] = extractGasPayment(suiObjectRefs, 100_000_000n);
      const tx = new TransactionBlock();
      let splitCoin = undefined;
      if (aIsSui) {
        splitCoin = tx.splitCoins(tx.gas, [tx.pure(amountA.quotient.toString())])[0];
      }
      if (bIsSui) {
        splitCoin = tx.splitCoins(tx.gas, [tx.pure(amountB.quotient.toString())])[0];
      }
      if (!aIsSui && !bIsSui) {
        tx.setGasPayment(suiGas);
      }
      tx.setGasBudget(100_000_000);
      if (pool) {
        tx.moveCall({
          target: `${WISP_CONFIG.dex.swapPackage}::${WISP_CONFIG.dex.swapModuleRouter}::${WISP_CONFIG.dex.functions.addLiquidity}`,
          typeArguments: [amountA.coin.type, amountB.coin.type],
          arguments: [
            tx.object(WISP_CONFIG.dex.swapSetting),
            tx.makeMoveVec({
              objects: aIsSui
                ? ([splitCoin] as TransactionArgument[])
                : tokenAObjectRefs.map((i) => tx.object(i.objectId)),
            }),
            tx.makeMoveVec({
              objects: bIsSui
                ? ([splitCoin] as TransactionArgument[])
                : tokenBObjectRefs.map((i) => tx.object(i.objectId)),
            }),
            tx.pure(amountA.quotient.toString()),
            tx.pure(amountB.quotient.toString()),
            tx.pure("0"),
            tx.pure("0"),
          ],
        });
        await signAndExecuteTransaction(tx);
      } else {
        tx.moveCall({
          target: `${WISP_CONFIG.dex.swapPackage}::${WISP_CONFIG.dex.swapModuleRouter}::${WISP_CONFIG.dex.functions.createPool}`,
          typeArguments: [amountA.coin.type, amountB.coin.type],
          arguments: [
            tx.object(WISP_CONFIG.dex.swapSetting),
            tx.makeMoveVec({
              objects: aIsSui
                ? ([splitCoin] as TransactionArgument[])
                : tokenAObjectRefs.map((i) => tx.object(i.objectId)),
            }),
            tx.makeMoveVec({
              objects: bIsSui
                ? ([splitCoin] as TransactionArgument[])
                : tokenBObjectRefs.map((i) => tx.object(i.objectId)),
            }),
            tx.pure(amountA.quotient.toString()),
            tx.pure(amountB.quotient.toString()),
          ],
        });
        await signAndExecuteTransaction(tx);
      }
      resetForm();
    } catch (e) {
      console.error(e);
    } finally {
      setIsBuildingTx(false);
    }
  }

  return (
    <div className="space-y-6">
      <div>
        <AssetInput
          asset={coins[Field.COIN_A]}
          balance={balances[Field.COIN_A]}
          balances={assetBalances}
          label="First Token"
          maxAmount={maxAmountCoinA}
          otherAsset={coins[Field.COIN_A]}
          value={formattedAmounts[Field.COIN_A]}
          onInputChange={handleCoinAInputChange}
          onMaxAmount={handleMaxCoinA}
          onSelect={handleSelectCoinA}
        />
        <div className="mt-6 mb-2">
          <div className="w-8 h-8 mx-auto">
            <PlusIcon className="text-pGreen-500 w-8 h-8" />
          </div>
        </div>
        <AssetInput
          asset={coins[Field.COIN_B]}
          balance={balances[Field.COIN_B]}
          balances={assetBalances}
          label="Second Token"
          maxAmount={maxAmountCoinB}
          otherAsset={coins[Field.COIN_B]}
          value={formattedAmounts[Field.COIN_B]}
          onInputChange={handleCoinBInputChange}
          onMaxAmount={handleMaxCoinB}
          onSelect={handleSelectCoinB}
        />
      </div>
      <div>
        {!adapter ? (
          <Button
            className="px-6 py-3 w-full text-xl font-semibold font-Poppins whitespace-pre-wrap"
            onClick={(): void => setOpenSelectWallet(true)}
          >
            Connect Wallet
          </Button>
        ) : (
          <Button
            className="px-6 py-3 w-full text-xl font-semibold font-Poppins whitespace-pre-wrap"
            disabled={Boolean(
              error || isFetching || isBuildingTx || !parsedAmounts[Field.COIN_A] || !parsedAmounts[Field.COIN_B],
            )}
            isLoading={isBuildingTx}
            onClick={handleAddButtonClick}
          >
            {isFetching
              ? "Loading"
              : error ?? (isBuildingTx ? (pool ? "Adding" : "Creating") : pool ? "Add Liquidity" : "Create Pool")}
          </Button>
        )}
      </div>
      {state.coinA && state.coinB && (formattedAmounts[Field.COIN_A] || formattedAmounts[Field.COIN_B]) ? (
        <div className="space-y-4">
          <AddLiquidityItem
            label="Share of pool"
            value={`${
              noLiquidity && price
                ? 100
                : (poolTokenPercentage?.lessThan(ONE_BIPS) ? "<0.01" : poolTokenPercentage?.toFixed(2)) ?? "0"
            }%`}
          />
          {!!liquidityMinted && <AddLiquidityItem label="Estimated LP" value={liquidityMinted.toExact()} />}
          {!!price && (
            <AddLiquidityItem
              label="Rate"
              value={
                <div className="cursor-pointer flex items-center justify-end space-x-3" onClick={handleRevertRate}>
                  <span className="text-right">
                    {state.isRateReverted
                      ? `1 ${state.coinB.name} = ${price?.invert()?.toSignificant(6, { groupSeparator: "," }) ?? "-"} ${
                          state.coinA.name
                        }`
                      : `1 ${state.coinA.name} = ${price?.toSignificant(6, { groupSeparator: "," }) ?? "-"} ${
                          state.coinB.name
                        }`}
                  </span>
                  <RevertIcon className="w-4 h-4 text-pGreen-500" />
                </div>
              }
            />
          )}
        </div>
      ) : null}
    </div>
  );
}

function AddLiquidityItem({
  label,
  value,
  tooltip,
}: {
  label: ReactNode;
  value: ReactNode;
  tooltip?: string;
}): ReactElement {
  return (
    <div className="flex items-center justify-between space-x-4 text-sm font-semibold text-pNeutral-800">
      <div className="flex items-center space-x-1.5">
        <span>{label}</span>
        <div>
          {!!tooltip && (
            <Tooltip>
              <TooltipButton className="h-4 w-4">
                <NoteIcon className="w-4 h-4" />
              </TooltipButton>
              <TooltipContent>
                <div>{tooltip}</div>
              </TooltipContent>
            </Tooltip>
          )}
        </div>
      </div>
      {typeof value === "string" ? <div className="text-right">{value}</div> : value}
    </div>
  );
}
