export const BOOST_RATE = [
  {
    name: 2592000,
    value: 5000,
    string: "30 days",
  },
  {
    name: 5184000,
    value: 10000,
    string: "60 days",
  },
];
