import { useMemo } from "react";
import { useWalletHelper } from "contexts/WalletHelperContext";
import { Amount } from "sdk/entities/amount";
import { tryParseAmount } from "sdk/utils/tryParseAmount";
import { WISP_SUI } from "utils/coins";

import { State } from "../reducers/depositReducer";

type Response = {
  balance: Amount | undefined;
  parsedAmount: Amount | undefined;
  inputError: string | undefined;
};

export function useWithdrawLsd(state: State): Response {
  const { typedValue } = state;
  const { fungibleBalances } = useWalletHelper();

  const balance = useMemo(() => {
    if (!fungibleBalances?.length) {
      return undefined;
    }
    const amt = fungibleBalances.find((f) => f.coin.equals(WISP_SUI));
    if (amt) {
      return Amount.fromRawAmount(WISP_SUI, amt.quotient);
    }
    return Amount.fromRawAmount(WISP_SUI, 0);
  }, [fungibleBalances]);

  const parsedAmount = useMemo(() => {
    return tryParseAmount(typedValue, WISP_SUI);
  }, [typedValue]);

  let inputError: string | undefined;
  if (!parsedAmount) {
    inputError = inputError ?? "Enter an amount";
  }
  if (!balance || balance.equalTo(0)) {
    inputError = inputError ?? "Empty WISPSUI";
  }
  if (balance?.greaterThan(0) && parsedAmount && balance?.lessThan(parsedAmount)) {
    inputError = inputError ?? `Insufficient ${WISP_SUI?.name} balance`;
  }

  return {
    balance,
    parsedAmount,
    inputError,
  };
}
