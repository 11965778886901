import * as React from "react";

import { IconProps } from "./type";

export function RevertIcon(props: IconProps): React.ReactElement<IconProps> {
  return (
    <svg fill="none" height="20" viewBox="0 0 18 20" width="18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5 5.00003L17 5.00003M17 5.00003L13 1.00003M17 5.00003L13 9.00003M13 15L0.999999 15M0.999999 15L5 19M0.999999 15L5 11"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.6"
      />
    </svg>
  );
}
