import * as React from "react";

import { IconProps } from "./type";

export function UpIcon(props: IconProps): React.ReactElement<IconProps> {
  return (
    <svg fill="none" height="12" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M6.5 6V10H5.5V6H2L6 2L10 6H6.5Z" fill="currentColor" />
    </svg>
  );
}
