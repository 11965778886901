import { Coin } from "sdk/entities/coin";

import { Action, ActionType } from "./actions";

export enum Field {
  COIN_A = "COIN_A",
  COIN_B = "COIN_B",
}

export type State = {
  readonly coinA?: Coin;
  readonly coinB?: Coin;
  readonly independentField: Field;
  readonly typedValue: string;
  readonly otherTypedValue: string;
  readonly isRateReverted: boolean;
};

export const initialState: State = {
  coinA: undefined,
  coinB: undefined,
  independentField: Field.COIN_A,
  typedValue: "",
  otherTypedValue: "",
  isRateReverted: false,
};

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.TYPE_INPUT: {
      if (action.noLiquidity) {
        if (action.field === state.independentField) {
          return {
            ...state,
            independentField: action.field,
            typedValue: action.typedValue,
          };
        } else {
          return {
            ...state,
            independentField: action.field,
            typedValue: action.typedValue,
            otherTypedValue: state.typedValue,
          };
        }
      } else {
        return {
          ...state,
          independentField: action.field,
          typedValue: action.typedValue,
          otherTypedValue: "",
        };
      }
    }

    case ActionType.SET_COINS: {
      if (
        (action.field === Field.COIN_B && action.coin?.equals(state.coinA)) ||
        (action.field === Field.COIN_A && action.coin?.equals(state.coinB))
      ) {
        return {
          ...state,
          coinA: state.coinB,
          coinB: state.coinA,
          independentField: state.independentField === Field.COIN_A ? Field.COIN_B : Field.COIN_A,
        };
      }

      return action.field === Field.COIN_A
        ? {
            ...state,
            typedValue: state.independentField === Field.COIN_A ? initialState.typedValue : state.typedValue,
            coinA: action.coin,
          }
        : {
            ...state,
            typedValue: state.independentField === Field.COIN_B ? initialState.typedValue : state.typedValue,
            coinB: action.coin,
          };
    }

    case ActionType.SET_2_COINS: {
      return {
        ...state,
        coinA: action.coinA,
        coinB: action.coinB,
      };
    }

    case ActionType.REVERT_RATE: {
      return { ...state, isRateReverted: !state.isRateReverted };
    }

    case ActionType.RESET_FORM: {
      return initialState;
    }
  }
}
