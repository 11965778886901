import { Amount } from "sdk/entities/amount";
import { Fraction } from "sdk/entities/fraction";
import { Percent } from "sdk/entities/percent";

const ONE = new Fraction(1, 1);

export function calculateSlippageAmount(value: Amount, slippage: Percent): [bigint, bigint] {
  if (slippage.lessThan(0) || slippage.greaterThan(ONE)) throw new Error("Unexpected slippage");
  return [value.multiply(ONE.subtract(slippage)).quotient, value.multiply(ONE.add(slippage)).quotient];
}
