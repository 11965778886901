import * as React from "react";
import clsx from "clsx";

import { ToggleButtonContext } from "./toggleButtonContext";

type Props<T> = {
  value: T | undefined;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
};

export function ToggleButton<T>({ value, children, className, disabled }: Props<T>): React.ReactElement<Props<T>> {
  const { value: currentValue, setValue } = React.useContext(ToggleButtonContext);

  function handleButtonClick(): void {
    setValue(value);
  }

  const isActive = value === currentValue;

  return (
    <button
      className={clsx(
        "h-8 rounded-lg font-medium flex items-center justify-center border w-full",
        className,
        isActive ? "bg-pGreen-500 border-pGreen-500 text-dark-600" : "text-white border-white border-opacity-10",
      )}
      disabled={disabled}
      onClick={handleButtonClick}
    >
      {children ?? `${value}`}
    </button>
  );
}
