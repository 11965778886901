import { useMemo } from "react";
import { Connection, JsonRpcProvider, SuiObjectResponse } from "@mysten/sui.js";
import LiquidityAPI from "api/liquidity";
import { useQuery } from "react-query";
import { Amount } from "sdk/entities/amount";
import { Coin } from "sdk/entities/coin";
import { Pool } from "sdk/entities/pool";
import invariant from "tiny-invariant";
import { NetworkEnv, WISP_CONFIG } from "utils/constant";
import { parsePool } from "utils/parsePool";

type Props = {
  coinA: Coin | null;
  coinB: Coin | null;
  networkEnv: NetworkEnv;
  rpcEndpoint: string;
};

type Response = {
  pool: Pool | null;
  isFetching: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
};

type PoolsProps = {
  coinPairs: [Coin, Coin][];
  networkEnv: NetworkEnv;
  rpcEndpoint: string;
};

type PoolOneSideProps = {
  searchTerm: string;
  coinTypes: string[];
  networkEnv: NetworkEnv;
  rpcEndpoint: string;
};

type PoolsResponse = {
  pools: Pool[] | null;
  isFetching: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
};

export function usePool({ coinA: paramA, coinB: paramB, networkEnv, rpcEndpoint }: Props): Response {
  const [coinA, coinB] = useMemo(() => {
    if (!paramA || !paramB) {
      return [paramA, paramB];
    }
    if (paramA.packageObject < paramB.packageObject) {
      return [paramA, paramB];
    }
    return [paramB, paramA];
  }, [paramA, paramB]);
  const {
    data: pool,
    isFetching,
    error,
  } = useQuery(
    ["getPool", coinA, coinB, rpcEndpoint, networkEnv],
    async (): Promise<Pool | null> => {
      invariant(coinA && coinB, "Pool: Invalid 2 coins");
      const beResp = await LiquidityAPI.getPoolInfos(networkEnv, [
        {
          coinAType: coinA.type,
          coinBType: coinB.type,
        },
      ]);
      const poolId = beResp?.data?.pools?.[0]?.pool_addr;
      if (!poolId) {
        return null;
      }
      const provider = new JsonRpcProvider(
        new Connection({
          fullnode: rpcEndpoint,
        }),
      );
      const resp: SuiObjectResponse = await provider.getObject({
        id: poolId,
        options: { showType: true, showContent: true },
      });
      if (!resp.data?.objectId) {
        return null;
      }
      if (resp.data.content?.dataType !== "moveObject") {
        return null;
      }

      const fields = resp.data.content.fields;
      const firstAmt = fields?.first_token ?? 0;
      const secondAmt = fields?.second_token ?? 0;
      const lspType = resp.data.content?.type ?? "";
      const lspAmt = fields?.wisp_lp_supply?.fields?.value ?? 0;
      const lpCoin = Coin.createLpCoin(lspType, 0);
      const is1stA = lpCoin.coinA?.equals(coinA);

      return new Pool(
        poolId,
        WISP_CONFIG.dex.swapModulePool,
        "Pool",
        Amount.fromRawAmount(is1stA ? coinA : coinB, is1stA ? firstAmt : secondAmt),
        Amount.fromRawAmount(is1stA ? coinB : coinA, is1stA ? secondAmt : firstAmt),
        Amount.fromRawAmount(Coin.createLpCoin(lspType, 0), lspAmt),
      );
    },
    { enabled: !!coinA && !!coinB },
  );
  return useMemo(() => {
    return {
      pool: pool ?? null,
      isFetching,
      error,
    };
  }, [pool, isFetching, error]);
}

export function usePools({ coinPairs: pairParam, networkEnv, rpcEndpoint }: PoolsProps): PoolsResponse {
  const coinPairs = useMemo(() => {
    return pairParam.map((pair) => {
      const [a, b] = pair;
      if (a.packageObject < a.packageObject) {
        return [a, b];
      }
      return [b, a];
    });
  }, [pairParam]);
  const {
    data: pools,
    isFetching,
    error,
  } = useQuery(
    ["get-Pool-Infos", coinPairs, rpcEndpoint, networkEnv],
    async (): Promise<Pool[] | null> => {
      const beResp = await LiquidityAPI.getPoolInfos(
        networkEnv,
        coinPairs.map((c) => ({
          coinAType: c[0].type,
          coinBType: c[1].type,
        })),
      );
      return (beResp?.data?.pools ?? []).map(parsePool);
    },
    { enabled: coinPairs.length > 0 },
  );
  return useMemo(() => {
    return {
      pools: pools ?? null,
      isFetching,
      error,
    };
  }, [pools, isFetching, error]);
}

export function usePoolsByOneSide({ coinTypes, networkEnv, rpcEndpoint, searchTerm }: PoolOneSideProps): PoolsResponse {
  const {
    data: pools,
    isFetching,
    error,
  } = useQuery(
    ["get-Pool-Infos-one-side", coinTypes, rpcEndpoint, networkEnv, searchTerm],
    async (): Promise<Pool[] | null> => {
      const beResp = await LiquidityAPI.getPoolInfosByOneSide(networkEnv, coinTypes, searchTerm);
      return (beResp?.data?.pools ?? []).map(parsePool);
    },
    { enabled: coinTypes.length > 0 },
  );
  return useMemo(() => {
    return {
      pools: pools ?? null,
      isFetching,
      error,
    };
  }, [pools, isFetching, error]);
}
