import { Action, ActionType, SelectCoinAction, TypeInputAction } from "./actions";

export function selectCoin(dispatch: React.Dispatch<Action>, { field, coin }: Omit<SelectCoinAction, "type">): void {
  dispatch({ type: ActionType.SELECT_COIN, field, coin });
}

export function userInput(dispatch: React.Dispatch<Action>, { typedValue }: Omit<TypeInputAction, "type">): void {
  dispatch({ type: ActionType.TYPE_INPUT, typedValue });
}

export function switchCoin(dispatch: React.Dispatch<Action>): void {
  dispatch({ type: ActionType.SWITCH_COINS });
}

export function reset(dispatch: React.Dispatch<Action>): void {
  dispatch({ type: ActionType.RESET });
}
