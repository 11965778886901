import { useState } from "react";
import clsx from "clsx";

import LsdDeposit from "./deposit/LsdDeposit";
import LsdExchange from "./exchange/Exchange";
import LsdWithdraw from "./withdraw/LsdWithdraw";

const tabs = [
  {
    id: "exchange",
    label: "Exchange",
  },
  {
    id: "deposit",
    label: "Deposit",
  },
  {
    id: "withdraw",
    label: "Withdraw",
  },
];

export default function LsdFi(): React.ReactElement {
  const [tab, setTab] = useState<string>(tabs[0].id);

  return (
    <div className="relative w-full flex-1 z-10 flex flex-col justify-center">
      <div className="bg-dark-600 p-4 md:p-6 rounded-xl max-w-md w-full space-y-6 md:space-y-8 mx-auto">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-6">
            {tabs.map((t) => {
              const isActive = t.id === tab;
              return (
                <div
                  className={clsx("text-lg font-bold cursor-pointer", isActive ? "text-white" : "text-gray-500")}
                  key={t.id}
                  onClick={(): void => setTab(t.id)}
                >
                  {t.label}
                </div>
              );
            })}
          </div>
          {/* <div className="text-xs text-right">
            <div className="text-gray-300">APR: 10.63%</div>
            <div className="text-pGreen-400 ">Stake wispSui</div>
          </div> */}
        </div>
        {tab === "exchange" ? <LsdExchange /> : tab === "deposit" ? <LsdDeposit /> : <LsdWithdraw />}
      </div>
    </div>
  );
}
