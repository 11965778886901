import { ReactElement } from "react";
import SwiperProvider from "components/atoms/swiper/SwiperProvider";
import { Prediction } from "modules/prediction/Prediction";

export function PredictionView(): ReactElement {
  return (
    <div className="relative w-full flex-1 space-y-8 z-10 flex flex-col">
      <SwiperProvider>
        <Prediction />
      </SwiperProvider>
    </div>
  );
}
