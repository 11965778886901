import { ReactElement, useState } from "react";
import { TransactionBlock } from "@mysten/sui.js";
import { useWallet } from "@suiet/wallet-kit";
import { Button } from "components/atoms/Button";
import { CreateVestingVeWispModal } from "components/molecules/CreateVestingVeWispModal";
import { useWalletHelper } from "contexts/WalletHelperContext";
import { useWispSettings } from "contexts/WispSettingsContext";
import { useAllVeWisp, VeWispNftObj } from "hooks/useAllVeWisp";
import moment from "moment";
import { Amount } from "sdk/entities/amount";
import invariant from "tiny-invariant";
import { SUI } from "utils/coins";
import { WISP_CONFIG } from "utils/constant";
import { extractGasPayment, getSuiObjectRefs } from "utils/getCoinObjectIds";

const milestones = WISP_CONFIG.vesting.milestones;

export default function VeWispToWisp(): ReactElement {
  const { address } = useWallet();
  const { settings } = useWispSettings();
  const { signAndExecuteTransaction } = useWalletHelper();
  const veWispData = useAllVeWisp(settings.customRPC, settings.networkEnv, address);

  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [isCreating, setCreating] = useState(false);
  const [claimingId, setClaimingId] = useState("");
  const [withdrawId, setWithdrawId] = useState("");

  const handleCreateVestingNft = async (amount: Amount): Promise<void> => {
    setCreating(true);
    invariant(address, "Address not found");
    try {
      const objectRefs = await getSuiObjectRefs(settings.customRPC, address, [SUI]);
      const suiObjectRefs = objectRefs[SUI.type] ?? [];
      const [suiGas] = extractGasPayment(suiObjectRefs, 100_000_000n);
      const tx = new TransactionBlock();
      tx.setGasPayment(suiGas);
      tx.setGasBudget(100_000_000);
      tx.moveCall({
        target: `${WISP_CONFIG.vesting.package}::${WISP_CONFIG.vesting.module}::${WISP_CONFIG.vesting.functions.createVestingVeWispNft}`,
        arguments: [
          tx.object(WISP_CONFIG.vesting.registry),
          tx.makeMoveVec({
            objects: veWispData.objects.map((i) => tx.object(i.object)),
          }),
          tx.pure(amount.quotient),
          tx.object("0x6"),
        ],
      });
      await signAndExecuteTransaction(tx);
    } catch (e) {
      console.error(e);
    } finally {
      setCreating(false);
    }
  };

  const handleClaimWisp = async (nftObj: VeWispNftObj): Promise<void> => {
    setClaimingId(nftObj.object);
    invariant(address, "Address not found");
    try {
      const objectRefs = await getSuiObjectRefs(settings.customRPC, address, [SUI]);
      const suiObjectRefs = objectRefs[SUI.type] ?? [];
      const [suiGas] = extractGasPayment(suiObjectRefs, 100_000_000n);
      const tx = new TransactionBlock();
      tx.setGasPayment(suiGas);
      tx.setGasBudget(100_000_000);
      tx.moveCall({
        target: `${WISP_CONFIG.vesting.package}::${WISP_CONFIG.vesting.module}::${WISP_CONFIG.vesting.functions.redeemWisp}`,
        arguments: [tx.object(WISP_CONFIG.vesting.registry), tx.object(nftObj.object), tx.object("0x6")],
      });
      await signAndExecuteTransaction(tx);
    } catch (e) {
      console.error(e);
    } finally {
      setClaimingId("");
    }
  };

  const handleWithdrawVeWisp = async (nftObj: VeWispNftObj): Promise<void> => {
    setWithdrawId(nftObj.object);
    invariant(address, "Address not found");
    try {
      const objectRefs = await getSuiObjectRefs(settings.customRPC, address, [SUI]);
      const suiObjectRefs = objectRefs[SUI.type] ?? [];
      const [suiGas] = extractGasPayment(suiObjectRefs, 100_000_000n);
      const tx = new TransactionBlock();
      tx.setGasPayment(suiGas);
      tx.setGasBudget(100_000_000);
      tx.moveCall({
        target: `${WISP_CONFIG.vesting.package}::${WISP_CONFIG.vesting.module}::${WISP_CONFIG.vesting.functions.emergencyWithdrawVeWisp}`,
        arguments: [tx.object(WISP_CONFIG.vesting.registry), tx.object(nftObj.object)],
      });
      await signAndExecuteTransaction(tx);
    } catch (e) {
      console.error(e);
    } finally {
      setWithdrawId("");
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between space-x-5">
        <div className="text-xl font-medium">Convert your $VeWisp to $Wisp</div>
        <Button
          className="!px-5 !py-1 text-base font-Poppins whitespace-pre-wrap"
          isLoading={isCreating}
          onClick={(): void => setIsOpenCreateModal(true)}
        >
          Create Vesting NFT
        </Button>
      </div>
      {!!veWispData.veWispNftObjs.length && (
        <div className="mt-5">
          <div className="font-medium">Your vesting NFTs</div>
          <div className="mt-4 space-y-2">
            {veWispData.veWispNftObjs.map((obj) => {
              const now = Date.now();
              const lockedTime = obj.lockedTime.getTime();
              const delta = now - lockedTime >= 0 ? now - lockedTime : 0;
              let percent = 0;
              if (delta >= milestones[2].ms) {
                percent = 100;
              } else if (delta >= milestones[1].ms) {
                const linearTime = delta - milestones[1].ms;
                percent =
                  milestones[1].percent +
                  ((milestones[2].percent - milestones[1].percent) / (milestones[2].ms - milestones[1].ms)) *
                    linearTime;
              } else if (delta >= milestones[0].ms) {
                const linearTime = delta - milestones[0].ms;
                percent =
                  milestones[0].percent +
                  ((milestones[1].percent - milestones[0].percent) / (milestones[1].ms - milestones[0].ms)) *
                    linearTime;
              }
              const estimateWisp = obj.amount.multiply(Math.floor(percent * 100)).divide(10_000);
              return (
                <div
                  className="border border-white border-opacity-10 rounded p-3 grid grid-cols-2 gap-4 md:grid-cols-3"
                  key={obj.object}
                >
                  <div>
                    <div className="text-pNeutral-500 font-medium text-sm">Lock time</div>
                    <div className="mt-1">{moment(obj.lockedTime).format("MMMM Do YYYY, h:mm:ss a")}</div>
                  </div>
                  <div>
                    <div className="text-pNeutral-500 font-medium text-sm">Estimate Wisp</div>
                    <div className="mt-1">{estimateWisp.toExact(undefined, 4)} WISP</div>
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <div className="text-pNeutral-500 font-medium text-sm">Action</div>
                    <div className="flex items-center space-x-3 mt-1">
                      <Button
                        className="px-4 py-1 font-medium rounded-md !bg-gradient-to-r !from-orange-500 !to-yellow-900"
                        disabled={claimingId === obj.object}
                        isLoading={claimingId === obj.object}
                        onClick={(): Promise<void> => handleClaimWisp(obj)}
                      >
                        Claim
                      </Button>
                      <Button
                        className="px-4 py-1 font-medium rounded-md !bg-none !bg-transparent border border-white border-opacity-10 hover:text-white"
                        disabled={withdrawId === obj.object}
                        isLoading={withdrawId === obj.object}
                        onClick={(): Promise<void> => handleWithdrawVeWisp(obj)}
                      >
                        Withdraw VeWisp
                      </Button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <CreateVestingVeWispModal
        isLoading={isCreating}
        isOpen={isOpenCreateModal}
        veWispBalance={veWispData.totalBalance}
        onClick={handleCreateVestingNft}
        onClose={(): void => setIsOpenCreateModal(false)}
      />
    </div>
  );
}
