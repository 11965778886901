import CoinAPI from "api/coin";
import { useQuery } from "react-query";
import { Coin } from "sdk/entities/coin";
import { NetworkEnv } from "utils/constant";

type Props = {
  networkEnv: NetworkEnv;
  defaultList?: Coin[];
};

export function useAllStCoins({ networkEnv, defaultList = [] }: Props): { allStCoins: Coin[] | undefined } {
  const { data: allStCoins } = useQuery<Coin[]>(["get-all-st-coins", networkEnv], async () => {
    const resp = await CoinAPI.getCoins(networkEnv, {
      searchTerm: "",
      page: 1,
      pageSize: 1000,
      isStToken: true,
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data = (resp?.data?.data ?? []).map((piece: any) => {
      return new Coin(piece.package_addr, piece.module, piece.type, piece.decimal ?? 0, piece.treasury_addr, {
        imageUrl: piece.icon_url,
        ticker: piece.symbol,
        projectName: piece.symbol,
        description: piece.description,
      });
    });
    return [...defaultList, ...data];
  });

  return { allStCoins };
}
