import { ReactElement } from "react";
import clsx from "clsx";

type Props = {
  label: string;
  checked: boolean;
  className?: string;
  onClick?: () => void;
};

export function Checkbox({ label, checked, className, onClick }: Props): ReactElement {
  return (
    <div className={clsx("flex items-center space-x-2 cursor-pointer", className)} onClick={onClick}>
      {checked ? (
        <div className="w-4 h-4 flex items-center justify-center rounded-full bg-btn">
          <div className="w-2 h-2 bg-dark-400 rounded-full"></div>
        </div>
      ) : (
        <div className="w-4 h-4 border-2 border-[#ccc] rounded-full"></div>
      )}
      <div className="text-pNeutral-800">{label}</div>
    </div>
  );
}
