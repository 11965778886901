import { ReactElement } from "react";
import { WalletProvider } from "@suiet/wallet-kit";
import { WispSettingsProvider } from "contexts/WispSettingsContext";
import { domAnimation, LazyMotion } from "framer-motion";
import { BridgeView } from "modules/bridge";
import { WormHole } from "modules/bridge/WormHole";
import VeWispView from "modules/earn/vewisp/VeWispView";
import YieldFarming from "modules/earn/YieldFarming";
import LsdFi from "modules/lsd-fi";
import Pools from "modules/pools";
import { AddLiquidityView } from "modules/pools/AddLiquidityView";
import { PredictionView } from "modules/prediction";
import { RemoveLiquidityView } from "modules/remove-liquidity";
import { Trade } from "modules/swap";
import { WisppointView } from "modules/wisppoint";
import { QueryClient, QueryClientProvider } from "react-query";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Layout from "./components/atoms/Layout";
import { WalletHelperContext } from "./contexts/WalletHelperContext";
import { PATH } from "./utils/constant";

import "react-toastify/dist/ReactToastify.min.css";

const queryClient = new QueryClient();

function App(): ReactElement {
  return (
    <QueryClientProvider client={queryClient}>
      <WalletProvider>
        <LazyMotion features={domAnimation} strict>
          <WispSettingsProvider>
            <WalletHelperContext>
              <Layout>
                <Routes>
                  <Route element={<AddLiquidityView />} path={PATH.ADD_LIQUIDITY} />
                  <Route element={<RemoveLiquidityView />} path={PATH.REMOVE_LIQUIDITY} />
                  <Route element={<Pools />} path={PATH.POOL} />
                  <Route element={<PredictionView />} path={PATH.PREDICTION} />
                  <Route element={<WisppointView />} path={PATH.WISPPOINT} />
                  <Route element={<BridgeView />} path={PATH.BRIDGE} />
                  <Route element={<WormHole />} path={PATH.WORMHOLE} />
                  <Route element={<YieldFarming />} path={PATH.YIELD} />
                  <Route element={<VeWispView />} path={PATH.VE_WISP} />
                  <Route element={<LsdFi />} path={PATH.LSDFI} />
                  {/* <Route element={<IdoView />} path={PATH.IDO} /> */}
                  <Route element={<Trade />} path="/" />
                </Routes>
              </Layout>
            </WalletHelperContext>
          </WispSettingsProvider>
          <ToastContainer
            autoClose={5_000}
            bodyClassName="text-white [&>div:nth-child(2)]:line-clamp-3"
            hideProgressBar={false}
            newestOnTop={true}
            position="top-right"
            rtl={false}
            toastClassName="md:top-16 bg-dark-600 z-[100]"
            closeOnClick
            pauseOnFocusLoss
            pauseOnHover
          />
        </LazyMotion>
      </WalletProvider>
    </QueryClientProvider>
  );
}

export default App;
