import { ReactElement } from "react";
import PoolsTable from "modules/pools/PoolsTable";

function Pools(): ReactElement {
  return (
    <div className="relative max-w-7xl w-full mx-auto z-10">
      <div className="text-2xl sm:text-4xl font-extrabold pb-4 sm:pb-8">Pools</div>
      <div className="flex justify-center">
        <div className="w-full mb-10">
          <PoolsTable />
        </div>
      </div>
    </div>
  );
}

export default Pools;
