import { ReactElement, useState } from "react";
import clsx from "clsx";
import { ArrowLeftIcon } from "components/atoms/icons/ArrowLeftIcon";
import { SettingsIcon } from "components/atoms/icons/SettingsIcon";
import { SettingsModal } from "components/molecules/SettingsModal";
import { ZapInForm } from "modules/zapin/ZapInForm";
import { Link } from "react-router-dom";

import { AddLiquidity } from "./AddLiquidity";

enum Tab {
  ADD_LIQUIDITY,
  ZAP_IN,
}

export function AddLiquidityView(): ReactElement {
  const [tab, setTab] = useState<Tab>(Tab.ADD_LIQUIDITY);
  const [isOpenSettingModal, setIsOpenSettingModal] = useState<boolean>(false);

  return (
    <div className="relative w-full flex-1 space-y-8 z-10 flex flex-col justify-center">
      <div className={clsx("bg-dark-600 p-8 rounded-2xl max-w-md w-full space-y-8", "mx-auto")}>
        <div className="flex justify-between items-center">
          <div className="flex space-x-5">
            <div className="flex items-center text-xl font-bold">
              <Link to="/pools">
                <ArrowLeftIcon className="mr-4" />
              </Link>
              <span
                className={clsx(tab === Tab.ADD_LIQUIDITY ? "text-white" : "text-gray-700")}
                onClick={(): void => setTab(Tab.ADD_LIQUIDITY)}
              >
                Add
              </span>
              <span className="h-5 w-0.5 bg-white bg-opacity-20 mx-4"></span>
              <span
                className={clsx(tab === Tab.ZAP_IN ? "text-white" : "text-gray-700")}
                onClick={(): void => setTab(Tab.ZAP_IN)}
              >
                Zap In
              </span>
            </div>
          </div>
          <div className="shrink-0 cursor-pointer" onClick={(): void => setIsOpenSettingModal(true)}>
            <SettingsIcon className="h-6 w-6" />
          </div>
        </div>
        {tab === Tab.ADD_LIQUIDITY ? <AddLiquidity /> : <ZapInForm />}
        <SettingsModal isOpen={isOpenSettingModal} onClose={(): void => setIsOpenSettingModal(false)} />
      </div>
    </div>
  );
}
