import { lazy, ReactElement, Suspense, useEffect, useState } from "react";
import { useWallet } from "@suiet/wallet-kit";
import { ChartIcon } from "components/atoms/icons/ChartIcon";
import { LoadingRing } from "components/atoms/LoadingRing";
import { useSwiper } from "components/atoms/swiper/SwiperProvider";
import { useWispSettings } from "contexts/WispSettingsContext";
import { useCurrentRounds } from "hooks/useCurrentRounds";
import SwiperCore, { FreeMode, Keyboard, Mousewheel } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { ROUND_STATUS } from "./constant";
import { History } from "./History";
import { Leaderboard } from "./Leaderboard";
import { RoundCard } from "./RoundCard";
import { Round } from "./types";

import "swiper/css/bundle";
import "./style.css";

SwiperCore.use([Keyboard, Mousewheel, FreeMode]);

const TradingViewChart = lazy(() => import("./components/TradingViewChart"));

export function Prediction(): ReactElement {
  const suietWallet = useWallet();

  const address = suietWallet.address;
  const [openChart, setOpenChart] = useState(false);
  const [openLeaderboard, setOpenLeaderboard] = useState(false);

  const { swiper, setSwiper } = useSwiper();
  const { settings } = useWispSettings();
  const currentRounds = useCurrentRounds(settings.networkEnv, address);

  useEffect(() => {
    if (!currentRounds.length || !swiper) {
      return;
    }
    const index = currentRounds.findIndex((i) => i.status === ROUND_STATUS.LIVE);
    if (index === -1) {
      return;
    }
    swiper.slideTo(index, 500);
  }, [swiper, currentRounds.length]);

  if (openLeaderboard) {
    return <Leaderboard onBack={(): void => setOpenLeaderboard(false)}></Leaderboard>;
  }

  return (
    <div className="pb-7 pt-20">
      <History onOpenLeaderBoard={(): void => setOpenLeaderboard(true)} />
      <Swiper
        className="-mx-4 mb-12"
        freeMode={{ enabled: true, sticky: true, momentumRatio: 0.25, momentumVelocityRatio: 0.5 }}
        slidesPerView="auto"
        spaceBetween={16}
        centeredSlides
        keyboard
        mousewheel
        resizeObserver
        onBeforeDestroy={(): void => setSwiper(null)}
        onSwiper={setSwiper}
      >
        {currentRounds.map((round: Round) => (
          <SwiperSlide key={round.epoch}>
            <RoundCard round={round} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="flex items-center justify-center">
        <div
          className="flex items-center space-x-2 px-4 py-2 rounded-full mb-5 cursor-pointer bg-white text-dark-600"
          onClick={(): void => setOpenChart((prev) => !prev)}
        >
          <ChartIcon className="w-4 h-4" />
          <span className="font-semibold">{openChart ? "Close chart" : "Open chart"}</span>
        </div>
      </div>
      {openChart && (
        <Suspense
          fallback={
            <div className="bg-dark-600 rounded-xl p-4 flex items-center justify-center mb-5 max-w-[1200px] mx-auto">
              <LoadingRing size={40} />
            </div>
          }
        >
          <TradingViewChart className="mb-5 max-w-[1200px] mx-auto" />
        </Suspense>
      )}
    </div>
  );
}
