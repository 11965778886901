import React from "react";
import { TransactionBlock } from "@mysten/sui.js";
import { useWallet } from "@suiet/wallet-kit";
import { AssetLogo } from "components/atoms/AssetLogo";
import { Button } from "components/atoms/Button";
import { TriangleDownIcon } from "components/atoms/icons/TriangleDownIcon";
import { AssetInput } from "components/molecules/AssetInput";
import { useWalletHelper } from "contexts/WalletHelperContext";
import { useWispSettings } from "contexts/WispSettingsContext";
import { useLsdRegistry } from "hooks/useLsdRegistry";
import { Amount } from "sdk/entities/amount";
import { Percent } from "sdk/entities/percent";
import { ONE_HUNDRED_PERCENT } from "sdk/misc";
import { toCommaSeparated } from "sdk/utils/formatNumber";
import { isTooManyDecimals } from "sdk/utils/tryParseAmount";
import { SUI, WISP_SUI } from "utils/coins";
import { WISP_CONFIG } from "utils/constant";
import { extractGasPayment, getSuiObjectRefs } from "utils/getCoinObjectIds";

import { useWithdrawLsd } from "./hooks/useWithdrawLsd";
import * as ActionHandler from "./reducers/actionHandler";
import * as Reducers from "./reducers/depositReducer";

export default function LsdWithdraw(): React.ReactElement {
  const [state, dispatch] = React.useReducer(Reducers.reducer, Reducers.initialState);
  const { balance, inputError, parsedAmount } = useWithdrawLsd(state);
  const { setOpenSelectWallet, signAndExecuteTransaction } = useWalletHelper();
  const { adapter, address } = useWallet();
  const { settings } = useWispSettings();

  const [isBuildingTx, setIsBuildingTx] = React.useState<boolean>(false);

  const { data: registryData } = useLsdRegistry({ customRPC: settings.customRPC, networkEnv: settings.networkEnv });

  const withdrawAmounts = React.useMemo(() => {
    if (!registryData) {
      return [];
    }
    const feePercent = new Percent(registryData.redemptionFee, 10_000);
    const actualWithdrawAmt = parsedAmount?.multiply(ONE_HUNDRED_PERCENT.subtract(feePercent));
    const totalSupply = Amount.fromRawAmount(WISP_SUI, registryData.totalSupplyWispSui);
    const wispSuiPercent = new Percent(actualWithdrawAmt?.quotient ?? 0, totalSupply.quotient);
    return registryData.balances.map((b) => {
      return b.multiply(wispSuiPercent);
    });
  }, [parsedAmount, registryData?.totalSupplyWispSui, registryData?.balances, registryData?.redemptionFee]);

  function handleChangeInput(value: string): void {
    if (isTooManyDecimals(value, state.coin)) {
      return;
    }
    ActionHandler.userInput(dispatch, { typedValue: value });
  }

  async function handleWithdraw(): Promise<void> {
    if (isBuildingTx || !address || !parsedAmount || !parsedAmount.greaterThan(0) || !registryData) {
      return;
    }
    setIsBuildingTx(true);
    const objectRefs = await getSuiObjectRefs(settings.customRPC, address, [SUI, WISP_SUI]);
    const suiObjectRefs = objectRefs[SUI.type] ?? [];
    const wispSuiObjectRefs = objectRefs[WISP_SUI.type] ?? [];
    const [suiGas] = extractGasPayment(suiObjectRefs, 100_000_000n);
    const tx = new TransactionBlock();
    tx.setGasPayment(suiGas);
    try {
      const [withrawReceipt] = tx.moveCall({
        // Get withdraw receipt object
        target: `${WISP_CONFIG.lsd.package}::lsdfi::withdraw_mul_coin`,
        arguments: [
          tx.object(WISP_CONFIG.lsd.registry),
          tx.makeMoveVec({
            // WISP_SUI
            objects: wispSuiObjectRefs.map((i) => {
              return tx.object(i.objectId);
            }),
          }),
          tx.pure(parsedAmount.quotient.toString()), // wispSUI amount
        ],
      });
      for (const bl of registryData.balances) {
        tx.moveCall({
          target: `${WISP_CONFIG.lsd.package}::lsdfi::consume_withdraw_receipt`,
          typeArguments: [bl.coin.type],
          arguments: [tx.object(WISP_CONFIG.lsd.registry), withrawReceipt],
        });
      }
      tx.moveCall({
        // Destroy withdraw receipt object
        target: `${WISP_CONFIG.lsd.package}::lsdfi::drop_withdraw_receipt`,
        arguments: [withrawReceipt],
      });

      tx.setGasBudget(100_000_000);
      await signAndExecuteTransaction(tx);
      handleChangeInput("");
    } catch (e) {
      console.error(e);
    } finally {
      setIsBuildingTx(false);
    }
  }

  const isOverPoolBalance =
    !!registryData?.totalSupplyWispSui && (parsedAmount?.quotient ?? 0n) >= registryData.totalSupplyWispSui;

  return (
    <React.Fragment>
      <div>
        <AssetInput
          asset={WISP_SUI}
          balance={balance}
          balances={[]}
          label="from"
          maxAmount={true}
          otherAsset={undefined}
          value={toCommaSeparated(state.typedValue)}
          fixedCoin
          isFixedList
          onInputChange={handleChangeInput}
          onSelect={(): void => {}}
        />
        <div className="my-3">
          <TriangleDownIcon className="mx-auto" />
        </div>
        <div className="p-5 rounded-xl border border-pGreen-400 bg-pGreen-400 bg-opacity-5 grid grid-cols-2 gap-3">
          {withdrawAmounts.map((bl) => {
            return (
              <div className="flex items-start space-x-3" key={bl.coin.type}>
                <AssetLogo asset={bl.coin} className="mt-1" />
                <div>
                  <div className="text-sm">{bl.coin.name}</div>
                  <div className="text-sm text-pGreen-500 font-medium break-all">{bl.toExact()}</div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="text-xs mt-2">
          <div className="text-gray-300">Withdraw Fee: {registryData ? registryData.redemptionFee / 100 : "--"}%</div>
        </div>
      </div>
      <div>
        {!adapter ? (
          <Button
            className="px-6 py-3 w-full text-xl font-semibold font-Poppins whitespace-pre-wrap"
            onClick={(): void => setOpenSelectWallet(true)}
          >
            Connect Wallet
          </Button>
        ) : (
          <Button
            className="px-6 py-3 w-full text-xl font-semibold font-Poppins whitespace-pre-wrap"
            disabled={Boolean(inputError || isBuildingTx || isOverPoolBalance)}
            isLoading={isBuildingTx}
            onClick={handleWithdraw}
          >
            {isOverPoolBalance ? "Too much WISPSUI" : inputError ?? (isBuildingTx ? "Withdrawing" : "Withdraw")}
          </Button>
        )}
      </div>
    </React.Fragment>
  );
}
