import React, { useReducer } from "react";
import { TransactionBlock } from "@mysten/sui.js";
import { useWallet } from "@suiet/wallet-kit";
import { Button } from "components/atoms/Button";
import { ConvertIcon } from "components/atoms/icons/ConvertIcon";
import { AssetInput } from "components/molecules/AssetInput";
import { useWalletHelper } from "contexts/WalletHelperContext";
import { useWispSettings } from "contexts/WispSettingsContext";
import { useAllStCoins } from "hooks/useAllStCoins";
import { useLsdDynamicFee } from "hooks/useLsdDynamicFee";
import { Amount } from "sdk/entities/amount";
import { Coin } from "sdk/entities/coin";
import { Percent } from "sdk/entities/percent";
import { ONE_HUNDRED_PERCENT } from "sdk/misc";
import { toCommaSeparated } from "sdk/utils/formatNumber";
import { isTooManyDecimals } from "sdk/utils/tryParseAmount";
import { SUI } from "utils/coins";
import { WISP_CONFIG } from "utils/constant";
import { extractGasPayment, getSuiObjectRefs } from "utils/getCoinObjectIds";

import { useExchange } from "./hooks/useExchange";
import * as ActionHandler from "./reducers/actionHandler";
import { Field } from "./reducers/actions";
import * as Reducers from "./reducers/exchangeReducer";

export default function LsdExchange(): React.ReactElement {
  const { settings } = useWispSettings();
  const { fungibleBalances, setOpenSelectWallet, signAndExecuteTransaction } = useWalletHelper();
  const { adapter, address } = useWallet();
  const [isBuildingTx, setIsBuildingTx] = React.useState<boolean>(false);

  const [state, dispatch] = useReducer(Reducers.reducer, Reducers.initialState);
  const { assets, balances, parsedAmount, inputError } = useExchange(state);

  const { allStCoins } = useAllStCoins({ networkEnv: settings.networkEnv });
  const fee = useLsdDynamicFee({
    networkEnv: settings.networkEnv,
    params: {
      amount: parsedAmount?.quotient.toString() ?? "",
      tokenAType: assets[Field.INPUT]?.type ?? "",
      tokenBType: assets[Field.OUTPUT]?.type ?? "",
    },
  });
  const feePercent = React.useMemo(() => {
    return fee ? new Percent(Math.floor(fee), 10_000) : undefined;
  }, [fee]);

  const outputAmount = React.useMemo(() => {
    if (!parsedAmount || !assets[Field.OUTPUT]) {
      return undefined;
    }
    return Amount.fromRawAmount(
      assets[Field.OUTPUT],
      parsedAmount.multiply(ONE_HUNDRED_PERCENT.subtract(feePercent ? feePercent : new Percent(0, 1))).quotient,
    );
  }, [feePercent, parsedAmount]);

  const allStBalances = React.useMemo(() => {
    return (allStCoins ?? []).map((coin) => {
      const amt = fungibleBalances?.find((f) => f.coin.equals(coin));
      if (amt) {
        return amt;
      }
      return Amount.fromRawAmount(coin, 0);
    });
  }, [allStCoins, fungibleBalances]);

  function handleSelectInputAsset(asset: Coin | undefined): void {
    ActionHandler.selectCoin(dispatch, { field: Field.INPUT, coin: asset });
  }

  function handleSelectOutputAsset(asset: Coin | undefined): void {
    ActionHandler.selectCoin(dispatch, { field: Field.OUTPUT, coin: asset });
  }

  function handleChangeInput(value: string): void {
    if (isTooManyDecimals(value, assets[Field.INPUT])) {
      return;
    }
    ActionHandler.userInput(dispatch, { typedValue: value });
  }

  function handleMaxInput(): void {
    handleChangeInput(balances[Field.INPUT]?.toExact({ groupSeparator: "" }) ?? "");
  }

  function handleSwitchAssets(): void {
    ActionHandler.switchCoin(dispatch);
  }

  async function handleExchange(): Promise<void> {
    const inCoin = state[Field.INPUT]?.coin;
    const outCoin = state[Field.OUTPUT]?.coin;
    if (isBuildingTx || !address || !inCoin || !outCoin || !parsedAmount || !parsedAmount.greaterThan(0)) {
      return;
    }
    setIsBuildingTx(true);
    const objectRefs = await getSuiObjectRefs(settings.customRPC, address, [SUI, inCoin]);
    const suiObjectRefs = objectRefs[SUI.type] ?? [];
    const inCoinObjectRefs = objectRefs[inCoin.type] ?? [];
    const [suiGas] = extractGasPayment(suiObjectRefs, 100_000_000n);
    const tx = new TransactionBlock();
    tx.setGasPayment(suiGas);
    try {
      tx.moveCall({
        target: `${WISP_CONFIG.lsd.package}::lsdfi::swap_mul_coin`,
        typeArguments: [inCoin.type, outCoin.type],
        arguments: [
          tx.object(WISP_CONFIG.lsd.registry),
          tx.object(WISP_CONFIG.lsd.aggregatorReg),
          tx.makeMoveVec({
            objects: inCoinObjectRefs.map((i) => {
              return tx.object(i.objectId);
            }),
          }),
          tx.pure(parsedAmount.quotient.toString()),
          tx.object("0x6"),
        ],
      });
      tx.setGasBudget(100_000_000);
      await signAndExecuteTransaction(tx);
      handleChangeInput("");
    } catch (e) {
      console.error(e);
    } finally {
      setIsBuildingTx(false);
    }
  }

  return (
    <React.Fragment>
      <div>
        <AssetInput
          asset={assets[Field.INPUT]}
          balance={balances[Field.INPUT]}
          balances={allStBalances}
          label="from"
          maxAmount={true}
          otherAsset={assets[Field.OUTPUT]}
          value={toCommaSeparated(state.typedValue)}
          isFixedList
          onInputChange={handleChangeInput}
          onMaxAmount={handleMaxInput}
          onSelect={handleSelectInputAsset}
        />
        <div className="mt-6 mb-2">
          <div className="w-8 h-8 mx-auto cursor-pointer" onClick={handleSwitchAssets}>
            <ConvertIcon className="text-pGreen-500 w-8 h-8" />
          </div>
        </div>
        <AssetInput
          asset={assets[Field.OUTPUT]}
          balance={undefined}
          balances={allStBalances}
          label="to"
          maxAmount={false}
          otherAsset={assets[Field.INPUT]}
          value={outputAmount?.toExact()}
          isFixedList
          readonly
          onInputChange={(): void => {}}
          onSelect={handleSelectOutputAsset}
        />
        <div className="text-xs mt-2">
          <div className="text-gray-300">Exchange Fee: {feePercent ? feePercent.toFixed(2) : "--"}%</div>
        </div>
      </div>
      <div>
        {!adapter ? (
          <Button
            className="px-6 py-3 w-full text-xl font-semibold font-Poppins whitespace-pre-wrap"
            onClick={(): void => setOpenSelectWallet(true)}
          >
            Connect Wallet
          </Button>
        ) : (
          <Button
            className="px-6 py-3 w-full text-xl font-semibold font-Poppins whitespace-pre-wrap"
            disabled={Boolean(inputError || isBuildingTx)}
            isLoading={isBuildingTx}
            onClick={handleExchange}
          >
            {inputError ?? (isBuildingTx ? "Processing" : "Exchange")}
          </Button>
        )}
      </div>
    </React.Fragment>
  );
}
