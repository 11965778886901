import { ReactElement, useEffect, useMemo, useState } from "react";
import { useWallet } from "@suiet/wallet-kit";
import { Button } from "components/atoms/Button";
import { Modal } from "components/atoms/Modal";
import { useWalletHelper } from "contexts/WalletHelperContext";
import { Amount } from "sdk/entities/amount";
import { toCommaSeparated } from "sdk/utils/formatNumber";
import { isTooManyDecimals, tryParseAmount } from "sdk/utils/tryParseAmount";
import { FarmWithNft } from "types/farm";

import { AssetInput } from "./AssetInput";

type Props = {
  farm: FarmWithNft | undefined;
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onWithdrawClick: (amount: Amount, farm: FarmWithNft) => void;
};

export function WithdrawLPModal({ farm, isOpen, isLoading, onClose, onWithdrawClick }: Props): ReactElement {
  const { adapter } = useWallet();
  const { setOpenSelectWallet } = useWalletHelper();
  const [typedValue, setTypedValue] = useState("");

  useEffect(() => {
    return () => {
      if (!isOpen) {
        setTypedValue("");
      }
    };
  }, [isOpen]);

  const stakingBalance = useMemo(() => {
    return farm?.spNft?.stakingBalance;
  }, [farm]);

  const amount = useMemo(() => {
    if (!farm?.stakingCoin || !typedValue) {
      return undefined;
    }
    return tryParseAmount(typedValue, farm.stakingCoin);
  }, [typedValue, farm?.stakingCoin]);

  const errorMsg: string | null = useMemo(() => {
    if (!stakingBalance || stakingBalance.equalTo(0)) {
      return "Empty balance";
    }
    if (!amount) {
      return "Enter an amount";
    }
    if (stakingBalance?.lessThan(amount)) {
      return "Insufficient balance";
    }
    return null;
  }, [amount, stakingBalance]);

  const handleChangeInput = (value: string): void => {
    if (isTooManyDecimals(value, farm?.stakingCoin)) {
      return;
    }
    setTypedValue(value);
  };

  const handleMaxInput = (): void => {
    if (!stakingBalance) {
      return;
    }
    setTypedValue(stakingBalance.toExact({ groupSeparator: "" }));
  };

  const handleWithdrawClick = (): void => {
    if (!farm || !amount || !amount.greaterThan(0)) {
      return;
    }
    onWithdrawClick(amount, farm);
  };

  return (
    <Modal isOpen={isOpen} title="Withdraw LP Modal" onClose={onClose}>
      <div className="space-y-4">
        <div>Enter LP amount you want to withdraw</div>
        <AssetInput
          asset={stakingBalance?.coin}
          balance={stakingBalance}
          label="Amount"
          otherAsset={undefined}
          value={toCommaSeparated(typedValue)}
          fixedCoin
          maxAmount
          onInputChange={handleChangeInput}
          onMaxAmount={handleMaxInput}
          onSelect={(): void => {}}
        />
        <div>
          {!adapter ? (
            <Button
              className="px-6 py-3 w-full text-xl font-Poppins whitespace-pre-wrap"
              onClick={(): void => setOpenSelectWallet(true)}
            >
              Connect Wallet
            </Button>
          ) : (
            <Button
              className="px-6 py-3 w-full text-xl font-Poppins whitespace-pre-wrap"
              disabled={Boolean(errorMsg)}
              isLoading={isLoading}
              onClick={handleWithdrawClick}
            >
              {errorMsg ?? (isLoading ? "Processing" : "Withdraw")}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}
