import { ReactElement } from "react";
import {
  Slider as ReachSlider,
  SliderHandle as ReachSliderHandle,
  SliderInput as ReachSliderInput,
  SliderRange as ReachSliderRange,
  SliderTrack as ReachSliderTrack,
} from "@reach/slider";
import clsx from "clsx";

type Props = React.ComponentPropsWithoutRef<typeof ReachSlider> & {
  rangeCls?: string;
};

export function RangeSlider({ className, rangeCls, ...rest }: Props): ReactElement {
  return (
    <ReachSliderInput className={clsx("range-slider", className)} {...rest}>
      <ReachSliderTrack>
        <ReachSliderRange className={rangeCls} />
        <ReachSliderHandle />
      </ReachSliderTrack>
    </ReachSliderInput>
  );
}
