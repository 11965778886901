import { Action, ActionType, BET_TYPE } from "./actions";

export function typeInput(dispatch: React.Dispatch<Action>, typedValue: string): void {
  dispatch({ type: ActionType.TYPE_INPUT, typedValue });
}

export function setBetType(dispatch: React.Dispatch<Action>, betType: BET_TYPE | null): void {
  dispatch({ type: ActionType.SET_BET_TYPE, betType });
}

export function resetForm(dispatch: React.Dispatch<Action>): void {
  dispatch({ type: ActionType.RESET_FORM });
}
