import { ReactElement, ReactNode, useMemo, useReducer, useState } from "react";
import { TransactionArgument, TransactionBlock } from "@mysten/sui.js";
import { useWallet } from "@suiet/wallet-kit";
import clsx from "clsx";
import { Button } from "components/atoms/Button";
import { ConvertIcon } from "components/atoms/icons/ConvertIcon";
import { NoteIcon } from "components/atoms/icons/NoteIcon";
import { RevertIcon } from "components/atoms/icons/RevertIcon";
import { SettingsIcon } from "components/atoms/icons/SettingsIcon";
import { Tooltip, TooltipButton, TooltipContent } from "components/atoms/Tooltip";
import { AssetInput } from "components/molecules/AssetInput";
import { SettingsModal } from "components/molecules/SettingsModal";
import { useWalletHelper } from "contexts/WalletHelperContext";
import { useWispSettings } from "contexts/WispSettingsContext";
import { TradeType } from "sdk/constants";
import { Amount } from "sdk/entities/amount";
import { Coin } from "sdk/entities/coin";
import { Trade } from "sdk/entities/trade";
import { toCommaSeparated } from "sdk/utils/formatNumber";
import { computeRealizedLPFeePercent, WarningSeverity, warningSeverity } from "sdk/utils/prices";
import { isTooManyDecimals } from "sdk/utils/tryParseAmount";
import { SUI } from "utils/coins";
import { WISP_CONFIG } from "utils/constant";
import { extractGasPayment, getSuiObjectRefs } from "utils/getCoinObjectIds";

import { useSwap } from "./hooks/useSwap";
import * as ActionHandler from "./reducers/actionHandler";
import { Field } from "./reducers/actions";
import * as Reducers from "./reducers/swapReducer";
import { TradeHistory } from "./TradeHistory";

function getPriceText(isPricesInverted: boolean, trade: Trade | undefined): string {
  const formattedPrice = isPricesInverted
    ? trade?.executionPrice.invert().toSignificant(4, { groupSeparator: "," })
    : trade?.executionPrice.toSignificant(4, { groupSeparator: "," });
  const labelPrice = isPricesInverted ? trade?.executionPrice.baseCoin.name : trade?.executionPrice.quoteCoin.name;
  const labelPriceInverted = isPricesInverted
    ? trade?.executionPrice.quoteCoin.name
    : trade?.executionPrice.baseCoin.name;
  return `1 ${labelPriceInverted} = ${formattedPrice ?? "-"} ${labelPrice}`;
}

export function Swap({ className }: { className?: string }): ReactElement {
  const [state, dispatch] = useReducer(Reducers.reducer, Reducers.initialState);
  const suietWallet = useWallet();
  const { settings } = useWispSettings();

  const adapter = suietWallet.adapter;
  const address = suietWallet.address;

  const { fungibleBalances, setOpenSelectWallet, signAndExecuteTransaction } = useWalletHelper();
  const { pool, assets, balances, parsedAmount, inputError, trade, isFetching } = useSwap(state);
  const [isOpenSettingModal, setIsOpenSettingModal] = useState<boolean>(false);
  const [isPricesInverted, setIsPricesInverted] = useState<boolean>(false);
  const [isBuildingTx, setIsBuildingTx] = useState<boolean>(false);

  const assetBalances = useMemo(() => {
    return fungibleBalances ?? [];
  }, [fungibleBalances]);

  const parsedAmounts: { [field in Field]?: Amount } = useMemo(
    () => ({
      [Field.INPUT]: state.independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
      [Field.OUTPUT]: state.independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
    }),
    [parsedAmount, state.independentField, trade?.inputAmount, trade?.outputAmount],
  );
  const [dependentField, tradeType]: [Field, TradeType] =
    state.independentField === Field.INPUT
      ? [Field.OUTPUT, TradeType.EXACT_INPUT]
      : [Field.INPUT, TradeType.EXACT_OUTPUT];
  const formattedAmounts = {
    [state.independentField]: toCommaSeparated(state.typedValue),
    [dependentField]: parsedAmounts[dependentField]?.toExact() ?? "",
  };
  const { realizedLPFee, priceImpact, route } = useMemo(() => {
    if (!trade) return { realizedLPFee: undefined, priceImpact: undefined, route: undefined };
    const route = trade.route.path.map((asset) => asset.name).join(" > ");
    const realizedLpFeePercent = computeRealizedLPFeePercent(trade);
    const realizedLPFee = trade.inputAmount.multiply(realizedLpFeePercent);
    const priceImpact = trade.priceImpact.subtract(realizedLpFeePercent);
    return { priceImpact, realizedLPFee, route };
  }, [trade]);
  const maxInputOrMinOutput: Amount | undefined =
    tradeType === TradeType.EXACT_INPUT
      ? trade?.minimumAmountOut(settings.slippageTolerance)
      : trade?.maximumAmountIn(settings.slippageTolerance);
  const maxAmountBelongToInputAsset = Boolean(balances[Field.INPUT]?.coin.equals(assets[Field.INPUT]));

  function handleSelectInputAsset(asset: Coin | undefined): void {
    ActionHandler.selectCoin(dispatch, { field: Field.INPUT, coin: asset });
  }

  function handleSelectOutputAsset(asset: Coin | undefined): void {
    ActionHandler.selectCoin(dispatch, { field: Field.OUTPUT, coin: asset });
  }

  function handleChangeInput(value: string): void {
    if (isTooManyDecimals(value, assets[Field.INPUT])) {
      return;
    }
    ActionHandler.userInput(dispatch, { field: Field.INPUT, typedValue: value });
  }

  function handleChangeOutput(value: string): void {
    if (isTooManyDecimals(value, assets[Field.OUTPUT])) {
      return;
    }
    ActionHandler.userInput(dispatch, { field: Field.OUTPUT, typedValue: value });
  }

  function handleMaxInput(): void {
    if (maxAmountBelongToInputAsset) {
      handleChangeInput(balances[Field.INPUT]?.toExact({ groupSeparator: "" }) ?? "");
    }
  }

  function handleSwitchAssets(): void {
    ActionHandler.switchCoin(dispatch);
  }

  async function handleSwap(): Promise<void> {
    if (isBuildingTx || !address || !pool || !trade) {
      return;
    }
    setIsBuildingTx(true);
    const isExactIn = tradeType === TradeType.EXACT_INPUT;
    const objectRefs = await getSuiObjectRefs(settings.customRPC, address, [SUI, trade.inputAmount.coin]);
    const suiObjectRefs = objectRefs[SUI.type] ?? [];
    const coinObjectRefs = objectRefs[trade.inputAmount.coin.type] ?? [];
    const [suiGas] = extractGasPayment(suiObjectRefs, 100_000_000n);
    const isSui = trade.inputAmount.coin.equals(SUI);
    const tx = new TransactionBlock();
    let splitCoin = undefined;
    if (isSui) {
      splitCoin = tx.splitCoins(tx.gas, [
        tx.pure(
          isExactIn
            ? trade.inputAmount.quotient.toString()
            : trade.maximumAmountIn(settings.slippageTolerance).quotient.toString(),
        ),
      ])[0];
    }
    if (!isSui) {
      tx.setGasPayment(suiGas);
    }
    try {
      tx.moveCall({
        target: `${WISP_CONFIG.dex.swapPackage}::${WISP_CONFIG.dex.swapModuleRouter}::${
          isExactIn ? WISP_CONFIG.dex.functions.swapExactInput : WISP_CONFIG.dex.functions.swapExactOutput
        }`,
        typeArguments: [trade.inputAmount.coin.type, trade.outputAmount.coin.type],
        arguments: [
          tx.object(WISP_CONFIG.dex.swapSetting),
          tx.makeMoveVec({
            objects: isSui
              ? ([splitCoin] as TransactionArgument[])
              : coinObjectRefs.map((i) => {
                  return tx.object(i.objectId);
                }),
          }),
          tx.pure(isExactIn ? trade.inputAmount.quotient.toString() : trade.outputAmount.quotient.toString()),
          tx.pure(
            isExactIn
              ? trade.minimumAmountOut(settings.slippageTolerance).quotient
              : trade.maximumAmountIn(settings.slippageTolerance).quotient,
          ),
        ],
      });
      tx.setGasBudget(100_000_000);
      await signAndExecuteTransaction(tx);
      if (isExactIn) {
        handleChangeInput("");
      } else {
        handleChangeOutput("");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsBuildingTx(false);
    }
  }

  const priceText = getPriceText(isPricesInverted, trade);
  const fmtPriceImpact = priceImpact?.toFixed(2);

  return (
    <div className={clsx("flex flex-col-reverse lg:flex-row lg:space-x-8 w-full max-w-[1150px]", className)}>
      <TradeHistory className="mt-8 lg:mt-0" />
      <div className="bg-dark-600 p-8 rounded-2xl max-w-md w-full space-y-8 mx-auto">
        <div className="flex justify-between items-center">
          <div className="text-xl font-bold">Swap</div>
          <div className="shrink-0 cursor-pointer" onClick={(): void => setIsOpenSettingModal(true)}>
            <SettingsIcon className="h-6 w-6" />
          </div>
        </div>
        <div>
          <AssetInput
            asset={assets[Field.INPUT]}
            balance={balances[Field.INPUT]}
            balances={assetBalances}
            label="from"
            maxAmount={maxAmountBelongToInputAsset}
            otherAsset={assets[Field.OUTPUT]}
            value={formattedAmounts[Field.INPUT]}
            onInputChange={handleChangeInput}
            onMaxAmount={handleMaxInput}
            onSelect={handleSelectInputAsset}
          />
          <div className="mt-6 mb-2">
            <div className="w-8 h-8 mx-auto cursor-pointer" onClick={handleSwitchAssets}>
              <ConvertIcon className="text-pGreen-500 w-8 h-8" />
            </div>
          </div>
          <AssetInput
            asset={assets[Field.OUTPUT]}
            balance={balances[Field.OUTPUT]}
            balances={assetBalances}
            label="to"
            maxAmount={false}
            otherAsset={assets[Field.INPUT]}
            value={formattedAmounts[Field.OUTPUT]}
            onInputChange={handleChangeOutput}
            onSelect={handleSelectOutputAsset}
          />
        </div>
        <div>
          {!adapter ? (
            <Button
              className="px-6 py-3 w-full text-xl font-semibold font-Poppins whitespace-pre-wrap"
              onClick={(): void => setOpenSelectWallet(true)}
            >
              Connect Wallet
            </Button>
          ) : (
            <Button
              className="px-6 py-3 w-full text-xl font-semibold font-Poppins whitespace-pre-wrap"
              disabled={Boolean(inputError || isFetching || isBuildingTx)}
              isLoading={isFetching || isBuildingTx}
              onClick={handleSwap}
            >
              {isFetching ? "Loading" : inputError ?? (isBuildingTx ? "Swapping" : "Swap")}
            </Button>
          )}
        </div>

        <div className="space-y-4">
          <SwapInfoItem
            label="Rate"
            value={
              <div
                className="cursor-pointer flex items-center justify-end space-x-3"
                onClick={(): void => setIsPricesInverted(!isPricesInverted)}
              >
                {trade ? (
                  <>
                    <span className="text-right">{priceText}</span>
                    <RevertIcon className="w-4 h-4 text-pGreen-500" />
                  </>
                ) : (
                  "--"
                )}
              </div>
            }
          />

          <SwapInfoItem
            label={tradeType === TradeType.EXACT_INPUT ? "Expected Output" : "Expected Input"}
            tooltip="The amount you expect to receive at the current market price. You may receive less or more if the market price changes while your transaction is pending."
            value={`${parsedAmounts[dependentField]?.toExact() ?? "--"} ${
              parsedAmounts[dependentField]?.coin.name ?? ""
            }`}
          />

          <SwapInfoItem
            label="Price Impact"
            tooltip="The impact your trade has on the market price of this pool."
            value={
              <span className={priceImpact ? priceImpactCls(warningSeverity(priceImpact)) : ""}>
                {fmtPriceImpact ? (fmtPriceImpact === "0.00" ? "<0.01" : fmtPriceImpact) : "-"}%
              </span>
            }
          />
          <SwapInfoItem
            label={
              <>
                <span>
                  {tradeType === TradeType.EXACT_INPUT ? `Minimum received` : `Maximum sent`}
                  <br />
                  <span className="text-xs text-gray-500">
                    after slippage ({settings.slippageTolerance.toFixed(2)}%)
                  </span>
                </span>
              </>
            }
            tooltip={
              "The minimum amount you are guaranteed to receive. If the price slips any further, your transaction will revert."
            }
            value={`${maxInputOrMinOutput?.toExact() ?? "--"} ${maxInputOrMinOutput?.coin.name ?? ""}`}
          />
          {/* <SwapInfoItem
            label="Network Fee"
            tooltip={"The fee paid to miners who process your transaction. This must be paid in SUI."}
            value={`-- SUI`}
          /> */}
          <SwapInfoItem
            label="LP Fee"
            tooltip={"For each trade, 0.3% goes to liquidity providers."}
            value={`${realizedLPFee?.toExact() ?? ""} ${realizedLPFee?.coin.name ?? ""}`}
          />
          <SwapInfoItem label="Route" value={route} />
        </div>
        <SettingsModal isOpen={isOpenSettingModal} onClose={(): void => setIsOpenSettingModal(false)} />
      </div>
    </div>
  );
}

function SwapInfoItem({
  label,
  value,
  tooltip,
}: {
  label: ReactNode;
  value: ReactNode;
  tooltip?: string;
}): ReactElement {
  return (
    <div className="flex items-center justify-between space-x-4 text-sm font-semibold text-pNeutral-800">
      <div className="flex items-center space-x-1.5">
        <span>{label}</span>
        <div>
          {!!tooltip && (
            <Tooltip>
              <TooltipButton className="h-4 w-4">
                <NoteIcon className="w-4 h-4" />
              </TooltipButton>
              <TooltipContent>
                <div>{tooltip}</div>
              </TooltipContent>
            </Tooltip>
          )}
        </div>
      </div>
      {typeof value === "string" ? <div className="text-right">{value}</div> : value}
    </div>
  );
}

function priceImpactCls(severity?: WarningSeverity): string {
  switch (severity) {
    case 0:
      return "text-green-500";
    case 1:
      return "text-yellow-500";
    case 2:
      return "text-orange-500";
    case 3:
      return "text-red-400";
    case 4:
      return "text-red-600";
    default:
      return "";
  }
}
