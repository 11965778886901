import * as React from "react";

import { IconProps } from "./type";

export function DownIcon(props: IconProps): React.ReactElement<IconProps> {
  return (
    <svg fill="none" height="13" viewBox="0 0 12 13" width="12" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M6.5 6.5V2.5H5.5V6.5H2L6 10.5L10 6.5H6.5Z" fill="currentColor" />
    </svg>
  );
}
