import { ReactElement } from "react";

import { IconProps } from "./type";

export function HistoryIcon(props: IconProps): ReactElement<IconProps> {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 2C17.523 2 22 6.47701 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12H4C4.00015 13.8812 4.66326 15.7023 5.87283 17.1431C7.0824 18.5839 8.76104 19.5524 10.6138 19.8784C12.4666 20.2043 14.375 19.8669 16.0037 18.9254C17.6324 17.984 18.8772 16.4986 19.5194 14.7304C20.1616 12.9622 20.1601 11.0242 19.5152 9.25692C18.8703 7.48967 17.6233 6.00623 15.9931 5.06722C14.363 4.12821 12.4541 3.79372 10.6018 4.1225C8.74954 4.45129 7.07237 5.42233 5.865 6.865L8 9H2V3L4.447 5.446C5.38488 4.36319 6.54489 3.49497 7.84817 2.90036C9.15146 2.30574 10.5675 1.99867 12 2ZM13 7V11.585L16.243 14.828L14.828 16.243L11 12.413V7H13Z"
        fill="currentColor"
      />
    </svg>
  );
}
