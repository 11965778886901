/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";

export type ToggleButtonContextValue = {
  value: any | undefined;
  setValue: (value: any) => void;
};

export const ToggleButtonContext = React.createContext<ToggleButtonContextValue>({
  value: undefined,
  setValue: () => {},
});
