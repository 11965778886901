import { ReactElement } from "react";
import clsx from "clsx";
import { ExpiredStatusIcon } from "components/atoms/icons/ExpiredStatusIcon";
import { useCountDown } from "hooks/useCountDown";

import { ROUND_STATUS } from "../constant";
import { Round } from "../types";
import { getStatusText } from "../utils";
import { Payout } from "./Payout";

type Props = {
  round: Round;
  className?: string;
};

function leadingZero(num: number): string {
  if (num < 10) {
    return `0${num}`;
  }
  return num.toString();
}

export function LaterPayoutCardContent({ round, className }: Props): ReactElement {
  const [min, sec] = useCountDown(round.startTime);

  const isOvertime = min === 0 && sec === 0;

  return (
    <div
      className={clsx(
        "rounded-2xl bg-dark-600 overflow-hidden",
        round.status !== ROUND_STATUS.LIVE && round.status !== ROUND_STATUS.NEXT && "opacity-50",
        className,
      )}
    >
      <div
        className={clsx(
          "flex justify-between items-center space-x-2 p-4",
          round.status === ROUND_STATUS.NEXT ? "bg-btn" : " bg-[#1E5473]",
        )}
      >
        <span>
          <ExpiredStatusIcon />
        </span>
        <span className="flex-1 uppercase font-semibold">{getStatusText(round.status)}</span>
        <div className="font-semibold text-sm ml-2">#{round.epoch}</div>
      </div>
      <div>
        <Payout isActive={false} payout="--" type="up" />
        <div className="border-2 border-white border-opacity-20 rounded-3xl px-5 py-7 mx-5">
          <div className="text-center">{isOvertime ? "Round processing..." : "Round starts"}</div>
          {!isOvertime && (
            <div className="text-center font-semibold text-xl">
              ~{leadingZero(min)}:{leadingZero(sec)}
            </div>
          )}
        </div>
        <Payout isActive={false} payout="--" type="down" />
      </div>
    </div>
  );
}
