import { ReactElement } from "react";
import WormholeBridge, { WormholeConnectConfig } from "@wormhole-foundation/wormhole-connect";

const config: WormholeConnectConfig = {
  env: "testnet",
  networks: ["goerli", "mumbai", "bsc", "fuji", "avalanche", "moonbasealpha", "solana"],
  tokens: ["SUI", "ETH", "WETH", "USDCeth", "MATIC", "WMATIC", "BNB", "WBNB"],
  mode: "dark",
};

export function WormHole(): ReactElement {
  return <WormholeBridge config={config} />;
}
