import { useMemo } from "react";
import { useWallet } from "@suiet/wallet-kit";
import { useWalletHelper } from "contexts/WalletHelperContext";
import { Amount } from "sdk/entities/amount";
import { tryParseAmount } from "sdk/utils/tryParseAmount";
import { SUI } from "utils/coins";

import { State } from "../reducers/depositReducer";

type Response = {
  balance: Amount | undefined;
  parsedAmount: Amount | undefined;
  inputError: string | undefined;
};

const MINIMUM_DEPOSIT = Amount.fromRawAmount(SUI, 4_000_000_000);

export function useLsdDeposit(state: State): Response {
  const { coin, typedValue } = state;
  const { fungibleBalances } = useWalletHelper();
  const suietWallet = useWallet();
  const address = suietWallet.address;

  const balance = useMemo(() => {
    if (!fungibleBalances?.length || !coin) {
      return undefined;
    }
    return fungibleBalances.find((f) => f.coin.equals(coin));
  }, [coin, fungibleBalances]);

  const parsedAmount = useMemo(() => {
    return tryParseAmount(typedValue, coin);
  }, [typedValue, coin]);

  let inputError: string | undefined;
  if (!coin) {
    inputError = inputError ?? "Select a token";
  }
  if (!parsedAmount) {
    inputError = inputError ?? "Enter an amount";
  }
  if (address && parsedAmount && balance?.lessThan(parsedAmount)) {
    inputError = inputError ?? `Insufficient ${coin?.name} balance`;
  }
  if (coin?.equals(SUI) && parsedAmount?.lessThan(MINIMUM_DEPOSIT)) {
    inputError = inputError ?? "Minimum 4 SUI";
  }

  return {
    balance,
    parsedAmount,
    inputError,
  };
}
