import { ReactElement, ReactNode } from "react";
import { useWispSettings } from "contexts/WispSettingsContext";
import { NetworkEnv } from "utils/constant";

import Header from "./Header";

const getNetworkText = (nw: NetworkEnv): string => {
  switch (nw) {
    case NetworkEnv.DEVNET:
      return "Devnet";
    case NetworkEnv.TESTNET:
      return "Testnet";
    case NetworkEnv.MAINNET:
      return "Mainnet";
  }
};

function Layout({ children }: { children: ReactNode }): ReactElement {
  const { settings } = useWispSettings();
  return (
    <div className="flex flex-col min-h-screen bg-dark-600 overflow-x-hidden">
      <Header />
      <div className="z-0 flex-1 bg-mainBg pt-20 flex flex-col px-4 relative">
        <div className="absolute left-0 bottom-[5rem] pointer-events-none">
          <img alt="" className="w-72 h-72" src="/images/bg-wisp-right-2.png" />
        </div>
        <div className="absolute bottom-1/2 right-[2rem] pointer-events-none">
          <img alt="" className="w-72 h-72" src="/images/bg-wisp-left-2.png" />
        </div>
        <div className="mt-2 sm:mt-10 flex-1 flex flex-col">{children}</div>
        <div className="fixed right-4 bottom-4 z-50">
          <div className="flex items-center space-x-2">
            <span className="w-1.5 h-1.5 bg-orange-500 rounded-full"></span>
            <span className="font-medium text-xs text-orange-500">{getNetworkText(settings.networkEnv)}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Layout;
