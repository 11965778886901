import { AxiosResponse } from "axios";
import { PREDICTION_HISTORY_FILTER } from "modules/prediction/constant";
import { NetworkEnv } from "utils/constant";

import { LeaderboardRankBy } from "./../modules/prediction/constant";
import http from "./http";

type OhlcParams = {
  timeStart: number;
  timeEnd: number;
  interval: string;
};

function getCurrentRounds(networkEnv: NetworkEnv, address?: string): Promise<AxiosResponse> {
  return http[networkEnv].get(
    "/rounds",
    address
      ? {
          params: {
            address,
          },
        }
      : undefined,
  );
}

function getBetHistory(
  networkEnv: NetworkEnv,
  address: string,
  collection: PREDICTION_HISTORY_FILTER,
): Promise<AxiosResponse> {
  return http[networkEnv].get("/prediction/history", {
    params: {
      address,
      collectionStatus: collection,
    },
  });
}

function getAllUnclaimedEpochs(networkEnv: NetworkEnv, address: string): Promise<AxiosResponse> {
  return http[networkEnv].get("/prediction/claim", {
    params: {
      address,
    },
  });
}

function getBtcOhlc(networkEnv: NetworkEnv, { timeEnd, timeStart, interval }: OhlcParams): Promise<AxiosResponse> {
  return http[networkEnv].get("/prediction/ohlc", {
    params: {
      timeStart,
      timeEnd,
      interval,
    },
  });
}

function getLeaderboard(networkEnv: NetworkEnv, rankBy: LeaderboardRankBy): Promise<AxiosResponse> {
  return http[networkEnv].get("prediction/leader-board", {
    params: {
      rankBy,
      pageSize: 50,
    },
  });
}

const PredictionAPI = {
  getCurrentRounds,
  getBetHistory,
  getAllUnclaimedEpochs,
  getBtcOhlc,
  getLeaderboard,
};

export default PredictionAPI;
