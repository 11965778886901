import PredictionAPI from "api/prediction";
import { LeaderboardRankBy } from "modules/prediction/constant";
import { Leaderboard } from "modules/prediction/types";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { Amount } from "sdk/entities/amount";
import { getIntPart } from "utils";
import { SUI } from "utils/coins";
import { NetworkEnv } from "utils/constant";

export type LeaderboardResponse = {
  position: number;
  sender: string;
  win: string;
  total: string;
  netWinnings: string;
  allAmount: string;
  rate: number;
};

export function parseLeaderboard(item: LeaderboardResponse): Leaderboard {
  return {
    position: item.position,
    sender: item.sender,
    win: Number(item.win),
    total: Number(item.total),
    netWinnings: Amount.fromRawAmount(SUI, getIntPart(item.netWinnings)),
    allAmount: Amount.fromRawAmount(SUI, getIntPart(item.allAmount)),
    rate: item.rate * 100,
  };
}

export function useLeaderboard(networkEnv: NetworkEnv, rankBy: LeaderboardRankBy): Leaderboard[] {
  const { data } = useQuery<Leaderboard[]>(
    ["get-leaderboard", networkEnv, rankBy],
    async (): Promise<Leaderboard[]> => {
      try {
        const resp: { data: { data: LeaderboardResponse[] } } = await PredictionAPI.getLeaderboard(networkEnv, rankBy);
        return (resp?.data?.data ?? []).map((item) => {
          return parseLeaderboard(item);
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error(err);
        toast.error(err.message ?? "Fetching leaderboard error");
        return [];
      }
    },
  );

  return data ?? [];
}
