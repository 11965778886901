import React, { useState } from "react";
import clsx from "clsx";
import { NoteIcon } from "components/atoms/icons/NoteIcon";
import { Modal } from "components/atoms/Modal";
import { Tooltip, TooltipButton, TooltipContent } from "components/atoms/Tooltip";
import { useWispSettings } from "contexts/WispSettingsContext";
import { Percent } from "sdk/entities/percent";
import { isValidNumber } from "utils";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const SLIPPAGE_TOLERANCE = [0.1, 0.5, 1];
const REGEX = /^(\d*(\.\d{0,2})?|\.?\d{1,2})$/;

export function SettingsModal({ isOpen, onClose }: Props): React.ReactElement<Props> {
  const [slippageTolerance, setSlippageTolerance] = useState<string | null>(null);
  const { settings, updateSettings } = useWispSettings();

  function handleInputChange(value: string): void {
    if (!REGEX.test(value)) {
      return;
    }
    setSlippageTolerance(value);
    const parsed = Math.floor(Number.parseFloat(value) * 100);
    if (!isValidNumber(parsed) || parsed < 0) {
      return;
    }
    if (parsed >= 5000) {
      // Maximum of slippage: 49.99%
      setSlippageTolerance("49.99");
      updateSettings({ slippageTolerance: new Percent(4999, 10_000) });
      return;
    }
    updateSettings({ slippageTolerance: new Percent(parsed, 10_000) });
  }

  function handleButtonClick(value: number): void {
    setSlippageTolerance(value.toString());
    updateSettings({ slippageTolerance: new Percent(value * 100, 10_000) });
  }

  const slippageToleranceNumber: number =
    (Number(settings.slippageTolerance.numerator) / Number(settings.slippageTolerance.denominator)) * 100;

  return (
    <Modal isOpen={isOpen} title="Settings" onClose={onClose}>
      <div className="space-y-8">
        <div>
          {/* <div className="text-center text-sm text-red-500 mb-4">
            In Devnet, we use a large number of slippage tolerance for prevent reverting your transaction.
          </div> */}
          <div className="flex space-x-2 items-center mb-2">
            <span className="text-xs text-pNeutral-800 font-semibold">Slippage tolerance</span>
            <Tooltip>
              <TooltipButton className="w-4 h-4">
                <NoteIcon className="w-4 h-4" />
              </TooltipButton>
              <TooltipContent>
                <div>Your transaction will revert if the price changes unfavorably by more than this percentage.</div>
              </TooltipContent>
            </Tooltip>
          </div>
          <div className="flex justify-between bg-white bg-opacity-10 py-2 px-4 rounded-lg space-x-4">
            <div className="flex-1 flex justify-between items-center font-medium">
              <input
                className="bg-transparent flex-1 max-w-[6rem]"
                type="text"
                value={slippageTolerance ?? ""}
                onChange={(e): void => handleInputChange(e.target.value)}
              />
              <span className="">%</span>
            </div>
            <div className="shrink-0 flex items-center justify-end space-x-2">
              {SLIPPAGE_TOLERANCE.map((s) => (
                <SlippageButton
                  active={slippageToleranceNumber === s}
                  key={s}
                  text={`${s}%`}
                  onClick={(): void => handleButtonClick(s)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

function SlippageButton({
  text,
  active,
  onClick,
}: {
  text: string;
  active: boolean;
  onClick: () => void;
}): React.ReactElement {
  return (
    <button
      className={clsx(
        "shrink-0 font-medium h-8 w-12 flex items-center justify-center rounded-lg text-sm",
        active ? "text-dark-600 bg-pGreen-500" : "text-white border border-white border-opacity-10",
      )}
      onClick={(): void => {
        !active && onClick();
      }}
    >
      <span>{text}</span>
    </button>
  );
}
