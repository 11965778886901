import { AxiosResponse } from "axios";
import { NetworkEnv } from "utils/constant";

import http from "./http";

function getAllFarms(networkEnv: NetworkEnv): Promise<AxiosResponse> {
  return http[networkEnv].get("/farms");
}

const EarnAPI = {
  getAllFarms,
};

export default EarnAPI;
