import PredictionAPI from "api/prediction";
import { PRICE_STATUS, ROUND_STATUS, TIME_PER_PHASE, USER_POSITION } from "modules/prediction/constant";
import { Round } from "modules/prediction/types";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { Amount } from "sdk/entities/amount";
import { Percent } from "sdk/entities/percent";
import { getIntPart } from "utils";
import { SUI } from "utils/coins";
import { NetworkEnv } from "utils/constant";

export type CurrentRoundsResponse = {
  id: number;
  startTimeStamp: number;
  status: number;
  lockTimeStamp: number | null;
  closeTimeStamp: number;
  lockPrice: string | null;
  closePrice: string | null;
  totalAmount: string | null;
  downAmount: string | null;
  upAmount: string | null;
  rewardAmount: string | null;
  currentPrice: string | null;
  epoch: number;
  // =====
  amount?: string | null;
  position?: string | null;
};

const LATER_ROUND: Round = {
  id: -1,
  epoch: -1,
  status: ROUND_STATUS.LATER,
  startTime: new Date(),
  lockTime: new Date(),
  closeTime: new Date(),
  lockPrice: null,
  currentPrice: null,
  closePrice: null,
  downAmount: null,
  upAmount: null,
  rewardAmount: null,
  // =========
  priceStatus: PRICE_STATUS.UN_CHANGE,
  deltaChange: 0,
  upPayout: null,
  downPayout: null,
  // =========
  amount: null,
  position: null,
};

export function parseRound(item: CurrentRoundsResponse): Round {
  const closePrice = item.closePrice ? Number(item.closePrice) : null;
  const lockPrice = item.lockPrice ? Number(item.lockPrice) : null;
  const currentPrice = item.currentPrice ? Number(item.currentPrice) : null;
  const deltaChange =
    lockPrice && ((closePrice && closePrice > 0) || currentPrice)
      ? ((closePrice && closePrice > 0 ? closePrice : undefined) ?? currentPrice ?? 0) - lockPrice
      : 0;
  const rewardAmount = item.rewardAmount ? Amount.fromRawAmount(SUI, item.rewardAmount) : null;
  const downAmount = item.downAmount ? Amount.fromRawAmount(SUI, item.downAmount) : null;
  const upAmount = item.upAmount ? Amount.fromRawAmount(SUI, item.upAmount) : null;
  return {
    id: item.id,
    epoch: item.epoch,
    status: item.status as ROUND_STATUS,
    startTime: new Date(item.startTimeStamp),
    lockTime: item.lockTimeStamp ? new Date(item.lockTimeStamp) : new Date(item.startTimeStamp + TIME_PER_PHASE),
    closeTime: item.closeTimeStamp ? new Date(item.closeTimeStamp) : new Date(item.startTimeStamp + 2 * TIME_PER_PHASE),
    lockPrice,
    closePrice,
    currentPrice,
    upAmount,
    downAmount,
    rewardAmount,
    // =========
    priceStatus: deltaChange === 0 ? PRICE_STATUS.UN_CHANGE : deltaChange > 0 ? PRICE_STATUS.BULL : PRICE_STATUS.BEAR,
    deltaChange: Math.abs(deltaChange),
    upPayout:
      rewardAmount && upAmount && upAmount.greaterThan(0)
        ? new Percent(rewardAmount.quotient, upAmount.quotient).divide(100)
        : null,
    downPayout:
      rewardAmount && downAmount && downAmount.greaterThan(0)
        ? new Percent(rewardAmount.quotient, downAmount.quotient).divide(100)
        : null,
    // ==========
    amount: item.amount ? Amount.fromRawAmount(SUI, getIntPart(item.amount)) : null,
    position: (item.position as USER_POSITION) ?? null,
  };
}

export function useCurrentRounds(networkEnv: NetworkEnv, address?: string): Round[] {
  const { data } = useQuery<Round[]>(
    ["get-current-rounds", address, networkEnv],
    async (): Promise<Round[]> => {
      try {
        const resp: { data: CurrentRoundsResponse[] } = await PredictionAPI.getCurrentRounds(networkEnv, address);
        const rounds: Round[] = (resp?.data ?? []).map((item) => {
          return parseRound(item);
        });
        const nextRound = rounds[rounds.length - 1];
        if (nextRound) {
          rounds.push({
            ...LATER_ROUND,
            id: nextRound.id + 1,
            epoch: nextRound.epoch + 1,
            startTime: nextRound.lockTime,
            lockTime: nextRound.closeTime,
            closeTime: new Date(nextRound.closeTime.getTime() + TIME_PER_PHASE),
          });
        }
        return rounds;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error(err);
        toast.error(err.message ?? "Fetching remote config error");
        return [];
      }
    },
    {
      refetchInterval: 10_000,
    },
  );

  return data ?? [];
}
