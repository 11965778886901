import { Coin } from "sdk/entities/coin";
import { Pool } from "sdk/entities/pool";

export enum ActionType {
  TYPE_INPUT = "TYPE_INPUT",
  SET_ASSET = "SET_ASSET",
  SET_POOL = "SET_POOL",
  RESET_FORM = "RESET_FORM",
  REVERT_STATE = "REVERT_STATE",
}

type TypeInputAction = {
  type: ActionType.TYPE_INPUT;
  typedValue: string;
};

type SetAssetAction = {
  type: ActionType.SET_ASSET;
  coin?: Coin;
};

type SetLPPoolAction = {
  type: ActionType.SET_POOL;
  pool?: Pool;
};

type ResetFormAction = {
  type: ActionType.RESET_FORM;
};

type RevertStateAction = { type: ActionType.REVERT_STATE };

export type Action = TypeInputAction | SetAssetAction | SetLPPoolAction | ResetFormAction | RevertStateAction;
