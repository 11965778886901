import { ReactElement } from "react";
import clsx from "clsx";
import { SearchIcon } from "components/atoms/icons/SearchIcon";
import { Modal } from "components/atoms/Modal";
import { MultipleAssetLogo } from "components/atoms/MultipleAssetLogo";
import { Pool } from "sdk/entities/pool";

type Props = {
  isOpen: boolean;
  pools: Pool[];
  selectedPool: Pool | undefined;
  onClose: () => void;
  onSelect: (_: Pool) => void;
  searchTerm: string;
  onSearchChange: (val: string) => void;
};

function PoolItem({ pool, selected, onSelect }: { pool: Pool; selected: boolean; onSelect: () => void }): ReactElement {
  return (
    <div
      className={clsx(
        "p-2 flex items-center justify-between space-x-6 rounded-lg",
        selected ? "bg-pGreen-500 bg-opacity-10" : "hover:bg-pGreen-500 hover:bg-opacity-10",
        selected ? "" : "cursor-pointer",
      )}
      onClick={!selected ? onSelect : undefined}
    >
      <div className="flex items-center space-x-4">
        <MultipleAssetLogo assets={[pool.coin0, pool.coin1]} />
        <div className="text-pNeutral-800 font-medium">{pool.toString()}</div>
      </div>
      <div />
    </div>
  );
}

export function SelectPoolModal({
  pools,
  selectedPool,
  isOpen,
  onSelect,
  onClose,
  searchTerm,
  onSearchChange,
}: Props): ReactElement {
  return (
    <Modal isOpen={isOpen} title="Select pool" onClose={onClose}>
      <div className="space-y-6">
        <div className="py-2 px-4 border border-white border-opacity-10 rounded-lg flex items-center space-x-4 text-sm font-medium">
          <SearchIcon className="w-5 h-5 shrink-0" />
          <input
            className="flex-1 bg-transparent"
            placeholder="Search pool..."
            type="text"
            value={searchTerm}
            onChange={(e): void => onSearchChange(e.target.value)}
          />
        </div>
        <div className="space-y-0.5">
          {pools.map((p) => (
            <PoolItem
              key={p.type}
              pool={p}
              selected={!!selectedPool && selectedPool.equals(p)}
              onSelect={(): void => {
                onSelect(p);
                onClose();
              }}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
}
