import { ReactElement, useMemo, useState } from "react";
import { TransactionBlock } from "@mysten/sui.js";
import { useWallet } from "@suiet/wallet-kit";
import { Button } from "components/atoms/Button";
import { AssetInput } from "components/molecules/AssetInput";
import { useWalletHelper } from "contexts/WalletHelperContext";
import { useWispSettings } from "contexts/WispSettingsContext";
import { Amount } from "sdk/entities/amount";
import { toCommaSeparated } from "sdk/utils/formatNumber";
import { isTooManyDecimals, tryParseAmount } from "sdk/utils/tryParseAmount";
import invariant from "tiny-invariant";
import { SUI, VeWISP, WISP } from "utils/coins";
import { WISP_CONFIG } from "utils/constant";
import { extractGasPayment, getSuiObjectRefs } from "utils/getCoinObjectIds";

function WispToVeWisp(): ReactElement {
  const [typedWisp, setTypedWisp] = useState<string>("");
  const [isBuildingWispTx, setIsBuildingWispTx] = useState<boolean>(false);

  const { address } = useWallet();
  const { fungibleBalances, setOpenSelectWallet, signAndExecuteTransaction } = useWalletHelper();
  const { settings } = useWispSettings();

  const [wispBalance] = useMemo(() => {
    let wisp = fungibleBalances?.find((f) => f.coin.equals(WISP));
    let veWisp = fungibleBalances?.find((f) => f.coin.equals(VeWISP));
    if (wisp) {
      wisp = Amount.fromRawAmount(WISP, wisp.quotient);
    }
    if (veWisp) {
      veWisp = Amount.fromRawAmount(VeWISP, veWisp.quotient);
    }
    return [wisp, veWisp];
  }, [fungibleBalances]);

  const handleChangeWispInput = (value: string): void => {
    if (isTooManyDecimals(value, WISP)) {
      return;
    }
    setTypedWisp(value);
  };

  const handleMaxWispInput = (): void => {
    if (!wispBalance) {
      return;
    }
    setTypedWisp(wispBalance.toExact({ groupSeparator: "" }));
  };

  const amountWisp = useMemo(() => {
    if (!typedWisp) {
      return undefined;
    }
    return tryParseAmount(typedWisp, WISP);
  }, [typedWisp]);

  const errorMsgWisp: string | null = useMemo(() => {
    if (!wispBalance || wispBalance.equalTo(0)) {
      return "Empty balance";
    }
    if (!amountWisp) {
      return "Enter an amount";
    }
    if (wispBalance?.lessThan(amountWisp)) {
      return "Insufficient balance";
    }
    return null;
  }, [amountWisp, wispBalance]);

  const handleConvertWispToVeWisp = async (): Promise<void> => {
    invariant(amountWisp, "Amount not found");
    invariant(address, "Address not found");
    setIsBuildingWispTx(true);
    try {
      const objectRefs = await getSuiObjectRefs(settings.customRPC, address, [SUI, WISP]);
      const suiObjectRefs = objectRefs[SUI.type] ?? [];
      const tokenObjectRefs = objectRefs[WISP.type] ?? [];
      const [suiGas] = extractGasPayment(suiObjectRefs, 100_000_000n);
      const tx = new TransactionBlock();
      tx.setGasPayment(suiGas);
      tx.setGasBudget(100_000_000);
      tx.moveCall({
        target: `${WISP_CONFIG.vesting.package}::${WISP_CONFIG.vesting.module}::${WISP_CONFIG.vesting.functions.wispToVewisp}`,
        arguments: [
          tx.object(WISP_CONFIG.vesting.registry),
          tx.makeMoveVec({
            objects: tokenObjectRefs.map((i) => {
              return tx.object(i.objectId);
            }),
          }),
          tx.pure(amountWisp.quotient.toString()),
          tx.object("0x6"),
        ],
      });
      await signAndExecuteTransaction(tx);
      handleChangeWispInput("");
    } catch (e) {
      console.error(e);
    } finally {
      setIsBuildingWispTx(false);
    }
  };

  return (
    <div>
      <div className="max-w-md w-full space-y-5 mx-auto">
        <div>Convert your Wisp to veWisp</div>
        <AssetInput
          asset={WISP}
          balance={wispBalance}
          balances={undefined}
          fixedCoin={true}
          label="Amount"
          maxAmount={true}
          otherAsset={undefined}
          value={toCommaSeparated(typedWisp)}
          onInputChange={handleChangeWispInput}
          onMaxAmount={handleMaxWispInput}
          onSelect={(): void => {}}
        />
        {!address ? (
          <Button
            className="px-6 py-3 w-full text-xl font-Poppins whitespace-pre-wrap"
            onClick={(): void => setOpenSelectWallet(true)}
          >
            Connect Wallet
          </Button>
        ) : (
          <Button
            className="px-6 py-3 w-full text-xl font-Poppins whitespace-pre-wrap"
            disabled={Boolean(errorMsgWisp || isBuildingWispTx)}
            isLoading={isBuildingWispTx}
            onClick={handleConvertWispToVeWisp}
          >
            {errorMsgWisp ?? (isBuildingWispTx ? "Processing" : "Convert")}
          </Button>
        )}
      </div>
    </div>
  );
}

export default WispToVeWisp;
