/* eslint-disable @typescript-eslint/no-explicit-any */
import EarnAPI from "api/earn";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { Amount } from "sdk/entities/amount";
import { Coin } from "sdk/entities/coin";
import { getIntPart } from "utils";
import { SUI } from "utils/coins";
import { NetworkEnv } from "utils/constant";

export type FarmInfo = {
  id: number;
  stakingCoin: Coin;
  isLpCoin: boolean;
  farmAddress: string;
  allocPoint: number;
  stakeTokenType: string;
  poolAllocPoint: number;
  totalStakePoint: number;
  totalBoostBalance: bigint;
  accWispPerShare: bigint;
  lastClaimTime: Date;
  apy: number;
  tvlBySui: Amount;
};

type Response = {
  data: FarmInfo[];
  isFetched: boolean;
  isFetching: boolean;
  status: any;
};

export function useAllFarms(networkEnv: NetworkEnv): Response {
  const { data, isFetched, isFetching, status } = useQuery<FarmInfo[]>(
    ["get-all-farms", networkEnv],
    async (): Promise<FarmInfo[]> => {
      try {
        const resp = await EarnAPI.getAllFarms(networkEnv);
        const farms: FarmInfo[] = (resp.data?.data ?? []).map((item: any) => {
          let type = item.stakeTokenType.fields.name.replace(",", ", ");
          if (!type.startsWith("0x")) {
            type = `0x${type}`;
          }
          const isLpCoin = !!type.match(/.*<(.*)>/)?.[1];
          const stakingCoin = isLpCoin ? Coin.createLpCoin(type) : Coin.fromType(type);
          if (isLpCoin) {
            const coin1 = item.coinA;
            const coin2 = item.coinB;
            const coin1IsCoinA =
              stakingCoin.coinA?.packageObject === coin1.packageAddr &&
              stakingCoin.coinA?.module === coin1.module &&
              stakingCoin.coinA?.name === coin1.type;
            if (coin1IsCoinA) {
              stakingCoin.coinA?.setDecimal(coin1.decimal);
              stakingCoin.coinA?.setMetadata({
                imageUrl: coin1.iconUrl,
              });
              stakingCoin.coinB?.setDecimal(coin2.decimal);
              stakingCoin.coinB?.setMetadata({
                imageUrl: coin2.iconUrl,
              });
            } else {
              stakingCoin.coinB?.setDecimal(coin1.decimal);
              stakingCoin.coinB?.setMetadata({
                imageUrl: coin1.iconUrl,
              });
              stakingCoin.coinA?.setDecimal(coin2.decimal);
              stakingCoin.coinA?.setMetadata({
                imageUrl: coin2.iconUrl,
              });
            }
          } else {
            stakingCoin.setDecimal(item.coinA.decimal);
            stakingCoin.setMetadata({
              imageUrl: item.coinA.iconUrl,
            });
          }
          return {
            id: item.id,
            stakingCoin,
            isLpCoin,
            farmAddress: item.farmAddress,
            allocPoint: item.allocPoint,
            stakeTokenType: item.stakeTokenType.fields.name,
            poolAllocPoint: item.poolAllocPoint,
            totalStakePoint: item.totalStakePoint,
            totalBoostBalance: BigInt(item.totalBoostBalance),
            accWispPerShare: BigInt(getIntPart(item.accWispPerShare.toString())),
            lastClaimTime: new Date(item.lastClaimTimestamp),
            apy: item.apy,
            tvlBySui: Amount.fromRawAmount(SUI, item.pool?.tvlName === "SUI" ? getIntPart(item.pool.tvl) : 0),
          } as FarmInfo;
        });
        return farms;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error(err);
        toast.error(err.message ?? "Fetching all Farms error");
        return [];
      }
    },
  );

  return {
    data: data ?? [],
    isFetched,
    isFetching,
    status,
  };
}
