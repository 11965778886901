import PredictionAPI from "api/prediction";
import { BET_STATUS, HISTORY_CLAIM, PREDICTION_HISTORY_FILTER, USER_POSITION } from "modules/prediction/constant";
import { Round } from "modules/prediction/types";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { Amount } from "sdk/entities/amount";
import { getIntPart } from "utils";
import { SUI } from "utils/coins";
import { NetworkEnv } from "utils/constant";

import { CurrentRoundsResponse, parseRound } from "./useCurrentRounds";

type BetRoundResponse = {
  id: number;
  position: string;
  amount: string | null;
  winAmount: string | null;
  claim: number;
  sender: string;
  roundEpoch: string;
  status: number | null;
  roundId: number;
  round: CurrentRoundsResponse;
};

export type BetRound = {
  id: number;
  position: USER_POSITION;
  amount: Amount | null;
  winAmount: Amount | null;
  claim: HISTORY_CLAIM;
  sender: string;
  epoch: string;
  status: BET_STATUS | null;
  roundId: number;
  round: Round;
};

export function useBetHistory(
  networkEnv: NetworkEnv,
  address?: string,
  filter?: PREDICTION_HISTORY_FILTER,
): BetRound[] {
  const { data } = useQuery<BetRound[]>(
    ["get-bet-history", address, filter, networkEnv],
    async (): Promise<BetRound[]> => {
      try {
        const resp: { data: BetRoundResponse[] } = await PredictionAPI.getBetHistory(
          networkEnv,
          address ?? "",
          filter ?? -1,
        );
        const rounds: BetRound[] = (resp.data ?? []).map((item) => {
          const round = parseRound(item.round);
          return {
            id: item.id,
            position: item.position as USER_POSITION,
            amount: item.amount ? Amount.fromRawAmount(SUI, getIntPart(item.amount)) : null,
            winAmount: item.winAmount ? Amount.fromRawAmount(SUI, getIntPart(item.winAmount)) : null,
            claim: item.claim as HISTORY_CLAIM,
            sender: item.sender,
            epoch: item.roundEpoch,
            status: item.status as BET_STATUS | null,
            roundId: item.roundId,
            round,
          };
        });
        return rounds;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error(err);
        toast.error(err.message ?? "Fetching bet history error");
        return [];
      }
    },
    {
      enabled: !!address && typeof filter === "number",
      refetchInterval: 10_000,
    },
  );

  return data ?? [];
}
