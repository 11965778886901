import { ReactElement, useState } from "react";
import clsx from "clsx";
import { TriangleDownIcon } from "components/atoms/icons/TriangleDownIcon";
import { MultipleAssetLogo } from "components/atoms/MultipleAssetLogo";
import { Pool } from "sdk/entities/pool";

import { SelectPoolModal } from "./SelectPoolModal";

type Props = {
  label: string;
  value: string | undefined;
  selectedPool: Pool | undefined;
  pools: Pool[];
  onSelect: (_: Pool) => void;
  searchTerm: string;
  onSearchChange: (val: string) => void;
};

export function PoolInput({
  label,
  value,
  selectedPool,
  pools,
  onSelect,
  searchTerm,
  onSearchChange,
}: Props): ReactElement {
  const [isOpenSelectPoolModal, setIsOpenSelectPoolModal] = useState<boolean>(false);

  return (
    <div>
      <div className="mb-2 text-xs text-pNeutral-800 font-semibold uppercase">{label}</div>
      <div className="flex justify-between bg-white bg-opacity-10 py-2 pl-4 pr-2 rounded-lg space-x-4">
        <div className="flex-1 font-medium flex items-center text-base min-w-0">
          <input
            className="bg-transparent flex-1 pr-2 font-DmSans min-w-0"
            placeholder="0"
            type="text"
            value={value ?? ""}
            readOnly
          />
        </div>
        <div
          className={clsx(
            "shrink-0 flex items-center space-x-2 relative rounded-md pl-2",
            "before:w-px before:h-full before:bg-white before:bg-opacity-30 before:-left-2 before:absolute",
            "hover:bg-white hover:bg-opacity-5 cursor-pointer",
          )}
          onClick={(): void => setIsOpenSelectPoolModal(true)}
        >
          <MultipleAssetLogo
            assets={selectedPool ? [selectedPool.coin0, selectedPool.coin1] : [undefined]}
            itemCls="!w-6 !h-6"
          />
          <span className="text-sm font-medium">{selectedPool ? selectedPool.toString() : "Select pool"}</span>
          <TriangleDownIcon className="w-8 h-8 shrink-0" />
        </div>
      </div>
      <SelectPoolModal
        isOpen={isOpenSelectPoolModal}
        pools={pools}
        searchTerm={searchTerm}
        selectedPool={selectedPool}
        onClose={(): void => setIsOpenSelectPoolModal(false)}
        onSearchChange={onSearchChange}
        onSelect={onSelect}
      />
    </div>
  );
}
