import React from "react";
import { Switch as SwitchHeadless } from "@headlessui/react";
import clsx from "clsx";

type Props = {
  enabled: boolean;
  onChange: (value: boolean) => void;
  className?: string;
  classes?: {
    track?: string;
    thumb?: string;
  };
};

export function Switch({ enabled, onChange, className, classes }: Props): React.ReactElement<Props> {
  return (
    <SwitchHeadless
      checked={enabled}
      className={clsx(
        "relative inline-flex flex-shrink-0 h-8 w-14 rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none",
        enabled ? "bg-pGreen-500" : "bg-white bg-opacity-10",
        className,
        classes?.track,
      )}
      onChange={onChange}
    >
      <span
        aria-hidden="true"
        className={clsx(
          "bg-white shadow-lg rounded-full pointer-events-none inline-block h-7 w-7 transform transition ease-in-out duration-200 mt-0.5",
          enabled ? "translate-x-[26px]" : "translate-x-0.5",
          classes?.thumb,
        )}
      />
    </SwitchHeadless>
  );
}
