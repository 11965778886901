import { ReactElement } from "react";
import clsx from "clsx";

type Props = {
  progress: number;
  cls?: {
    track?: string;
    thumb?: string;
  };
};

export function Progress({ cls, progress }: Props): ReactElement {
  return (
    <div className={clsx("h-1 w-full", cls?.track)}>
      <div
        className={clsx("h-1 bg-predictionProgress", cls?.thumb)}
        style={{
          width: `${progress}%`,
        }}
      ></div>
    </div>
  );
}
