import { Coin } from "sdk/entities/coin";
import { Pool } from "sdk/entities/pool";

import { Action, ActionType } from "./actions";

export function typeInput(dispatch: React.Dispatch<Action>, typedValue: string): void {
  dispatch({ type: ActionType.TYPE_INPUT, typedValue });
}

export function setAsset(dispatch: React.Dispatch<Action>, coin?: Coin): void {
  dispatch({ type: ActionType.SET_ASSET, coin });
}

export function setPool(dispatch: React.Dispatch<Action>, pool?: Pool): void {
  dispatch({ type: ActionType.SET_POOL, pool: pool });
}

export function resetForm(dispatch: React.Dispatch<Action>): void {
  dispatch({ type: ActionType.RESET_FORM });
}

export function revertRate(dispatch: React.Dispatch<Action>): void {
  dispatch({ type: ActionType.REVERT_STATE });
}
