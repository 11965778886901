import { useEffect, useMemo, useState } from "react";
import { Connection, JsonRpcProvider, SuiObjectResponse } from "@mysten/sui.js";
import { useWalletHelper } from "contexts/WalletHelperContext";
import { TradeType } from "sdk/constants";
import { Amount } from "sdk/entities/amount";
import { Coin } from "sdk/entities/coin";
import { InsufficientInputAmountError } from "sdk/entities/error";
import { Percent } from "sdk/entities/percent";
import { Pool } from "sdk/entities/pool";
import { Price } from "sdk/entities/price";
import { Route } from "sdk/entities/route";
import { Trade } from "sdk/entities/trade";
import { tryParseAmount } from "sdk/utils/tryParseAmount";
import { WISP_CONFIG } from "utils/constant";

import { State } from "../reducers/zapReducers";

export function useZapIn(
  state: State,
  rpcEndpoint: string,
): {
  amount: Amount | undefined;
  balance: Amount | undefined;
  trade?: Trade;
  price?: Price;
  liquidityMinted?: Amount;
  poolTokenPercentage?: Percent;
  error?: string;
  poolOnChain: Pool | null;
} {
  const { fungibleBalances } = useWalletHelper();
  const { typedValue, asset, pool } = state;
  const [poolOnChain, setPoolOnChain] = useState<Pool | null>(null);

  useEffect(() => {
    void (async (): Promise<void> => {
      if (!pool) {
        setPoolOnChain(null);
        return;
      }
      const provider = new JsonRpcProvider(
        new Connection({
          fullnode: rpcEndpoint,
        }),
      );
      const resp: SuiObjectResponse = await provider.getObject({
        id: pool.packageObject,
        options: { showType: true, showContent: true },
      });
      if (!resp.data?.objectId) {
        return;
      }
      if (resp.data.content?.dataType !== "moveObject") {
        return;
      }
      const fields = resp.data.content.fields;
      const firstAmt = fields?.first_token ?? 0;
      const secondAmt = fields?.second_token ?? 0;
      const lspType = resp.data.content?.type ?? "";
      const lspAmt = fields?.wisp_lp_supply?.fields?.value ?? 0;
      const lpCoin = Coin.createLpCoin(lspType, 0);
      const is1st0 = lpCoin.coinA?.equals(pool.coin0);
      setPoolOnChain(
        new Pool(
          pool.packageObject,
          WISP_CONFIG.dex.swapModulePool,
          "Pool",
          Amount.fromRawAmount(is1st0 ? pool.coin0 : pool.coin1, is1st0 ? firstAmt : secondAmt),
          Amount.fromRawAmount(is1st0 ? pool.coin1 : pool.coin0, is1st0 ? secondAmt : firstAmt),
          Amount.fromRawAmount(Coin.createLpCoin(lspType, 0), lspAmt),
        ),
      );
    })();
  }, [pool, rpcEndpoint]);

  // balances
  const assetBalance: Amount | undefined = useMemo(() => {
    if (!asset) {
      return undefined;
    }
    const bl = fungibleBalances?.find((f) => f.coin.equals(asset));
    return bl ?? Amount.fromRawAmount(asset, 0);
  }, [asset]);

  // amounts
  const assetAmount: Amount | undefined = useMemo(() => {
    return tryParseAmount(typedValue, asset);
  }, [typedValue, asset]);

  const route: Route | undefined = useMemo(() => {
    if (!asset || !poolOnChain) {
      return undefined;
    }
    return new Route([poolOnChain], asset, poolOnChain.otherOf(asset));
  }, [asset, poolOnChain]);

  const { trade, tradeError }: { trade?: Trade; tradeError?: Error } = useMemo(() => {
    if (!route || !poolOnChain || !assetAmount || !asset) {
      return {};
    }
    try {
      const trade = new Trade(
        route,
        poolOnChain.getZapInSwapAmount(asset, assetAmount.quotient),
        TradeType.EXACT_INPUT,
      );
      return { trade };
    } catch (error) {
      return { tradeError: error as Error };
    }
  }, [route, poolOnChain, asset, assetAmount]);

  const price = useMemo(() => {
    if (!(poolOnChain && asset)) {
      return undefined;
    }
    return poolOnChain.priceOf(asset);
  }, [asset, poolOnChain]);

  // liquidity minted
  const liquidityMinted = useMemo(() => {
    if (!(poolOnChain && asset && assetAmount)) {
      return undefined;
    }
    try {
      return poolOnChain.getZapInLiquidityMinted(asset, assetAmount.quotient);
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }, [poolOnChain, asset, assetAmount]);

  const poolTokenPercentage = useMemo(() => {
    if (liquidityMinted && poolOnChain) {
      return new Percent(liquidityMinted.quotient, poolOnChain.liquidity.add(liquidityMinted).quotient);
    } else {
      return undefined;
    }
  }, [liquidityMinted, poolOnChain]);

  let error: string | undefined;

  if (!assetAmount) {
    error = error ?? "Enter an amount";
  }

  if (!pool) {
    error = error ?? "Select a pool";
  }

  if (assetAmount && assetBalance?.lessThan(assetAmount)) {
    error = error ?? `Insufficient ${asset?.name} balance`;
  }

  if (tradeError) {
    if (tradeError instanceof InsufficientInputAmountError) {
      error = error ?? `${asset?.name} amount is too small`;
    } else {
      error = error ?? tradeError.toString();
    }
  }

  return {
    balance: assetBalance,
    amount: assetAmount,
    trade,
    price,
    liquidityMinted,
    poolTokenPercentage,
    error,
    poolOnChain,
  };
}
