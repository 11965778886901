import { Coin } from "sdk/entities/coin";

import { Action, ActionType } from "./actions";
import { Field } from "./reducers";

export function typeInput(dispatch: React.Dispatch<Action>, field: Field, typedValue: string): void {
  dispatch({ type: ActionType.TYPE_INPUT, field, typedValue });
}

export function setAssets(dispatch: React.Dispatch<Action>, assetA: Coin, assetB: Coin): void {
  dispatch({ type: ActionType.SET_ASSET, assetA, assetB });
}

export function clearForm(dispatch: React.Dispatch<Action>): void {
  dispatch({ type: ActionType.CLEAR_FORM });
}
