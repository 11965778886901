import { Coin } from "sdk/entities/coin";

import { Action, ActionType } from "./actions";

export type State = {
  readonly typedValue: string;
  readonly coin: Coin | undefined;
};

export const initialState: State = {
  typedValue: "",
  coin: undefined,
};

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.SELECT_COIN: {
      return {
        ...state,
        coin: action.coin,
      };
    }
    case ActionType.TYPE_INPUT: {
      return {
        ...state,
        typedValue: action.typedValue,
      };
    }
    case ActionType.RESET: {
      return {
        ...initialState,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
