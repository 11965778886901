import { useMemo } from "react";
import { useWalletHelper } from "contexts/WalletHelperContext";
import { usePool } from "hooks/usePools";
import { Amount } from "sdk/entities/amount";
import { Percent } from "sdk/entities/percent";
import { Pool } from "sdk/entities/pool";
import { tryParseAmount } from "sdk/utils/tryParseAmount";
import { NetworkEnv } from "utils/constant";

import { Field, State } from "../reducers/reducers";

export function useRemoveLiquidity(
  state: State,
  rpcEndpoint: string,
  networkEnv: NetworkEnv,
): {
  pool?: Pool | null;
  amountAssetA?: Amount;
  amountAssetB?: Amount;
  liquidity?: Amount;
  removeLPPercentage: Percent;
  poolTokenPercentage?: Percent;
  error?: string | null;
} {
  const { independentField, typedValue, assetA, assetB } = state;
  const { pool } = usePool({ coinA: assetA ?? null, coinB: assetB ?? null, rpcEndpoint, networkEnv });
  const { lpBalances } = useWalletHelper();

  const userLiquidity: Amount | null = useMemo(() => {
    return (
      lpBalances?.find(
        (lp) =>
          (lp.coin.coinA?.equals(assetA) && lp.coin.coinB?.equals(assetB)) ||
          (lp.coin.coinA?.equals(assetB) && lp.coin.coinB?.equals(assetA)),
      ) ?? null
    );
  }, [lpBalances, assetA, assetB]);

  const coins = {
    [Field.ASSET_A]: assetA,
    [Field.ASSET_B]: assetB,
    [Field.LIQUIDITY]: pool?.liquidity.coin,
  };

  // liquidity values
  const liquidityValueA =
    pool &&
    userLiquidity &&
    assetA &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    pool.liquidity.quotient >= userLiquidity.quotient
      ? Amount.fromRawAmount(assetA, pool.getLiquidityValue(assetA, userLiquidity).quotient)
      : undefined;
  const liquidityValueB =
    pool &&
    userLiquidity &&
    assetB &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    pool.liquidity.quotient >= userLiquidity.quotient
      ? Amount.fromRawAmount(assetB, pool.getLiquidityValue(assetB, userLiquidity).quotient)
      : undefined;
  const liquidityValues: { [Field.ASSET_A]?: Amount; [Field.ASSET_B]?: Amount } = {
    [Field.ASSET_A]: liquidityValueA,
    [Field.ASSET_B]: liquidityValueB,
  };

  let removeLPPercentage: Percent = new Percent("0", "100");
  // user specified a %
  if (independentField === Field.LIQUIDITY_PERCENT) {
    removeLPPercentage = new Percent(typedValue, "100");
  }
  // user specified a specific amount of liquidity coins
  else if (independentField === Field.LIQUIDITY) {
    if (pool?.liquidity.coin) {
      const independentAmount = tryParseAmount(typedValue, pool.liquidity.coin);
      if (independentAmount && userLiquidity && !independentAmount.greaterThan(userLiquidity)) {
        removeLPPercentage = new Percent(independentAmount.quotient, userLiquidity.quotient);
      }
    }
  }
  // user specified a specific amount of coin a or b
  else {
    if (coins[independentField]) {
      const independentAmount = tryParseAmount(typedValue, coins[independentField]);
      const liquidityValue = liquidityValues[independentField];
      if (independentAmount && liquidityValue && !independentAmount.greaterThan(liquidityValue)) {
        removeLPPercentage = new Percent(independentAmount.quotient, liquidityValue.quotient);
      }
    }
  }

  const amountAssetA =
    assetA && removeLPPercentage && removeLPPercentage.greaterThan("0") && liquidityValueA
      ? Amount.fromRawAmount(assetA, removeLPPercentage.multiply(liquidityValueA.quotient).quotient)
      : undefined;

  const amountAssetB =
    assetB && removeLPPercentage && removeLPPercentage.greaterThan("0") && liquidityValueB
      ? Amount.fromRawAmount(assetB, removeLPPercentage.multiply(liquidityValueB.quotient).quotient)
      : undefined;

  const liquidity =
    userLiquidity && removeLPPercentage && removeLPPercentage.greaterThan("0")
      ? Amount.fromRawAmount(userLiquidity.coin, removeLPPercentage.multiply(userLiquidity.quotient).quotient)
      : undefined;

  const poolTokenPercentage = useMemo(() => {
    if (pool && liquidity) {
      return new Percent(liquidity.quotient, pool.liquidity.quotient);
    } else {
      return undefined;
    }
  }, [pool, liquidity]);

  let error: string | undefined | null = null;

  if (!liquidity || !amountAssetA || !amountAssetB) {
    error = error ?? "Enter an amount";
  }

  return {
    pool,
    amountAssetA,
    amountAssetB,
    liquidity,
    removeLPPercentage,
    poolTokenPercentage,
    error,
  };
}
