import { Amount } from "sdk/entities/amount";
import { Coin } from "sdk/entities/coin";
import { Pool } from "sdk/entities/pool";

import { SUI } from "./coins";
import { WISP_CONFIG } from "./constant";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parsePool(piece: any): Pool {
  const coinA = new Coin(
    piece?.coin?.package_addr,
    piece?.coin?.module,
    piece?.coin?.type,
    piece?.coin?.decimal ?? 0,
    piece?.coin?.treasury_addr,
    {
      imageUrl: piece?.coin?.icon_url,
      ticker: piece?.coin?.symbol,
      projectName: piece?.coin?.symbol,
      description: piece?.coin?.description,
    },
  );
  const coinB = new Coin(
    piece?.coin2?.package_addr,
    piece?.coin2?.module,
    piece?.coin2?.type,
    piece?.coin2?.decimal ?? 0,
    piece?.coin2?.treasury_addr,
    {
      imageUrl: piece?.coin2?.icon_url,
      ticker: piece?.coin2?.symbol,
      projectName: piece?.coin2?.symbol,
      description: piece?.coin2?.description,
    },
  );
  return new Pool(
    piece?.pool_addr ?? "",
    WISP_CONFIG.dex.swapModulePool,
    "Pool",
    Amount.fromRawAmount(coinA, piece.first_token_amount ?? 0),
    Amount.fromRawAmount(coinB, piece.sec_token_amount ?? 0),
    Amount.fromRawAmount(
      Coin.createLpCoin(
        `${WISP_CONFIG.dex.swapPackage}::${WISP_CONFIG.dex.swapModulePool}::LSP<${coinA.type}, ${coinB.type}>`,
        0,
      ),
      piece?.lp_supply ?? 0,
    ),
    Amount.fromRawAmount(SUI, piece?.volumeBySui ?? 0),
  );
}
