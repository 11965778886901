import { ReactElement, useMemo, useState } from "react";
import { Listbox } from "@headlessui/react";
import { useMediaQuery } from "@react-hookz/web";
import clsx from "clsx";
import { ArrowLeftIcon } from "components/atoms/icons/ArrowLeftIcon";
import { ChevronDownIcon } from "components/atoms/icons/ChevronDownIcon";
import { useWispSettings } from "contexts/WispSettingsContext";
import { useLeaderboard } from "hooks/useLeaderboard";
import { shortenAddress } from "utils";

import { LeaderboardRankBy } from "./constant";

const sortOptions = [
  {
    value: LeaderboardRankBy.NET_WINNINGS,
    label: "Net winnings",
  },
  {
    value: LeaderboardRankBy.ROUND_PLAYED,
    label: "Round played",
  },
  {
    value: LeaderboardRankBy.TOTAL,
    label: "Total WispSUI",
  },
  {
    value: LeaderboardRankBy.WIN_RATE,
    label: "Win rate",
  },
];

export function Leaderboard({ onBack }: { onBack: () => void }): ReactElement {
  const { settings } = useWispSettings();
  const [selectedRank, setSelectedRank] = useState(sortOptions[0]);
  const isMobile = useMediaQuery("only screen and (max-width : 768px)");

  const leaders = useLeaderboard(settings.networkEnv, selectedRank.value);

  const [top3Leaders, restLeaders] = useMemo(() => {
    return [leaders.slice(0, 3), leaders.slice(3)];
  }, [leaders]);

  const handleChangeSelectedRank = (option: LeaderboardRankBy): void => {
    const op = sortOptions.find((s) => s.value === option);
    if (op) {
      setSelectedRank(op);
    }
  };

  return (
    <div className="max-w-4xl mx-auto w-full mb-10">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <span className="p-2 cursor-pointer rounded-full hover:bg-white hover:bg-opacity-20" onClick={onBack}>
            <ArrowLeftIcon />
          </span>
          <span className="font-semibold text-xl">Leaderboard</span>
        </div>
        <div>
          <Listbox value={selectedRank.value} onChange={handleChangeSelectedRank}>
            <div className="relative">
              <Listbox.Button className="flex items-center w-full cursor-default rounded-lg bg-dark-400 py-2 px-3 text-left shadow-md text-dark-600">
                <span className="text-white font-medium">{selectedRank.label}</span>
                <span className="pointer-events-none ml-2">
                  <ChevronDownIcon className="h-2.5 w-2.5 text-white" />
                </span>
              </Listbox.Button>
              <Listbox.Options className="absolute z-20 mt-1 min-w-[160px] max-h-60 w-full overflow-auto rounded-md bg-dark-400 py-2 text-base shadow-lg ring-1 ring-white ring-opacity-10 right-0">
                {sortOptions.map((option) => (
                  <Listbox.Option
                    className="px-4 py-1 text-center hover:bg-dark-600 hover:bg-opacity-30 cursor-pointer"
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </div>
          </Listbox>
        </div>
      </div>
      <div className="mt-10 grid grid-cols-1 md:grid-cols-3 gap-5">
        {top3Leaders.map((item) => {
          const netWinnings = item.netWinnings.toExact(undefined, 2);
          return (
            <div className="relative overflow-hidden p-4 bg-dark-600 rounded-lg bg-opacity-70" key={item.sender}>
              <div
                className={clsx(
                  "absolute -left-10 -rotate-45 w-[150px] h-8 leading-8 text-white font-medium text-center",
                  `leaderboard-${item.position}`,
                )}
              >
                #{item.position}
              </div>
              <div className="text-pRed-500 font-medium text-center py-3 mb-2">{shortenAddress(item.sender)}</div>
              <div className="flex items-center justify-between">
                <span className="text-sm text-gray-400">Win Rate</span>
                <span>{item.rate.toFixed(2)}%</span>
              </div>
              <div className="flex items-center justify-between mt-2">
                <span className="text-sm text-gray-400">Net Winnings</span>
                <span className={netWinnings.startsWith("-") ? "text-pRed-500" : "text-pGreen-500"}>{netWinnings}</span>
              </div>
              <div className="flex items-center justify-between mt-2">
                <span className="text-sm text-gray-400">Round Won</span>
                <span>
                  {item.win}/{item.total}
                </span>
              </div>
            </div>
          );
        })}
      </div>
      {isMobile ? (
        <div className="mt-5 space-y-5">
          {restLeaders.map((item) => {
            const netWinnings = item.netWinnings.toExact(undefined, 2);
            return (
              <div className="overflow-hidden p-4 bg-dark-600 rounded-lg bg-opacity-70" key={item.sender}>
                <div className="text-pRed-500 font-medium text-center py-3 mb-2">{shortenAddress(item.sender)}</div>
                <div className="flex items-center justify-between">
                  <span className="text-sm text-gray-400">Win Rate</span>
                  <span>{item.rate.toFixed(2)}%</span>
                </div>
                <div className="flex items-center justify-between mt-2">
                  <span className="text-sm text-gray-400">Net Winnings</span>
                  <span className={netWinnings.startsWith("-") ? "text-pRed-500" : "text-pGreen-500"}>
                    {netWinnings}
                  </span>
                </div>
                <div className="flex items-center justify-between mt-2">
                  <span className="text-sm text-gray-400">Round Won</span>
                  <span>
                    {item.win}/{item.total}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="mt-5 bg-dark-600 bg-opacity-50 rounded-lg overflow-hidden">
          <div className="grid grid-cols-[30px,1.5fr,1fr,1fr,1fr,1fr] gap-3 font-medium bg-dark-600 bg-opacity-50 py-3 px-5 border-b border-white border-opacity-10">
            <div></div>
            <div>User</div>
            <div className="text-right">Net Winnings</div>
            <div className="text-right">Win Rate</div>
            <div className="text-right">Round Won</div>
            <div className="text-right">Round Played</div>
          </div>
          <div className="divide-y divide-white divide-opacity-10">
            {restLeaders.map((item) => {
              const netWinnings = item.netWinnings.toExact(undefined, 2);
              return (
                <div className="grid grid-cols-[30px,1.5fr,1fr,1fr,1fr,1fr] gap-3 py-3 px-5" key={item.sender}>
                  <div className="text-center">#{item.position}</div>
                  <div className="text-left">{shortenAddress(item.sender)}</div>
                  <div
                    className={clsx("text-right", netWinnings.startsWith("-") ? "text-pRed-500" : "text-pGreen-500")}
                  >
                    {netWinnings}
                  </div>
                  <div className="text-right">{item.rate.toFixed(2)}%</div>
                  <div className="text-right">{item.win}</div>
                  <div className="text-right">{item.total}</div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
