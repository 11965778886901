import { ReactElement, useEffect, useMemo, useState } from "react";
import { useWallet } from "@suiet/wallet-kit";
import { Button } from "components/atoms/Button";
import { Modal } from "components/atoms/Modal";
import { useWalletHelper } from "contexts/WalletHelperContext";
import { Amount } from "sdk/entities/amount";
import { toCommaSeparated } from "sdk/utils/formatNumber";
import { isTooManyDecimals, tryParseAmount } from "sdk/utils/tryParseAmount";
import { VeWISP } from "utils/coins";

import { AssetInput } from "./AssetInput";

type Props = {
  isOpen: boolean;
  isLoading: boolean;
  veWispBalance: Amount;
  onClose: () => void;
  onClick: (amount: Amount) => void;
};

export function CreateVestingVeWispModal({ isOpen, isLoading, veWispBalance, onClose, onClick }: Props): ReactElement {
  const { adapter } = useWallet();
  const { setOpenSelectWallet } = useWalletHelper();
  const [typedValue, setTypedValue] = useState("");

  useEffect(() => {
    return () => {
      if (!isOpen) {
        setTypedValue("");
      }
    };
  }, [isOpen]);

  const amount = useMemo(() => {
    if (!typedValue) {
      return undefined;
    }
    return tryParseAmount(typedValue, VeWISP);
  }, [typedValue]);

  const errorMsg: string | null = useMemo(() => {
    if (!veWispBalance || veWispBalance.equalTo(0)) {
      return "Empty balance";
    }
    if (!amount) {
      return "Enter an amount";
    }
    if (veWispBalance?.lessThan(amount)) {
      return "Insufficient balance";
    }
    return null;
  }, [amount, veWispBalance]);

  const handleChangeInput = (value: string): void => {
    if (isTooManyDecimals(value, VeWISP)) {
      return;
    }
    setTypedValue(value);
  };

  const handleMaxInput = (): void => {
    if (!veWispBalance) {
      return;
    }
    setTypedValue(veWispBalance.toExact({ groupSeparator: "" }));
  };

  const handleStakeClick = (): void => {
    if (!amount || !amount.greaterThan(0)) {
      return;
    }
    onClick(amount);
  };

  return (
    <Modal isOpen={isOpen} title="Create Vesting VeWisp Modal" onClose={onClose}>
      <div className="space-y-4">
        <div>Enter VeWisp amount you want to convert to Wisp</div>
        <AssetInput
          asset={VeWISP}
          balance={veWispBalance}
          label="Amount"
          otherAsset={undefined}
          value={toCommaSeparated(typedValue)}
          fixedCoin
          maxAmount
          onInputChange={handleChangeInput}
          onMaxAmount={handleMaxInput}
          onSelect={(): void => {}}
        />
        <div>
          {!adapter ? (
            <Button
              className="px-6 py-3 w-full text-xl font-Poppins whitespace-pre-wrap"
              onClick={(): void => setOpenSelectWallet(true)}
            >
              Connect Wallet
            </Button>
          ) : (
            <Button
              className="px-6 py-3 w-full text-xl font-Poppins whitespace-pre-wrap"
              disabled={Boolean(errorMsg)}
              isLoading={isLoading}
              onClick={handleStakeClick}
            >
              {errorMsg ?? (isLoading ? "Processing" : "Create Vesting NFT")}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}
