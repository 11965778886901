/* eslint-disable @typescript-eslint/no-explicit-any */
import { Connection, JsonRpcProvider } from "@mysten/sui.js";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { Amount } from "sdk/entities/amount";
import { VeWISP } from "utils/coins";
import { NetworkEnv, WISP_CONFIG } from "utils/constant";

// VEWISP: 0x355c9aca89cf96343fd5ad5e22b40d78c57994d5d36ae06189bb12a052d648a3::vecoin::VeCoin<0x355c9aca89cf96343fd5ad5e22b40d78c57994d5d36ae06189bb12a052d648a3::vewisp::VEWISP>

export type VeWispObj = {
  object: string;
  type: string;
  balance: bigint;
};

export type VeWispNftObj = {
  object: string;
  type: string;
  lockedTime: Date;
  amount: Amount;
};

export type VeWispReturn = {
  objects: VeWispObj[];
  totalBalance: Amount;
  veWispNftObjs: VeWispNftObj[];
};

// 0x6b1f528d5c05f24648b29dee04ef1e54b0256aca6ff5a90cb84d9dc9868e2623::vesting::VestingVeWisp

async function getVeWispObjects(provider: JsonRpcProvider, address: string, cursor: string | null): Promise<any> {
  const resp = await provider.getOwnedObjects({
    owner: address ?? "",
    limit: 50,
    cursor: cursor ? cursor : undefined,
  });
  const ids = resp.data.map((i) => i.data?.objectId ?? "").filter(Boolean);
  if (ids.length === 0) {
    return [[], false, ""];
  }
  const resp2 = await provider.multiGetObjects({
    ids: ids,
    options: {
      showContent: true,
      showType: true,
    },
  });
  const objs = resp2.filter((i) =>
    i.data?.type?.includes(`${WISP_CONFIG.veWisp.package}::${WISP_CONFIG.veWisp.module}::${WISP_CONFIG.veWisp.name}`),
  );
  const nftObjs = resp2.filter((i) =>
    i.data?.type?.startsWith(`${WISP_CONFIG.vesting.package}::${WISP_CONFIG.vesting.module}::VestingVeWisp`),
  );
  return [{ objs, nftObjs }, resp.hasNextPage, resp.nextCursor];
}

export function useAllVeWisp(rpcEndpoint: string, networkEnv: NetworkEnv, address: string | undefined): VeWispReturn {
  const { data } = useQuery(
    ["get-all-vewisp", address, rpcEndpoint, networkEnv],
    async (): Promise<VeWispReturn> => {
      try {
        const provider = new JsonRpcProvider(
          new Connection({
            fullnode: rpcEndpoint,
          }),
        );
        let nextCursor: string | null = null;
        const veWispObjects = [];
        const veWispNftObjects = [];
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const resp: any = await getVeWispObjects(provider, address ?? "", nextCursor);
          const [{ objs, nftObjs }, hasNextPage, tempNextCursor] = resp;
          if (objs.length) {
            veWispObjects.push(...objs);
          }
          if (nftObjs.length) {
            veWispNftObjects.push(...nftObjs);
          }
          if (!(hasNextPage && tempNextCursor)) {
            break;
          } else {
            nextCursor = tempNextCursor;
          }
        }
        const veWispObjs = veWispObjects
          // .filter((o) => !!o?.data?.content?.fields?.balance && o.data.content.fields.balance !== "0")
          .map((obj: any) => {
            return {
              object: obj.data.objectId,
              type: obj.data.type,
              balance: BigInt(obj?.data?.content?.fields?.balance ?? 0),
            } as VeWispObj;
          });
        const veWispNftObjs = veWispNftObjects.map((obj: any) => {
          return {
            object: obj.data.objectId,
            type: obj.data.type,
            lockedTime: new Date(Number(obj.data.content.fields.lock_timestamp)),
            amount: Amount.fromRawAmount(VeWISP, obj.data.content.fields.vewisp),
          } as VeWispNftObj;
        });
        const totalBalanceBint = veWispObjs.reduce((acc, curr) => {
          return acc + curr.balance;
        }, 0n);
        return {
          objects: veWispObjs,
          totalBalance: Amount.fromRawAmount(VeWISP, totalBalanceBint),
          veWispNftObjs,
        } as VeWispReturn;
      } catch (err: any) {
        console.error(err);
        toast.error(err.message ?? "Fetching bet history error");
        return {
          objects: [],
          veWispNftObjs: [],
          totalBalance: Amount.fromRawAmount(VeWISP, 0),
        };
      }
    },
    {
      enabled: !!address,
    },
  );

  return (
    data ?? {
      objects: [],
      veWispNftObjs: [],
      totalBalance: Amount.fromRawAmount(VeWISP, 0),
    }
  );
}
