import { ReactElement } from "react";
import clsx from "clsx";

type Props = {
  type: "up" | "down";
  payout: string;
  isActive: boolean;
};

export function Payout({ type, payout, isActive }: Props): ReactElement {
  if (type === "up") {
    return (
      <div
        className={clsx(
          "p-4 border-b-2 border-b-white border-opacity-10 text-center mb-5 space-x-2",
          isActive && "bg-predictionUp",
        )}
      >
        <span className="font-bold uppercase text-pGreen-500">up</span>
        <span className="text-sm">Payout: {payout}x</span>
      </div>
    );
  }

  return (
    <div
      className={clsx(
        "p-4 border-t-2 border-t-white border-opacity-10 text-center space-x-2 mt-5",
        isActive && "bg-predictionDown",
      )}
    >
      <span className="font-bold uppercase text-pRed-500">down</span>
      <span className="text-sm">Payout: {payout}x</span>
    </div>
  );
}
