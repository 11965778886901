import { ReactElement } from "react";

import { RemoveLiquidity } from "./RemoveLiquidity";

export function RemoveLiquidityView(): ReactElement {
  return (
    <div className="relative w-full flex-1 space-y-8 z-10 flex flex-col justify-center">
      <RemoveLiquidity className="mx-auto" />
    </div>
  );
}
