/* eslint-disable @typescript-eslint/no-explicit-any */
import { Connection, JsonRpcProvider } from "@mysten/sui.js";
import CoinAPI from "api/coin";
import uniq from "lodash/uniq";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { Amount } from "sdk/entities/amount";
import { Coin } from "sdk/entities/coin";
import { SpNFT } from "sdk/entities/spNFT";
import { CoinSet } from "sdk/utils/coinMap";
import { SUI, VeWISP } from "utils/coins";
import { NetworkEnv, WISP_CONFIG } from "utils/constant";

async function getNftObjects(provider: JsonRpcProvider, address: string, cursor: string | null): Promise<any> {
  const resp = await provider.getOwnedObjects({
    owner: address ?? "",
    limit: 50,
    cursor: cursor ? cursor : undefined,
  });
  const ids = resp.data.map((i) => i.data?.objectId ?? "").filter(Boolean);
  if (ids.length === 0) {
    return [[], false, ""];
  }
  const resp2 = await provider.multiGetObjects({
    ids: ids,
    options: {
      showContent: true,
      showType: true,
    },
  });
  const objs = resp2.filter((i) =>
    i.data?.type?.startsWith(
      `${WISP_CONFIG.farm.spNFT.packageObject}::${WISP_CONFIG.farm.spNFT.module}::${WISP_CONFIG.farm.spNFT.name}`,
    ),
  );
  return [objs, resp.hasNextPage, resp.nextCursor];
}

export function useAllSpNfts(rpcEndpoint: string, networkEnv: NetworkEnv, address: string | undefined): SpNFT[] {
  const { data } = useQuery(
    ["get-all-nft", address, rpcEndpoint, networkEnv],
    async (): Promise<SpNFT[]> => {
      try {
        const provider = new JsonRpcProvider(
          new Connection({
            fullnode: rpcEndpoint,
          }),
        );
        let nextCursor: string | null = null;
        const nftObjects = [];
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const resp: any = await getNftObjects(provider, address ?? "", nextCursor);
          const [objs, hasNextPage, tempNextCursor] = resp;
          if (objs.length) {
            nftObjects.push(...objs);
          }
          if (!(hasNextPage && tempNextCursor)) {
            break;
          } else {
            nextCursor = tempNextCursor;
          }
        }
        const coinTypes = uniq(
          nftObjects
            .map((n) => n.data.type)
            .map((t: string) => {
              const stakingCoins = t.substring(t.indexOf("<") + 1, t.indexOf(">"));
              if (stakingCoins.includes(", ")) {
                const [coin1Type, coin2Type] = stakingCoins.split(", ");
                return [coin1Type, coin2Type];
              }
              return [stakingCoins];
            })
            .flatMap((t) => t),
        );
        let coins: CoinSet = new CoinSet();
        if (coinTypes.length) {
          const coinInfos = await CoinAPI.getCoinInfos(networkEnv, coinTypes);
          for (const piece of coinInfos.data) {
            coins.add(
              new Coin(piece.package_addr, piece.module, piece.type, piece.decimal ?? 0, piece.treasury_addr, {
                imageUrl: piece.icon_url,
                ticker: piece.symbol,
                projectName: piece.symbol,
                description: piece.description,
              }),
            );
          }
        }
        const spNFTs = nftObjects.map((obj: any) => {
          const stakingCoins = obj.data.type.substring(obj.data.type.indexOf("<") + 1, obj.data.type.indexOf(">"));
          let stakingCoin: Coin | undefined = undefined;
          if (stakingCoins.includes("::pool::WISPLP")) {
            const twoCoins = stakingCoins.substring(obj.data.type.indexOf("<") + 1);
            const [coin1Type, coin2Type] = twoCoins.split(", ");
            const _coin1 = Coin.fromType(coin1Type);
            const _coin2 = Coin.fromType(coin2Type);
            const coin1 = _coin1.equals(SUI) ? SUI : coins.get2(_coin1) ?? _coin1;
            const coin2 = _coin2.equals(SUI) ? SUI : coins.get2(_coin2) ?? _coin2;
            const lpCoin = Coin.createLpCoin(stakingCoins + ">");
            lpCoin.coinA = coin1;
            lpCoin.coinB = coin2;
            stakingCoin = lpCoin;
          } else {
            const _coin1 = Coin.fromType(stakingCoins);
            stakingCoin = _coin1.equals(SUI) ? SUI : coins.get2(_coin1) ?? _coin1;
          }
          const fields = obj.data.content.fields;
          console.log({ fields });
          return new SpNFT({
            packageObject: WISP_CONFIG.farm.spNFT.packageObject,
            module: WISP_CONFIG.farm.spNFT.module,
            name: WISP_CONFIG.farm.spNFT.name,
            stakingCoin,
            objectId: obj.data.objectId,
            boostBalance: Amount.fromRawAmount(VeWISP, fields.boost_balance),
            boostMultiplier: Number(fields.boost_multiplier),
            stakingBalance: Amount.fromRawAmount(stakingCoin, fields.lp_balance),
            rewardDebt: BigInt(fields.reward_debt),
            stakePoint: BigInt(fields.stake_point),
            stakePoolId: fields.stake_pool_id,
            lockPeriod: Number(fields.lock_period ?? "0"),
            unlockTime:
              fields.unlock_time && fields.unlock_time !== "0" ? new Date(Number(fields.unlock_time) * 1000) : null,
          });
        });
        return spNFTs;
      } catch (err: any) {
        console.error(err);
        toast.error(err.message ?? "Fetching bet history error");
        return [];
      }
    },
    {
      enabled: !!address,
    },
  );

  return data ?? [];
}
