import React from "react";
import { TransactionArgument, TransactionBlock } from "@mysten/sui.js";
import { useWallet } from "@suiet/wallet-kit";
import { Button } from "components/atoms/Button";
import { TriangleDownIcon } from "components/atoms/icons/TriangleDownIcon";
import { AssetInput } from "components/molecules/AssetInput";
import { useWalletHelper } from "contexts/WalletHelperContext";
import { useWispSettings } from "contexts/WispSettingsContext";
import { useAllStCoins } from "hooks/useAllStCoins";
import { useLsdDynamicFee } from "hooks/useLsdDynamicFee";
import { Amount } from "sdk/entities/amount";
import { Coin } from "sdk/entities/coin";
import { Percent } from "sdk/entities/percent";
import { ONE_HUNDRED_PERCENT } from "sdk/misc";
import { toCommaSeparated } from "sdk/utils/formatNumber";
import { isTooManyDecimals } from "sdk/utils/tryParseAmount";
import { SUI, WISP_SUI } from "utils/coins";
import { WISP_CONFIG } from "utils/constant";
import { extractGasPayment, getSuiObjectRefs } from "utils/getCoinObjectIds";

import { useLsdDeposit } from "./hooks/useDepositLsd";
import * as ActionHandler from "./reducers/actionHandler";
import * as Reducers from "./reducers/depositReducer";

export default function LsdDeposit(): React.ReactElement {
  const [state, dispatch] = React.useReducer(Reducers.reducer, Reducers.initialState);
  const { balance, inputError, parsedAmount } = useLsdDeposit(state);
  const { fungibleBalances, setOpenSelectWallet, signAndExecuteTransaction } = useWalletHelper();
  const { adapter, address } = useWallet();
  const { settings } = useWispSettings();

  const { allStCoins } = useAllStCoins({ networkEnv: settings.networkEnv, defaultList: [SUI] });
  const fee = useLsdDynamicFee({
    networkEnv: settings.networkEnv,
    params: {
      amount: parsedAmount?.quotient.toString() ?? "",
      tokenType: parsedAmount?.coin.type ?? "",
    },
  });
  const feePercent = React.useMemo(() => {
    return fee ? new Percent(Math.floor(fee), 10_000) : undefined;
  }, [fee]);

  const wispSuiAmount = React.useMemo(() => {
    if (!parsedAmount) {
      return undefined;
    }
    if (parsedAmount.coin.equals(SUI)) {
      return Amount.fromRawAmount(WISP_SUI, parsedAmount.quotient);
    }
    return parsedAmount.multiply(ONE_HUNDRED_PERCENT.subtract(feePercent ? feePercent : new Percent(0, 1)));
  }, [feePercent, parsedAmount]);

  const allStBalances = React.useMemo(() => {
    return (allStCoins ?? []).map((coin) => {
      const amt = fungibleBalances?.find((f) => f.coin.equals(coin));
      if (amt) {
        return amt;
      }
      return Amount.fromRawAmount(coin, 0);
    });
  }, [allStCoins, fungibleBalances]);

  const [isBuildingTx, setIsBuildingTx] = React.useState<boolean>(false);

  async function handleDeposit(): Promise<void> {
    if (isBuildingTx || !address || !state.coin || !parsedAmount || !parsedAmount.greaterThan(0)) {
      return;
    }
    setIsBuildingTx(true);
    const isSui = state.coin.equals(SUI);
    const objectRefs = await getSuiObjectRefs(settings.customRPC, address, isSui ? [SUI] : [SUI, state.coin]);
    const suiObjectRefs = objectRefs[SUI.type] ?? [];
    const coinObjectRefs = objectRefs[state.coin.type] ?? [];
    const [suiGas] = extractGasPayment(suiObjectRefs, 100_000_000n);
    const tx = new TransactionBlock();

    let splitCoin = undefined;
    if (isSui) {
      splitCoin = tx.splitCoins(tx.gas, [tx.pure(parsedAmount.quotient.toString())])[0];
    }
    if (!isSui) {
      tx.setGasPayment(suiGas);
    }
    try {
      if (isSui) {
        const [depositReceipt] = tx.moveCall({
          // Get withdraw receipt object
          target: `${WISP_CONFIG.lsd.package}::lsdfi::deposit_SUI_mul_coin`,
          arguments: [
            tx.object(
              WISP_CONFIG.lsd.registry, // LSDFI_REGISTRY
            ),
            tx.object(
              "0x33dad3c4a8ec662326d69d94151ab157e26e621830cce125e97965a0111c37c4", // EXCHANGE_REGISTRY
            ),
            tx.object(WISP_CONFIG.lsd.aggregatorReg),
            tx.makeMoveVec({
              objects: [splitCoin] as TransactionArgument[],
            }),
            tx.pure(parsedAmount.quotient.toString()), // SUI amount
            tx.object("0x6"),
          ],
        });

        tx.moveCall({
          // Stake to volosui
          target: "0x4aeb6357f338dbc4da1bc34ac06c5b09438fbe9d11e8f0666f0bd983e5c0bab8::aftermath_adapter::stake",
          arguments: [
            tx.object(
              "0x2ba221c741f952572537137ab850f5f40f95ab88a7f5c78bef9d6a648ac89483", // AFTERMATH_ADAPTER
            ),
            tx.object(
              "0x6c56f66099b83416ac77e0f79f9cb66e5c1bb9a9be8a239cdd8786eefd67d8eb", // LSDFI_REGISTRY
            ),
            tx.object(
              "0x690f36f9c5249b0c4c9b3efdf8a2864c750a8021037360e3b7bedc9ceafb277f", // AFTERMATH_STAKED_SUI_VAULT
            ),
            tx.object(
              "0x091686a693e86929f91ef539d867fae334a33d124bc2c204dcb3b53dd9016501", // AFTERMATH_SAFE
            ),
            tx.object(
              "0x5", // SUI_SYSTEM
            ),
            tx.object(
              "0x8d357115058f22976cd01c5415116d9aca806d1ded37eecd75d87978f404e927", // AFTERMATH_REFERRAL_VAULT
            ),
            tx.pure("0x2b761dda04b324c8f2aaf8429c0e160aa3772df37d00a37e6ec948aa1efebd1a"), // VALIDATOR ADDRESS (STAKIN)
            depositReceipt,
          ],
        });

        tx.moveCall({
          // Stake to volosui
          target: "0xf449105218d90e68ca5cd530a375e40f392b249facc3374c85678afc18b3adf6::volo_adapter::stake",
          arguments: [
            tx.object(
              "0x53ce50cf2f80e9891d3a8a06c2c953722b48d347bc26cebe1b30f28432b88eb5", // VOLO_ADAPTER
            ),
            tx.object(
              "0x5", // SUI_SYSTEM
            ),
            tx.object(
              "0x6c56f66099b83416ac77e0f79f9cb66e5c1bb9a9be8a239cdd8786eefd67d8eb", // LSDFI_REGISTRY
            ),
            tx.object(
              "0x6eb5cce7a8ca2cbae2c564d3ad490bfbec6ee405c87cf110f0f19ce5d144d958", // VOLO_NATIVE_POOL
            ),
            tx.object(
              "0x1ecb36a7a8e39fefc929b34d3b90b15301d9326deb4b4b70b6130b45b7261d54", // VOLO_METADATA
            ),
            depositReceipt,
          ],
        });

        tx.moveCall({
          // Stake to hasui
          target: "0x80d5fd2f567fdc45f88139f11563aea980486466ec33c5719a70ce27435710ab::haedal_adapter::stake",
          arguments: [
            tx.object(
              "0x566c0784096fc0698babe787b261d594dde9e4da02de539fb4be2739e334488b", // HAEDAL_ADAPTER
            ),
            tx.object(
              "0x5", // SuiSystemState
            ),
            tx.object(
              "0x6c56f66099b83416ac77e0f79f9cb66e5c1bb9a9be8a239cdd8786eefd67d8eb", // LSDFI_REGISTRY
            ),
            tx.object(
              "0x6e384d2da5b040b27f973155e25bbe4beb0ad5ca8ee0a36e20dff356094c9fc0", // HAEDAL_STAKING
            ),
            tx.pure("0x2b761dda04b324c8f2aaf8429c0e160aa3772df37d00a37e6ec948aa1efebd1a"), // VALIDATOR ADDRESS (STAKIN)
            depositReceipt,
          ],
        });

        tx.moveCall({
          // Destroy deposit receipt object
          target: `${WISP_CONFIG.lsd.package}::lsdfi::drop_deposit_SUI_receipt`,
          arguments: [
            tx.object(
              WISP_CONFIG.lsd.registry, // LSDFI_REGISTRY
            ),
            depositReceipt,
          ],
        });
      } else {
        tx.moveCall({
          target: `${WISP_CONFIG.lsd.package}::lsdfi::deposit_mul_coin`,
          typeArguments: [state.coin.type],
          arguments: [
            tx.object(WISP_CONFIG.lsd.registry),
            tx.object(WISP_CONFIG.lsd.aggregatorReg),
            tx.makeMoveVec({
              objects: coinObjectRefs.map((i) => {
                return tx.object(i.objectId);
              }),
            }),
            tx.pure(parsedAmount.quotient.toString()),
            tx.object("0x6"),
          ],
        });
      }
      tx.setGasBudget(100_000_000);
      await signAndExecuteTransaction(tx);
      handleChangeInput("");
    } catch (e) {
      console.error(e);
    } finally {
      setIsBuildingTx(false);
    }
  }

  function handleChangeInput(value: string): void {
    if (isTooManyDecimals(value, state.coin)) {
      return;
    }
    ActionHandler.userInput(dispatch, { typedValue: value });
  }

  function handleMaxInput(): void {
    handleChangeInput(balance?.toExact({ groupSeparator: "" }) ?? "");
  }

  function handleSelectInputAsset(asset: Coin | undefined): void {
    ActionHandler.selectCoin(dispatch, { coin: asset });
  }

  return (
    <React.Fragment>
      <div>
        <AssetInput
          asset={state.coin}
          balance={balance}
          balances={allStBalances}
          label="from"
          maxAmount={true}
          otherAsset={undefined}
          value={toCommaSeparated(state.typedValue)}
          isFixedList
          onInputChange={handleChangeInput}
          onMaxAmount={handleMaxInput}
          onSelect={handleSelectInputAsset}
        />
        <div className="my-3">
          <TriangleDownIcon className="mx-auto" />
        </div>
        <AssetInput
          asset={WISP_SUI}
          balance={undefined}
          label="to"
          otherAsset={undefined}
          value={wispSuiAmount?.toExact()}
          fixedCoin
          readonly
          onInputChange={(): void => {}}
          onSelect={(): void => {}}
        />
        <div className="text-xs mt-2">
          <div className="text-gray-300">
            Deposit Fee: {!parsedAmount?.coin.equals(SUI) ? (feePercent ? feePercent.toFixed(2) : "--") : "0"}%
          </div>
        </div>
      </div>
      <div>
        {!adapter ? (
          <Button
            className="px-6 py-3 w-full text-xl font-semibold font-Poppins whitespace-pre-wrap"
            onClick={(): void => setOpenSelectWallet(true)}
          >
            Connect Wallet
          </Button>
        ) : (
          <Button
            className="px-6 py-3 w-full text-xl font-semibold font-Poppins whitespace-pre-wrap"
            disabled={Boolean(inputError || isBuildingTx)}
            isLoading={isBuildingTx}
            onClick={handleDeposit}
          >
            {inputError ?? (isBuildingTx ? "Depositing" : "Deposit")}
          </Button>
        )}
      </div>
    </React.Fragment>
  );
}
