/* eslint-disable @typescript-eslint/no-explicit-any */
import { Connection, JsonRpcProvider } from "@mysten/sui.js";
import CoinAPI from "api/coin";
import { useQuery } from "react-query";
import { Amount } from "sdk/entities/amount";
import { Coin } from "sdk/entities/coin";
import { NetworkEnv, WISP_CONFIG } from "utils/constant";

type Props = {
  networkEnv: NetworkEnv;
  customRPC: string;
};

type DataResponse = {
  balances: Amount[];
  baseFee: number;
  redemptionFee: number;
  totalSupplyWispSui: bigint;
};

export function useLsdRegistry({ customRPC, networkEnv }: Props): {
  data: DataResponse | undefined;
  isLoading: boolean;
} {
  const { data, isLoading } = useQuery<DataResponse | undefined>(["get-lsd-registry-data"], async () => {
    const provider = new JsonRpcProvider(
      new Connection({
        fullnode: customRPC,
      }),
    );
    const resp1 = await provider.getObject({
      id: WISP_CONFIG.lsd.registry,
      options: {
        showContent: true,
      },
    });
    const fields = (resp1?.data?.content as any)?.fields;
    const id = fields?.available_balances?.fields?.id?.id;
    if (!id) {
      return undefined;
    }
    const typeNames: string[] = (fields?.supported_lsts?.fields?.contents ?? []).map((i: any) => i?.fields?.name);
    const resp2 = await Promise.all(
      typeNames.map((typeName) =>
        provider.getDynamicFieldObject({
          parentId: id,
          name: {
            type: "0x1::type_name::TypeName",
            value: typeName,
          },
        }),
      ),
    );
    const _coins = typeNames.map((t) => {
      return Coin.fromType(t.startsWith("0x") ? t : `0x${t}`);
    });
    const resp3 = await CoinAPI.getCoinInfos(
      networkEnv,
      _coins.map((c) => c.type),
    );
    const coins: Coin[] = (resp3.data ?? []).map((piece: any) => {
      return new Coin(piece.package_addr, piece.module, piece.type, piece.decimal ?? 0, piece.treasury_addr, {
        imageUrl: piece.icon_url,
        ticker: piece.symbol,
        projectName: piece.symbol,
        description: piece.description,
      });
    });
    const balances: Amount[] = resp2
      .map((re) => {
        const name = (re?.data?.content as any)?.fields?.name?.fields?.name;
        const coin = coins.find((c) => !!name && c.type.endsWith(name));
        if (!coin) {
          return null;
        }
        return Amount.fromRawAmount(coin, (re?.data?.content as any)?.fields?.value ?? 0);
      })
      .filter(Boolean) as Amount[];
    return {
      balances,
      baseFee: Number(fields?.base_fee ?? 0),
      redemptionFee: Number(fields?.redemption_fee ?? 0),
      totalSupplyWispSui: BigInt(fields?.wispSUI_treasury?.fields?.total_supply?.fields?.value ?? 0),
    };
  });

  return { data, isLoading };
}

// RESP1
// {
//   "data": {
//       "objectId": "0xddfeec9f9982eaf6d69a67496cb873da9a5c11726f79bca7812cb089951f1e68",
//       "version": "10591994",
//       "digest": "5ALXfTKTKKUHUzHrgSpAkaYWPKXGmLZ2usH8HaDsvhmC",
//       "content": {
//           "dataType": "moveObject",
//           "type": "0xefa4e1b61d01dbe59eb8aec59a5ce97aeb20c8e0837231fc88f39ca28b6a5ea6::pool::PoolRegistry",
//           "hasPublicTransfer": true,
//           "fields": {
//               "acceptable_result_time": "300000",
//               "available_balances": {
//                   "type": "0x2::table::Table<0x1::type_name::TypeName, u64>",
//                   "fields": {
//                       "id": {
//                           "id": "0xc27b722cbc8ddb08e82b0e17d28c70b3ddd9d9577ea35b8548189e442dd2adcf"
//                       },
//                       "size": "2"
//                   }
//               },
//               "balances": {
//                   "type": "0x2::bag::Bag",
//                   "fields": {
//                       "id": {
//                           "id": "0x54c321a2d5ef2bdd78bc15a1fa6df1703b6907f3fd28c02381714ab5b66b8cbc"
//                       },
//                       "size": "2"
//                   }
//               },
//               "base_fee": "10",
//               "fee_to": "0xd9dc89e74174b963a9f0a2b009787e6cb070805c9edbdd10f600dbcd7d7e6d5f",
//               "id": {
//                   "id": "0xddfeec9f9982eaf6d69a67496cb873da9a5c11726f79bca7812cb089951f1e68"
//               },
//               "max_diff_weights": {
//                   "type": "0x2::table::Table<0x1::type_name::TypeName, u64>",
//                   "fields": {
//                       "id": {
//                           "id": "0x52d8944c8a07f97ad6820ef2593ecfeb55214193e8920f15d73672a40f600d1c"
//                       },
//                       "size": "2"
//                   }
//               },
//               "redemption_fee": "25",
//               "risk_coefficients": {
//                   "type": "0x2::table::Table<0x1::type_name::TypeName, u64>",
//                   "fields": {
//                       "id": {
//                           "id": "0x13c0513ce06686a24b345820cb4eea85f6db6fdf04df14966f2760c7cb64421e"
//                       },
//                       "size": "2"
//                   }
//               },
//               "slope": "30000",
//               "sui_split_bps": "500",
//               "supported_lsts": {
//                   "type": "0x2::vec_set::VecSet<0x1::type_name::TypeName>",
//                   "fields": {
//                       "contents": [
//                           {
//                               "type": "0x1::type_name::TypeName",
//                               "fields": {
//                                   "name": "94cebd4b0f119af7fe75a64c040e832acfc5d96a676e6f1a98b039df58039c75::stwisp::STWISP"
//                               }
//                           },
//                           {
//                               "type": "0x1::type_name::TypeName",
//                               "fields": {
//                                   "name": "53358b5c79d0474e97f3ec7403cdec213c008db6b30552cd1a5a8624aee33d6a::stsui::STSUI"
//                               }
//                           }
//                       ]
//                   }
//               },
//               "wispSUI_treasury": {
//                   "type": "0x2::coin::TreasuryCap<0xefa4e1b61d01dbe59eb8aec59a5ce97aeb20c8e0837231fc88f39ca28b6a5ea6::wispSUI::WISPSUI>",
//                   "fields": {
//                       "id": {
//                           "id": "0x8dd9a1ef0efc432a4c439492098d171fd31769985d2da4ae8c5a116dc88f656a"
//                       },
//                       "total_supply": {
//                           "type": "0x2::balance::Supply<0xefa4e1b61d01dbe59eb8aec59a5ce97aeb20c8e0837231fc88f39ca28b6a5ea6::wispSUI::WISPSUI>",
//                           "fields": {
//                               "value": "999988000000"
//                           }
//                       }
//                   }
//               }
//           }
//       }
//   }
// }

// RESP2
// [
//   {
//       "data": {
//           "objectId": "0x8d536972863e4924602baca82268dec4167a59f8781f9482ffce8a8d6281de70",
//           "version": "10591994",
//           "digest": "73CPX2h5QGnnyrBC63fCzsGaJ4SoXG6Y4B6yeXwC6Ydi",
//           "type": "0x2::dynamic_field::Field<0x1::type_name::TypeName, u64>",
//           "owner": {
//               "ObjectOwner": "0xc27b722cbc8ddb08e82b0e17d28c70b3ddd9d9577ea35b8548189e442dd2adcf"
//           },
//           "previousTransaction": "7tzAX6A1p1kn5rpe3uZDLvhRGvjG2Ztpwp81ynd77Bxu",
//           "storageRebate": "2416800",
//           "content": {
//               "dataType": "moveObject",
//               "type": "0x2::dynamic_field::Field<0x1::type_name::TypeName, u64>",
//               "hasPublicTransfer": false,
//               "fields": {
//                   "id": {
//                       "id": "0x8d536972863e4924602baca82268dec4167a59f8781f9482ffce8a8d6281de70"
//                   },
//                   "name": {
//                       "type": "0x1::type_name::TypeName",
//                       "fields": {
//                           "name": "94cebd4b0f119af7fe75a64c040e832acfc5d96a676e6f1a98b039df58039c75::stwisp::STWISP"
//                       }
//                   },
//                   "value": "0"
//               }
//           }
//       }
//   },
//   {
//       "data": {
//           "objectId": "0x7cb6bf6df620999e9725c81901c561d5f37a92eac788ff05eb923a5248447dca",
//           "version": "10591994",
//           "digest": "6SDaG4Z4hpJQMGXiSfezKvCBsJNkjLynuDpP44uE8wsr",
//           "type": "0x2::dynamic_field::Field<0x1::type_name::TypeName, u64>",
//           "owner": {
//               "ObjectOwner": "0xc27b722cbc8ddb08e82b0e17d28c70b3ddd9d9577ea35b8548189e442dd2adcf"
//           },
//           "previousTransaction": "7tzAX6A1p1kn5rpe3uZDLvhRGvjG2Ztpwp81ynd77Bxu",
//           "storageRebate": "2401600",
//           "content": {
//               "dataType": "moveObject",
//               "type": "0x2::dynamic_field::Field<0x1::type_name::TypeName, u64>",
//               "hasPublicTransfer": false,
//               "fields": {
//                   "id": {
//                       "id": "0x7cb6bf6df620999e9725c81901c561d5f37a92eac788ff05eb923a5248447dca"
//                   },
//                   "name": {
//                       "type": "0x1::type_name::TypeName",
//                       "fields": {
//                           "name": "53358b5c79d0474e97f3ec7403cdec213c008db6b30552cd1a5a8624aee33d6a::stsui::STSUI"
//                       }
//                   },
//                   "value": "999988030000"
//               }
//           }
//       }
//   }
// ]
