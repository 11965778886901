import { createContext, Dispatch, ReactElement, ReactNode, useCallback, useContext, useMemo, useState } from "react";
import SwiperCore from "swiper";

interface Context {
  swiper: SwiperCore | null;
  setSwiper: Dispatch<React.SetStateAction<SwiperCore | null>>;
  destroySwiper: () => void;
}

const defaultValue: Context = {
  swiper: null,
  setSwiper: () => {},
  destroySwiper: () => {},
};

export const SwiperContext = createContext<Context>(defaultValue);

const SwiperProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);

  const destroySwiper = useCallback(() => {
    if (swiper) {
      swiper.destroy();
      setSwiper(null);
    }
  }, [swiper]);

  const providerValue = useMemo(() => ({ swiper, setSwiper, destroySwiper }), [swiper, destroySwiper]);

  return <SwiperContext.Provider value={providerValue}>{children}</SwiperContext.Provider>;
};

export const useSwiper = (): Context => {
  const swiperContext = useContext(SwiperContext);

  if (swiperContext === undefined) {
    throw new Error("Swiper not found");
  }

  return swiperContext;
};

export default SwiperProvider;
