import { Fragment, ReactElement, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import clsx from "clsx";
import { ChevronDownIcon } from "components/atoms/icons/ChevronDownIcon";
import { Link } from "react-router-dom";

type Direction = "From" | "To";

const OPTIONS = [
  {
    id: "eth",
    name: "Ethereum",
    image: "https://ethereum.org/static/4b5288012dc4b32ae7ff21fccac98de1/448ee/eth-diamond-black-gray.webp",
  },
];

const CHAINS = [
  {
    id: "celer",
    name: "Celer",
    coinImages: [
      "https://synapseprotocol.com/static/media/eth.328597820c3259f6c4149556e9c912f8.svg",
      "https://cryptologos.cc/logos/usd-coin-usdc-logo.png?v=018",
      "https://assets.coingecko.com/coins/images/4713/large/matic-token-icon.png",
      "https://assets.coingecko.com/coins/images/9576/large/BUSD.png",
      "https://assets.coingecko.com/coins/images/825/large/binance-coin-logo.png",
    ],
    link: "https://dev-cbridge-v2.netlify.app/",
  },
];

export function BridgeView(): ReactElement {
  const [selectedChain, setSelectedChain] = useState(OPTIONS[0]);
  const [direction, setDirection] = useState<Direction>("To");

  return (
    <div className="relative max-w-7xl w-full mx-auto z-10 md:mt-10 space-y-6">
      <div className="text-2xl sm:text-4xl font-extrabold text-center">SUI Bridges</div>
      <div className="flex items-center justify-center">
        <div className="relative gradient-box">
          <div className="flex items-center justify-center rounded-xl overflow-hidden">
            {["To", "From"].map((item) => {
              return (
                <div
                  className={clsx(
                    "flex items-center justify-center w-20 py-4 cursor-pointer",
                    direction === item ? "bg-dark-400" : "bg-dark-600 hover:bg-dark-500",
                  )}
                  key={item}
                  onClick={(): void => setDirection(item as Direction)}
                >
                  <span
                    className={
                      direction === item
                        ? "text-gradient !bg-clip-text text-transparent font-medium"
                        : "text-pNeutral-500"
                    }
                  >
                    {item}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="text-center text-3xl font-medium">SUI</div>
      <div className="text-center !mt-3">{direction === "From" ? "to" : "from"}</div>
      <div className="flex items-center justify-center">
        <Listbox
          as="div"
          className={clsx("w-[200px] py-2 text-base border border-white border-opacity-10 bg-dark-600 rounded-xl")}
          value={selectedChain}
          onChange={(val): void => setSelectedChain(val)}
        >
          <div className="relative">
            <Listbox.Button
              className={clsx("focus:outline-none flex w-full items-center justify-between rounded-xl px-5 py-2")}
            >
              <div className="flex items-center space-x-3">
                <img className="w-4 h-4 rounded-full" src={selectedChain.image} />
                <span className="block truncate">{selectedChain.name}</span>
              </div>
              <ChevronDownIcon className="text-gray-400 dark:text-gray-50" />
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={clsx(
                  "focus:outline-none bg-dark-500 absolute z-50 mt-2 w-full overflow-auto border border-white border-opacity-10 rounded-xl",
                )}
              >
                {OPTIONS.map((item) => (
                  <Listbox.Option
                    className={({ active }): string =>
                      clsx("cursor-pointer bg-opacity-100 px-5 py-4", {
                        "bg-dark-400": active,
                      })
                    }
                    key={item.id}
                    value={item}
                  >
                    {({ selected }): React.ReactElement => (
                      <div className="flex items-center space-x-3">
                        <img className="w-4 h-4 rounded-full" src={item.image} />
                        <span className={clsx("block truncate", selected ? "text-white" : "text-pNeutral-500")}>
                          {item.name}
                        </span>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
      <div className={clsx("grid gap-5", CHAINS.length === 1 ? "grid-cols-1 w-full max-w-md mx-auto" : "grid-cols-2")}>
        {CHAINS.map((chain) => {
          const Component = (
            <div className="rounded-xl bg-dark-600 hover:bg-dark-400 p-4">
              <div className="font-medium text-lg">{chain.name}</div>
              <div className="flex space-x-2 mt-2">
                {chain.coinImages.map((i) => (
                  <img className="w-4 h-4 rounded-full" key={i} src={i} />
                ))}
              </div>
            </div>
          );
          return chain.link.startsWith("https") ? (
            <a href={chain.link} key={chain.name} rel="noopener noreferrer" target="_blank">
              {Component}
            </a>
          ) : (
            <Link to={chain.link}>{Component}</Link>
          );
        })}
      </div>
    </div>
  );
}
