import { useMemo } from "react";
import { useWalletHelper } from "contexts/WalletHelperContext";
import { usePool } from "hooks/usePools";
import { Amount } from "sdk/entities/amount";
import { Coin } from "sdk/entities/coin";
import { Percent } from "sdk/entities/percent";
import { Pool } from "sdk/entities/pool";
import { Price } from "sdk/entities/price";
import { tryParseAmount } from "sdk/utils/tryParseAmount";
import { NetworkEnv } from "utils/constant";

import { Field, State } from "../reducers/liquidityReducer";

export function useLiquidityInfo(
  state: State,
  rpcEndpoint: string,
  networkEnv: NetworkEnv,
): {
  coins: { [field in Field]?: Coin };
  dependentField: Field;
  pool: Pool | null;
  balances: { [field in Field]?: Amount };
  parsedAmounts: { [field in Field]?: Amount };
  price?: Price;
  noLiquidity: boolean;
  liquidityMinted?: Amount;
  poolTokenPercentage?: Percent;
  error?: string;
  isFetching: boolean;
} {
  const { independentField, typedValue, otherTypedValue, coinA, coinB } = state;
  const { fungibleBalances } = useWalletHelper();
  const {
    pool,
    isFetching: isPoolFetching,
    error: fetchPoolError,
  } = usePool({ coinA: coinA ?? null, coinB: coinB ?? null, rpcEndpoint, networkEnv });

  const dependentField = independentField === Field.COIN_A ? Field.COIN_B : Field.COIN_A;

  // tokens
  const coins: { [field in Field]?: Coin } = useMemo(
    () => ({
      [Field.COIN_A]: coinA ?? undefined,
      [Field.COIN_B]: coinB ?? undefined,
    }),
    [coinA, coinB],
  );

  const noLiquidity: boolean =
    pool === null || pool.liquidity.quotient === 0n || (pool.reserve0.quotient === 0n && pool.reserve1.quotient === 0n);

  const [balanceA, balanceB]: [Amount | null, Amount | null] = useMemo(() => {
    let bA: Amount | null = null;
    let bB: Amount | null = null;
    if (coinA) {
      bA = fungibleBalances?.find((fb) => fb.coin.equals(coinA)) ?? null;
    }
    if (coinB) {
      bB = fungibleBalances?.find((fb) => fb.coin.equals(coinB)) ?? null;
    }
    return [bA, bB];
  }, [coinA, coinB, fungibleBalances]);

  const coinBalances: { [field in Field]?: Amount } = useMemo(() => {
    return {
      [Field.COIN_A]: balanceA ?? undefined,
      [Field.COIN_B]: balanceB ?? undefined,
    };
  }, [balanceA, balanceB]);

  // amounts
  const independentAmount: Amount | undefined = tryParseAmount(typedValue, coins[independentField]);
  const dependentAmount: Amount | undefined = useMemo(() => {
    if (noLiquidity) {
      if (otherTypedValue && coins[dependentField]) {
        return tryParseAmount(otherTypedValue, coins[dependentField]);
      }
      return undefined;
    } else if (independentAmount) {
      if (coinA && coinB && independentAmount && pool) {
        const dependentAmount =
          dependentField === Field.COIN_B
            ? pool.priceOf(coinA).quote(independentAmount)
            : pool.priceOf(coinB).quote(independentAmount);
        return dependentAmount;
      }
      return undefined;
    } else {
      return undefined;
    }
  }, [noLiquidity, otherTypedValue, coins, dependentField, independentAmount, coinA, coinB, pool]);

  const parsedAmounts: { [field in Field]: Amount | undefined } = useMemo(() => {
    return {
      [Field.COIN_A]: independentField === Field.COIN_A ? independentAmount : dependentAmount,
      [Field.COIN_B]: independentField === Field.COIN_A ? dependentAmount : independentAmount,
    };
  }, [dependentAmount, independentAmount, independentField]);

  const price = useMemo(() => {
    if (noLiquidity) {
      const { [Field.COIN_A]: coinAAmount, [Field.COIN_B]: coinBAmount } = parsedAmounts;
      if (coinAAmount?.greaterThan(0) && coinBAmount?.greaterThan(0)) {
        const value = coinBAmount.divide(coinAAmount);
        return new Price(coinAAmount.coin, coinBAmount.coin, value.denominator, value.numerator);
      }
      return undefined;
    } else {
      return pool && coinA ? pool.priceOf(coinA) : undefined;
    }
  }, [coinA, noLiquidity, pool, parsedAmounts]);

  // liquidity minted
  const liquidityMinted = useMemo(() => {
    const { [Field.COIN_A]: coinAAmount, [Field.COIN_B]: coinBAmount } = parsedAmounts;
    const [tokenAmountA, tokenAmountB] = [coinAAmount, coinBAmount];
    if (pool && tokenAmountA && tokenAmountB) {
      try {
        return pool.getLiquidityMinted(tokenAmountA, tokenAmountB);
      } catch (error) {
        console.error(error);
        return undefined;
      }
    } else {
      return undefined;
    }
  }, [parsedAmounts, pool]);

  const poolTokenPercentage = useMemo(() => {
    if (liquidityMinted && pool) {
      return new Percent(liquidityMinted.quotient, pool.liquidity.add(liquidityMinted).quotient);
    } else {
      return undefined;
    }
  }, [liquidityMinted, pool]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let error: string | undefined = (fetchPoolError as any)?.toString();

  if (!parsedAmounts[Field.COIN_A] || !parsedAmounts[Field.COIN_B]) {
    error = error ?? "Enter an amount";
  }

  const { [Field.COIN_A]: coinAAmount, [Field.COIN_B]: coinBAmount } = parsedAmounts;

  if (coinAAmount && (!coinBalances[Field.COIN_A] || coinBalances[Field.COIN_A]?.lessThan(coinAAmount))) {
    error = `Insufficient ${coins[Field.COIN_A]?.name} balance`;
  }

  if (coinBAmount && (!coinBalances[Field.COIN_B] || coinBalances[Field.COIN_B]?.lessThan(coinBAmount))) {
    error = `Insufficient ${coins[Field.COIN_B]?.name} balance`;
  }

  return {
    dependentField,
    coins,
    pool,
    balances: coinBalances,
    parsedAmounts,
    price,
    noLiquidity,
    liquidityMinted,
    poolTokenPercentage,
    error,
    isFetching: isPoolFetching,
  };
}
