import { useEffect, useState } from "react";

const getReturnValues = (countDown: number): [number, number] => {
  if (countDown <= 0) {
    return [0, 0];
  }
  const minutes = Math.floor((countDown % 3_600_000) / 60_000);
  const seconds = Math.floor((countDown % 60_000) / 1_000);
  return [minutes, seconds];
};

export function useCountDown(date: Date): [number, number] {
  const [countDown, setCountDown] = useState(date.getTime() - Date.now());

  useEffect(() => {
    const nextState = date.getTime() - Date.now();
    if (nextState > 0) {
      setTimeout(() => {
        setCountDown(nextState);
      }, 1000);
    }
  }, [date, countDown]);

  return getReturnValues(countDown);
}
