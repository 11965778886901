import { ReactElement, useEffect, useMemo, useState } from "react";
import { useWallet } from "@suiet/wallet-kit";
import { Button } from "components/atoms/Button";
import { Modal } from "components/atoms/Modal";
import { useWalletHelper } from "contexts/WalletHelperContext";
import moment from "moment";
import { Amount } from "sdk/entities/amount";
import { toCommaSeparated } from "sdk/utils/formatNumber";
import { isTooManyDecimals, tryParseAmount } from "sdk/utils/tryParseAmount";
import { FarmWithNft } from "types/farm";
import { VeWISP } from "utils/coins";

import { AssetInput } from "./AssetInput";

type Props = {
  farm: FarmWithNft | undefined;
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onUnboostClick: (amount: Amount, farm: FarmWithNft) => void;
};

export function UnboostModal({ farm, isOpen, isLoading, onClose, onUnboostClick }: Props): ReactElement {
  const { adapter } = useWallet();
  const { setOpenSelectWallet } = useWalletHelper();
  const [typedValue, setTypedValue] = useState("");

  useEffect(() => {
    return () => {
      if (!isOpen) {
        setTypedValue("");
      }
    };
  }, [isOpen]);

  const veWispBalance = useMemo(() => {
    if (!farm) {
      return undefined;
    }
    return farm.spNft?.boostBalance;
  }, [farm]);

  const amount = useMemo(() => {
    if (!typedValue) {
      return undefined;
    }
    return tryParseAmount(typedValue, VeWISP);
  }, [typedValue, farm?.stakingCoin]);

  const errorMsg: string | null = useMemo(() => {
    if (!veWispBalance || veWispBalance.equalTo(0)) {
      return "Empty balance";
    }
    if (!amount) {
      return "Enter an amount";
    }
    if (veWispBalance?.lessThan(amount)) {
      return "Insufficient balance";
    }
    return null;
  }, [amount, veWispBalance]);

  const handleChangeInput = (value: string): void => {
    if (isTooManyDecimals(value, VeWISP)) {
      return;
    }
    setTypedValue(value);
  };

  const handleMaxInput = (): void => {
    if (!veWispBalance) {
      return;
    }
    setTypedValue(veWispBalance.toExact({ groupSeparator: "" }));
  };

  const handleUnboostClick = (): void => {
    if (!farm || !amount || !amount.greaterThan(0)) {
      return;
    }
    onUnboostClick(amount, farm);
  };

  return (
    <Modal isOpen={isOpen} title="Unboost Modal" onClose={onClose}>
      <div className="space-y-4">
        <div>Enter veWisp amount you want to unboost</div>
        <AssetInput
          asset={veWispBalance?.coin}
          balance={veWispBalance}
          label="Amount"
          otherAsset={undefined}
          value={toCommaSeparated(typedValue)}
          fixedCoin
          maxAmount
          onInputChange={handleChangeInput}
          onMaxAmount={handleMaxInput}
          onSelect={(): void => {}}
        />
        {!!farm?.spNft?.unlockTime && (
          <div className="rounded-lg p-4 text-red-50 bg-red-900">
            You're locking your veWisp. If you unboost your veWisp, you will loose all your rewards. Your unboost time
            is {moment(farm?.spNft.unlockTime).format("MMMM Do YYYY, h:mm:ss a")}.
          </div>
        )}
        <div>
          {!adapter ? (
            <Button
              className="px-6 py-3 w-full text-xl font-Poppins whitespace-pre-wrap"
              onClick={(): void => setOpenSelectWallet(true)}
            >
              Connect Wallet
            </Button>
          ) : (
            <Button
              className="px-6 py-3 w-full text-xl font-Poppins whitespace-pre-wrap"
              disabled={Boolean(errorMsg)}
              isLoading={isLoading}
              onClick={handleUnboostClick}
            >
              {errorMsg ?? (isLoading ? "Processing" : "Unboost")}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}
