import { ReactElement, useMemo } from "react";
import { MultipleAssetLogo } from "components/atoms/MultipleAssetLogo";
import { useWalletHelper } from "contexts/WalletHelperContext";
import { useWispSettings } from "contexts/WispSettingsContext";
import { usePools } from "hooks/usePools";
import { Link } from "react-router-dom";
import { Coin } from "sdk/entities/coin";
import { Percent } from "sdk/entities/percent";
import { trimZeroes } from "sdk/utils/trimZeros";
import { PATH, WISP_CONFIG } from "utils/constant";

export function MyLiquidity(): ReactElement {
  const { lpBalances } = useWalletHelper();
  const { settings } = useWispSettings();

  const coinPairInputs: [Coin, Coin][] = useMemo(() => {
    const wispLP = (lpBalances ?? []).filter(
      (b) => !!b.coin.coinA && !!b.coin.coinB && b.coin.packageObject === WISP_CONFIG.dex.swapPackage,
    );
    return wispLP.map((w) => [w.coin.coinA, w.coin.coinB]) as [Coin, Coin][];
  }, [lpBalances]);

  const { pools } = usePools({
    coinPairs: coinPairInputs,
    networkEnv: settings.networkEnv,
    rpcEndpoint: settings.customRPC,
  });

  return (
    <div className="mt-6">
      <div className="grid grid-cols-4 gap-3 border-b border-white border-opacity-10 text-pNeutral-500 uppercase pb-2 px-3 font-medium">
        <div>Pool</div>
        <div className="text-right">LP Amount</div>
        <div className="text-right">Pool Share</div>
        <div />
      </div>
      <div>
        {pools?.map((pool) => {
          const lpBalance = lpBalances?.find(
            (lp) =>
              (lp.coin.coinA?.equals(pool.coin0) && lp.coin.coinB?.equals(pool.coin1)) ||
              (lp.coin.coinA?.equals(pool.coin1) && lp.coin.coinB?.equals(pool.coin0)),
          );
          const poolPercentage: Percent | undefined = lpBalance
            ? new Percent(lpBalance.quotient, pool.liquidity.quotient)
            : undefined;
          return (
            <div
              className="py-2 md:py-4 grid grid-cols-4 gap-3 hover:bg-dark-400 rounded-md cursor-pointer px-3"
              key={pool.type}
            >
              <div className="flex flex-col md:flex-row md:items-center md:space-x-2">
                <MultipleAssetLogo assets={[pool.coin0, pool.coin1]} />
                <div className="text-pNeutral-800 font-medium">{pool.toString()}</div>
              </div>
              <div className="font-medium text-right break-all flex items-center justify-end">
                {lpBalance?.toExact()}
              </div>
              <div className="font-medium text-right break-all flex items-center justify-end">
                {poolPercentage
                  ? poolPercentage.toFixed(4) === "0.0000"
                    ? "<0.0001"
                    : `${trimZeroes(poolPercentage.toFixed(4))}%`
                  : "-"}
              </div>
              <div className="flex flex-col md:flex-row justify-center items-center md:justify-end md:space-x-4 font-semibold">
                <Link to={`${PATH.ADD_LIQUIDITY}?coinA=${pool.coin0.type}&coinB=${pool.coin1.type}`}>
                  <div className="cursor-pointer text-pGreen-500 hover:underline">Add</div>
                </Link>
                <Link to={`${PATH.REMOVE_LIQUIDITY}?coinA=${pool.coin0.type}&coinB=${pool.coin1.type}`}>
                  <div className="cursor-pointer text-pRed-500 hover:underline">Remove</div>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
