export enum ActionType {
  TYPE_INPUT = "TYPE_INPUT",
  SET_BET_TYPE = "SET_BET_TYPE",
  RESET_FORM = "RESET_FORM",
}

export enum BET_TYPE {
  BEAR = "BEAR",
  BULL = "BULL",
}

type TypeInputAction = {
  type: ActionType.TYPE_INPUT;
  typedValue: string;
};

type SetBetAction = {
  type: ActionType.SET_BET_TYPE;
  betType: BET_TYPE | null;
};

type ResetFormAction = {
  type: ActionType.RESET_FORM;
};

export type Action = TypeInputAction | SetBetAction | ResetFormAction;
