import { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import LiquidityAPI from "api/liquidity";
import clsx from "clsx";
import { Button } from "components/atoms/Button";
import { PlusIcon } from "components/atoms/icons/PlusIcon";
import { SearchIcon } from "components/atoms/icons/SearchIcon";
import { MultipleAssetLogo } from "components/atoms/MultipleAssetLogo";
import { Spinner } from "components/atoms/Spinner";
import { useWispSettings } from "contexts/WispSettingsContext";
import useDebounce from "hooks/useDebounce";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Pool } from "sdk/entities/pool";
import { intlFormatNumber } from "sdk/utils/formatNumber";
import { PATH } from "utils/constant";
import { parsePool } from "utils/parsePool";

import { MyLiquidity } from "./MyLiquidity";

import "./style.css";

export enum PoolTab {
  ALL = "all",
  MY_POOL = "myPool",
}

const defaultPageInfo = {
  current: 1,
  totalPage: 1,
  totalItems: 0,
};

function PoolsTable(): ReactElement {
  const { settings } = useWispSettings();
  const [tab, setTab] = useState<PoolTab>(PoolTab.ALL);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const [pools, setPools] = useState<Pool[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const debouncedSearch = useDebounce(searchTerm, 500);
  const lastSearchTerm = useRef("");

  const fetchPoolData = useCallback(
    async (search: string, currPage: number) => {
      try {
        setIsFetching(true);
        const resp = await LiquidityAPI.getAllPools(settings.networkEnv, search, currPage);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const pools = (resp.data.pools ?? []).map(parsePool);
        const page = resp.data?.pagination?.page ?? 1;
        const totalItems = resp.data?.pagination?.totalItems ?? 0;
        const totalPage = totalItems % 10 === 0 ? Math.floor(totalItems / 10) : Math.floor(totalItems / 10) + 1;
        setPageInfo({
          current: page,
          totalPage: totalPage,
          totalItems: totalItems,
        });
        setPools(pools);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error(err);
        toast.error(err.message ?? "Fetching pools error");
      } finally {
        setIsFetching(false);
      }
    },
    [settings.networkEnv],
  );

  useEffect(() => {
    fetchPoolData(debouncedSearch, lastSearchTerm.current !== debouncedSearch ? 1 : pageInfo.current);
    lastSearchTerm.current = debouncedSearch;
  }, [pageInfo.current, debouncedSearch, fetchPoolData]);

  const onClickTab = (value: PoolTab): void => {
    setTab(value);
  };

  const handleIncreasePage = (): void => {
    if (pageInfo.current < pageInfo.totalPage) {
      setPageInfo((prev) => ({ ...prev, current: prev.current + 1 }));
    }
  };

  const handleDecreasePage = (): void => {
    if (pageInfo.current > 1) {
      setPageInfo((prev) => ({ ...prev, current: prev.current - 1 }));
    }
  };

  const handleChangeSearch = (value: string): void => {
    setSearchTerm(value);
  };

  return (
    <div className="bg-dark-600 p-4 md:p-8 rounded-2xl">
      <div className="flex justify-between items-center md:pb-4 space-x-9">
        <div className="text-base px-3 sm:text-xl font-bold flex justify-start items-center space-x-6 w-fit lg:w-auto">
          <div
            className={clsx("whitespace-nowrap cursor-pointer", tab !== PoolTab.ALL && "text-gray-600")}
            onClick={(): void => onClickTab(PoolTab.ALL)}
          >
            All
          </div>
          <div
            className={clsx("whitespace-nowrap cursor-pointer", tab !== PoolTab.MY_POOL && "text-gray-600")}
            onClick={(): void => onClickTab(PoolTab.MY_POOL)}
          >
            My Liquidity
          </div>
        </div>
        <div className="flex items-center space-x-5">
          {tab === PoolTab.ALL && (
            <div className="hidden sm:flex px-3 py-2 rounded-lg items-center space-x-3 border border-white border-opacity-10 w-full">
              <SearchIcon />
              <input
                className="flex-1 bg-transparent w-full"
                placeholder="Search pool..."
                type="text"
                value={searchTerm}
                onChange={(e): void => handleChangeSearch(e.target.value)}
              />
            </div>
          )}
          <Link className="shrink-0" to={PATH.ADD_LIQUIDITY}>
            <Button className="px-2 md:px-4 py-2 font-medium w-full whitespace-nowrap">
              <PlusIcon className="w-5 h-5" />
              <span className="hidden md:block">Add Liquidity</span>
            </Button>
          </Link>
        </div>
      </div>
      {tab === PoolTab.ALL && (
        <div className="mt-3 sm:hidden flex p-2 rounded-lg items-center space-x-2 border border-white border-opacity-10 w-full">
          <SearchIcon />
          <input
            className="flex-1 bg-transparent w-full"
            placeholder="Search pool..."
            type="text"
            value={searchTerm}
            onChange={(e): void => handleChangeSearch(e.target.value)}
          />
        </div>
      )}
      {tab === PoolTab.ALL && (
        <div className="mt-6">
          <div className="grid grid-cols-[1.5fr,1fr] sm:grid-cols-[2.5fr,1fr,1fr] md:grid-cols-[2.5fr,1fr,1fr,1fr] gap-3 border-b border-white border-opacity-30 text-pNeutral-500 pb-2 px-3 font-medium">
            <div>Pool</div>
            <div className="hidden sm:block text-right">TVL</div>
            <div className="text-right">Volume 24H (SUI)</div>
            <div className="hidden md:block text-right">Volume 7D (SUI)</div>
          </div>
          <div className="divide-y divide-white divide-opacity-5 !text-sm md:!text-base border-b border-white border-opacity-5">
            {isFetching ? (
              <div className="flex items-center justify-center h-[610px] md:h-[570px]">
                <Spinner className="w-8 h-8" />
              </div>
            ) : (
              pools?.map((pool) => {
                return (
                  <Link
                    className="block"
                    key={pool.type}
                    to={`/pools/add-liquidity?coinA=${pool.coin0.type}&coinB=${pool.coin1.type}`}
                  >
                    <div className="py-2.5 md:py-4 grid grid-cols-[1.5fr,1fr] sm:grid-cols-[2.5fr,1fr,1fr] md:grid-cols-[2.5fr,1fr,1fr,1fr] gap-3 hover:bg-dark-400 cursor-pointer px-3">
                      <div className="flex items-center space-x-2 md:space-x-4">
                        <MultipleAssetLogo assets={[pool.coin0, pool.coin1]} itemCls="!w-6 !h-6" />
                        <div className="text-pNeutral-800 font-medium">{pool.toString()}</div>
                        <div className="bg-neutral-600 rounded-md px-1 py-0.5 text-xs">0.3%</div>
                      </div>
                      <div className="hidden sm:flex text-right items-center justify-end lowercase">
                        <span>{intlFormatNumber(pool.reserve0.multiply(2).toExactNumber())}</span>
                        &nbsp;
                        <span className="uppercase">{pool.coin0.name}</span>
                      </div>
                      <div className="text-right flex items-center justify-end lowercase">
                        {intlFormatNumber(pool.volume24h?.toExactNumber())}
                      </div>
                      <div className="hidden md:flex text-right items-center justify-end lowercase">{"--"}</div>
                    </div>
                  </Link>
                );
              })
            )}
          </div>
          {pageInfo.totalPage > 1 ? (
            <div className="flex items-center justify-center space-x-4 mt-4">
              <span
                className={clsx(
                  "text-sm block",
                  pageInfo.current <= 1 ? "text-gray-600 cursor-not-allowed" : "cursor-pointer text-pGreen-500",
                )}
                onClick={handleDecreasePage}
              >
                ←
              </span>
              <span className="text-sm text-pNeutral-500">
                Page {pageInfo.current} of {pageInfo.totalPage}
              </span>
              <span
                className={clsx(
                  "text-sm block",
                  pageInfo.current >= pageInfo.totalPage
                    ? "text-gray-600 cursor-not-allowed"
                    : "cursor-pointer text-pGreen-500",
                )}
                onClick={handleIncreasePage}
              >
                →
              </span>
            </div>
          ) : null}
        </div>
      )}
      {tab === PoolTab.MY_POOL && <MyLiquidity />}
    </div>
  );
}

export default PoolsTable;
