import { Coin } from "sdk/entities/coin";

import { Field } from "./reducers";

export enum ActionType {
  TYPE_INPUT = "TYPE_INPUT",
  SET_ASSET = "SET_ASSET",
  CLEAR_FORM = "CLEAR_FORM",
}

type TypeInputAction = {
  type: ActionType.TYPE_INPUT;
  field: Field;
  typedValue: string;
};

type SetCoinAction = {
  type: ActionType.SET_ASSET;
  assetA: Coin | undefined;
  assetB: Coin | undefined;
};

type ClearFormAction = {
  type: ActionType.CLEAR_FORM;
};

export type Action = TypeInputAction | SetCoinAction | ClearFormAction;
