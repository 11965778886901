import * as React from "react";

import { IconProps } from "./type";

export function NoteIcon(props: IconProps): React.ReactElement<IconProps> {
  return (
    <svg fill="none" height="15" viewBox="0 0 14 15" width="14" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.99992 14.1668C3.31792 14.1668 0.333252 11.1822 0.333252 7.50016C0.333252 3.81816 3.31792 0.833496 6.99992 0.833496C10.6819 0.833496 13.6666 3.81816 13.6666 7.50016C13.6666 11.1822 10.6819 14.1668 6.99992 14.1668ZM6.99992 12.8335C8.41441 12.8335 9.77096 12.2716 10.7712 11.2714C11.7713 10.2712 12.3333 8.91465 12.3333 7.50016C12.3333 6.08567 11.7713 4.72912 10.7712 3.72893C9.77096 2.72873 8.41441 2.16683 6.99992 2.16683C5.58543 2.16683 4.22888 2.72873 3.22868 3.72893C2.22849 4.72912 1.66659 6.08567 1.66659 7.50016C1.66659 8.91465 2.22849 10.2712 3.22868 11.2714C4.22888 12.2716 5.58543 12.8335 6.99992 12.8335ZM6.33325 9.50016H7.66658V10.8335H6.33325V9.50016ZM7.66658 8.4035V8.8335H6.33325V7.8335C6.33325 7.65668 6.40349 7.48712 6.52851 7.36209C6.65354 7.23707 6.82311 7.16683 6.99992 7.16683C7.1893 7.16682 7.37479 7.11303 7.5348 7.01172C7.69481 6.9104 7.82276 6.76574 7.90377 6.59455C7.98477 6.42336 8.0155 6.23269 7.99237 6.04472C7.96925 5.85676 7.89322 5.67922 7.77314 5.53277C7.65306 5.38632 7.49386 5.27698 7.31407 5.21748C7.13427 5.15797 6.94128 5.15074 6.75753 5.19663C6.57379 5.24252 6.40686 5.33965 6.27616 5.47671C6.14546 5.61376 6.05637 5.78512 6.01925 5.97083L4.71125 5.70883C4.79234 5.30355 4.97959 4.92708 5.25387 4.61789C5.52814 4.3087 5.8796 4.07788 6.27231 3.94904C6.66503 3.8202 7.08491 3.79795 7.48904 3.88457C7.89317 3.97119 8.26705 4.16357 8.57246 4.44205C8.87788 4.72052 9.10386 5.0751 9.22733 5.46954C9.35079 5.86398 9.3673 6.28413 9.27516 6.68703C9.18303 7.08994 8.98555 7.46116 8.70293 7.76274C8.42031 8.06432 8.06267 8.28544 7.66658 8.4035Z"
        fill="currentColor"
      />
    </svg>
  );
}
