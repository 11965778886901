import { ReactElement } from "react";

import { LaterPayoutCardContent } from "./components/LaterPayoutCardContent";
import { NextPayoutCardContent } from "./components/NextPayoutCardContent";
import { NormalPayoutCardContent } from "./components/NormalPayoutCardContent";
import { ROUND_STATUS } from "./constant";
import { Round } from "./types";
type Props = {
  round: Round;
  className?: string;
};

export function RoundCard({ round, className }: Props): ReactElement {
  return round.status === ROUND_STATUS.NEXT ? (
    <NextPayoutCardContent round={round} />
  ) : round.status === ROUND_STATUS.LATER ? (
    <LaterPayoutCardContent round={round} />
  ) : (
    <NormalPayoutCardContent className={className} round={round} />
  );
}
