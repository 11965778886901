import { createElement, FC, ReactElement, useState } from "react";
import clsx from "clsx";
import { Tooltip, TooltipContent } from "components/atoms/Tooltip";
import { TooltipButton } from "components/atoms/Tooltip/TooltipButton";
import { Wallet } from "components/molecules/Wallet";
import { Link, useLocation } from "react-router-dom";
import { LANDING_PAGE, NAVIGATION_ITEMS, NavigationItem, PATH } from "utils/constant";

import { MenuIcon } from "./icons/MenuIcon";

type NavItemProps = {
  item: NavigationItem;
  isActive: boolean;
  children?: NavigationItem[];
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  cls?: string;
  icon?: FC<{ className?: string }>;
};

function NavItem({ item, isActive, cls, icon, children, onClick }: NavItemProps): ReactElement {
  if (children) {
    return (
      <div className="group w-full">
        <div
          className={clsx(
            "cursor-pointer font-semibold p-6 relative flex items-center space-x-1",
            cls,
            isActive
              ? "text-pGreen-500 bg-dark-500 shadow-nav after:absolute after:inset-x-0 after:bottom-0 after:h-0.5 after:bg-pGreen-500"
              : "text-pNeutral-800 hover:after:absolute hover:after:inset-x-0 hover:after:bottom-0 hover:after:h-0.5 hover:after:bg-pGreen-500",
          )}
        >
          <span>{item.title}</span>
        </div>
        <div
          className={clsx(
            "w-[200px] rounded-md hidden relative lg:absolute group-hover:block bg-dark-500 py-2 border border-white border-opacity-10",
          )}
        >
          {children.map((e) => {
            return (
              <Link
                className={clsx(
                  "px-6 py-3 text-pNeutral-500 font-semibold relative block",
                  "hover:after:absolute hover:after:inset-x-5 hover:after:bottom-0 hover:after:h-0.5 hover:after:bg-pGreen-500",
                )}
                key={e.title}
                to={e.path ?? "/"}
                onClick={onClick}
              >
                {e.title}
              </Link>
            );
          })}
        </div>
      </div>
    );
  }
  return (
    <>
      {item.path ? (
        <Link
          className={clsx(
            "font-semibold p-6 relative flex items-center space-x-1 shrink-0",
            cls,
            isActive
              ? "text-pGreen-500 bg-dark-500 shadow-nav after:absolute after:inset-x-0 after:bottom-0 after:h-0.5 after:bg-pGreen-500"
              : "text-pNeutral-800 hover:after:absolute hover:after:inset-x-0 hover:after:bottom-0 hover:after:h-0.5 hover:after:bg-pGreen-500",
          )}
          to={item.path}
          onClick={onClick}
        >
          <span>{item.title}</span>
          {icon && createElement(icon, { className: "w-5 h-5" })}
        </Link>
      ) : (
        <div className="w-full font-semibold p-6 relative cursor-pointer">
          <Tooltip>
            <TooltipButton>
              <div className="text-dark-100 opacity-50">{item.title}</div>
            </TooltipButton>
            <TooltipContent>
              <div>Coming soon!</div>
            </TooltipContent>
          </Tooltip>
        </div>
      )}
    </>
  );
}

function Header(): ReactElement {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = (): void => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="fixed inset-x-0 top-0 z-10 flex justify-between items-center bg-dark-600 bg-opacity-60 px-10 space-x-10 ">
      <div className="inline-block shrink-0 py-4">
        <a href={LANDING_PAGE} rel="noopener noreferrer" target="_blank">
          <img alt="wispswap logo" className="w-36 h-10" src="/images/logo.svg" />
        </a>
      </div>
      <div className="hidden xl:flex items-center space-x-5">
        {NAVIGATION_ITEMS.map((item: NavigationItem) => (
          <NavItem
            children={item.children}
            icon={item.icon}
            isActive={
              (item.path === PATH.TRADE && location.pathname === PATH.TRADE) ||
              (item.path !== PATH.TRADE && !!item.path && location.pathname.startsWith(item.path)) ||
              (item.path !== PATH.TRADE &&
                !!item.children &&
                item.children.some((item) => !!item.path && location.pathname.startsWith(item.path)))
            }
            item={item}
            key={item.title}
          />
        ))}
      </div>
      <div
        className={clsx(
          " w-screen h-screen absolute top-[4.5rem] right-0 bg-transparent ",
          isOpen ? "block" : "hidden",
        )}
        onClick={toggleMenu}
      >
        <div
          className={clsx("flex flex-col items-center w-4/5  bg-opacity-100 bg-dark-500 p-4 h-full ml-auto")}
          onClick={(event: React.MouseEvent<HTMLElement>): void => {
            event?.stopPropagation();
          }}
        >
          <div className="w-full my-5">
            <Wallet cls="w-full" />
          </div>
          {NAVIGATION_ITEMS.map((item: NavigationItem) => (
            <NavItem
              children={item.children}
              cls="w-full"
              icon={item.icon}
              isActive={
                (item.path === "/" && location.pathname === "/") ||
                (item.path !== "/" && !!item.path && location.pathname.startsWith(item.path)) ||
                (item.path !== PATH.TRADE &&
                  !!item.children &&
                  item.children.some((item) => !!item.path && location.pathname.startsWith(item.path)))
              }
              item={item}
              key={item.title}
              onClick={toggleMenu}
            />
          ))}
        </div>
      </div>

      <div className="flex items-center justify-end space-x-2">
        <div className="flex justify-center">
          <Link className="p-2 cursor-pointer hover:bg-dark-600 rounded-md" to={PATH.WISPPOINT}>
            <img className="w-6 h-6" src="/images/medal.png" />
          </Link>
        </div>
        <div className="shrink-0 text-white  gap-3 hidden xl:flex">
          <Wallet />
        </div>
        <button className={clsx("inline xl:hidden")} onClick={toggleMenu}>
          <div className="rounded-lg h-10 flex justify-center items-center w-10 hover:bg-mainBg">
            {isOpen ? (
              <div className="w-0 h-0 border-t-8 border-b-8 border-r-8 border-r-pGreen-400 border-t-transparent border-b-transparent"></div>
            ) : (
              <MenuIcon className="text-pGreen-400" />
            )}
          </div>
        </button>
      </div>
    </div>
  );
}

export default Header;
