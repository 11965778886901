import { Action, ActionType, BET_TYPE } from "./actions";

export type State = {
  readonly betType: BET_TYPE | null;
  readonly typedValue: string;
};

export const initialState: State = {
  betType: null,
  typedValue: "",
};

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.TYPE_INPUT: {
      return {
        ...state,
        typedValue: action.typedValue,
      };
    }

    case ActionType.SET_BET_TYPE: {
      return {
        ...state,
        betType: action.betType,
      };
    }

    case ActionType.RESET_FORM: {
      return { ...initialState };
    }
  }
}
