import { AxiosResponse } from "axios";
import { NetworkEnv } from "utils/constant";

import http from "./http";

type Param =
  | {
      amount: string;
      tokenAType: string;
      tokenBType: string;
    }
  | {
      amount: string;
      tokenType: string;
    };

function getDynamicFee(networkEnv: NetworkEnv, params: Param): Promise<AxiosResponse> {
  return http[networkEnv].get("/lsdfi/dynamicObject", {
    params: {
      ...params,
    },
  });
}

const LsdFiAPI = {
  getDynamicFee,
};

export default LsdFiAPI;
