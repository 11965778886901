import { ReactElement } from "react";

import { IconProps } from "./type";

export function LiveStatusIcon(props: IconProps): ReactElement<IconProps> {
  return (
    <svg fill="none" height="21" viewBox="0 0 20 21" width="20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.0003 18.8337C5.39783 18.8337 1.66699 15.1028 1.66699 10.5003C1.66699 5.89783 5.39783 2.16699 10.0003 2.16699C14.6028 2.16699 18.3337 5.89783 18.3337 10.5003C18.3337 15.1028 14.6028 18.8337 10.0003 18.8337ZM10.0003 17.167C11.7684 17.167 13.4641 16.4646 14.7144 15.2144C15.9646 13.9641 16.667 12.2684 16.667 10.5003C16.667 8.73222 15.9646 7.03652 14.7144 5.78628C13.4641 4.53604 11.7684 3.83366 10.0003 3.83366C8.23222 3.83366 6.53652 4.53604 5.28628 5.78628C4.03604 7.03652 3.33366 8.73222 3.33366 10.5003C3.33366 12.2684 4.03604 13.9641 5.28628 15.2144C6.53652 16.4646 8.23222 17.167 10.0003 17.167ZM8.85199 7.51283L12.9178 10.2228C12.9635 10.2533 13.001 10.2945 13.027 10.3429C13.0529 10.3913 13.0665 10.4454 13.0665 10.5003C13.0665 10.5552 13.0529 10.6093 13.027 10.6577C13.001 10.7061 12.9635 10.7474 12.9178 10.7778L8.85116 13.4878C8.80102 13.5211 8.74281 13.5401 8.68272 13.543C8.62263 13.5458 8.56289 13.5323 8.50983 13.504C8.45678 13.4756 8.41239 13.4334 8.38137 13.3818C8.35036 13.3303 8.33387 13.2713 8.33366 13.2112V7.78949C8.33377 7.7292 8.35023 7.67007 8.38129 7.61839C8.41235 7.56672 8.45684 7.52443 8.51003 7.49604C8.56322 7.46765 8.62311 7.45422 8.68333 7.45718C8.74355 7.46013 8.80184 7.47936 8.85199 7.51283Z"
        fill="currentColor"
      />
    </svg>
  );
}
