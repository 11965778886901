import * as React from "react";
import { Amount } from "sdk/entities/amount";
import { Coin } from "sdk/entities/coin";
import { InsufficientInputAmountError } from "sdk/entities/error";
import { Pool } from "sdk/entities/pool";
import { Trade } from "sdk/entities/trade";

export function useTradeExactIn(
  pool: Pool | null,
  coinAmountIn?: Amount,
  coinOut?: Coin,
): {
  trade: Trade | null;
  amountTooSmall: boolean;
} {
  return React.useMemo(() => {
    if (coinAmountIn && coinOut && pool) {
      let amountTooSmall = false;
      let bestTrade: Trade | null = null;
      try {
        bestTrade = Trade.bestTradeExactIn([pool], coinAmountIn, coinOut, { maxHops: 1, maxNumResults: 1 })[0] ?? null;
      } catch (error) {
        if (error instanceof InsufficientInputAmountError) {
          amountTooSmall = true;
        } else {
          throw error;
        }
      }
      return {
        trade: bestTrade,
        amountTooSmall,
      };
    }

    return { trade: null, amountTooSmall: false };
  }, [pool, coinAmountIn, coinOut]);
}

export function useTradeExactOut(pool: Pool | null, coinIn?: Coin, coinAmountOut?: Amount): { trade: Trade | null } {
  return React.useMemo(() => {
    if (coinIn && coinAmountOut && pool) {
      return {
        trade: Trade.bestTradeExactOut([pool], coinIn, coinAmountOut, { maxHops: 1, maxNumResults: 1 })[0] ?? null,
      };
    }
    return { trade: null };
  }, [coinIn, coinAmountOut, pool]);
}
