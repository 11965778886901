import { Coin } from "sdk/entities/coin";
import { Pool } from "sdk/entities/pool";

import { Action, ActionType } from "./actions";

export enum Field {
  ASSET = "ASSET",
  POOL = "POOL",
}

export type State = {
  readonly asset?: Coin;
  readonly pool?: Pool;
  readonly typedValue: string;
  readonly isRateReverted: boolean;
};

export const initialState: State = {
  asset: undefined,
  pool: undefined,
  typedValue: "",
  isRateReverted: false,
};

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.TYPE_INPUT: {
      return {
        ...state,
        typedValue: action.typedValue,
      };
    }

    case ActionType.SET_ASSET: {
      return {
        ...state,
        asset: action.coin,
        pool: initialState.pool,
        typedValue: initialState.typedValue,
      };
    }

    case ActionType.SET_POOL: {
      return {
        ...state,
        pool: action.pool,
      };
    }

    case ActionType.RESET_FORM: {
      return { ...initialState };
    }

    case ActionType.REVERT_STATE: {
      return { ...state, isRateReverted: !state.isRateReverted };
    }
  }
}
