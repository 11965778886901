import * as React from "react";

import { IconProps } from "./type";

export function ConvertIcon(props: IconProps): React.ReactElement<IconProps> {
  return (
    <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="16" cy="16" r="15" stroke="currentColor" strokeWidth="2" />
      <path
        d="M15.9112 13.1557H13.0674L13.0667 21.689H11.6445V13.1557H8.80005L12.3556 9.6001L15.9112 13.1557ZM23.0223 18.8445L19.4667 22.4001L15.9112 18.8445H18.7556V10.3112H20.1778V18.8445H23.0223Z"
        fill="currentColor"
      />
    </svg>
  );
}
