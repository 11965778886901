import { Coin } from "sdk/entities/coin";

export enum ActionType {
  SELECT_COIN = "SELECT_COIN",
  TYPE_INPUT = "TYPE_INPUT",
  RESET = "RESET",
}

export type Action = SelectCoinAction | TypeInputAction | ResetAction;

export type SelectCoinAction = {
  type: ActionType.SELECT_COIN;
  coin: Coin | undefined;
};

export type TypeInputAction = {
  type: ActionType.TYPE_INPUT;
  typedValue: string;
};

export type ResetAction = { type: ActionType.RESET };
