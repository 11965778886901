import { Connection, JsonRpcProvider, PaginatedCoins, SuiObjectRef } from "@mysten/sui.js";
import { Coin } from "sdk/entities/coin";
import { CoinSet } from "sdk/utils/coinMap";

export async function getSuiObjectRefs(
  rpcEndpoint: string,
  owner: string,
  coins: Coin[],
): Promise<Record<string, (SuiObjectRef & { balance: bigint })[]>> {
  const provider = new JsonRpcProvider(
    new Connection({
      fullnode: rpcEndpoint,
    }),
  );
  const coinSet = CoinSet.fromList(coins);
  const promises = coinSet.toList().map((c) =>
    provider.getCoins({
      owner,
      coinType: c.type,
      limit: 50,
    }),
  );
  const responses = await Promise.all(promises);
  const rpcData = responses.reduce((acc, curr) => {
    return [...acc, ...curr.data];
  }, [] as PaginatedCoins["data"]);
  const data: Record<string, (SuiObjectRef & { balance: bigint })[]> = {};
  for (const obj of rpcData) {
    try {
      let _coin: Coin;
      if (/0x[a-fA-F0-9]+::.+::.+<0x[a-fA-F0-9]+::.+::.+, 0x[a-fA-F0-9]+::.+::.+>/.test(obj.coinType)) {
        _coin = Coin.createLpCoin(obj.coinType);
      } else {
        _coin = Coin.fromType(obj.coinType);
      }
      const isExist = coinSet.has(_coin);
      if (!isExist) {
        continue;
      }
      const refs = data[_coin.type];
      const piece = {
        objectId: obj.coinObjectId,
        version: obj.version,
        digest: obj.digest,
        balance: BigInt(obj.balance),
      };
      if (refs) {
        refs.push(piece);
      } else {
        data[_coin.type] = [piece];
      }
    } catch (e) {
      console.error(e);
    }
  }
  return data;
}

export function extractGasPayment(
  objectRefs: (SuiObjectRef & { balance: bigint })[],
  amount: bigint,
): [SuiObjectRef[], SuiObjectRef[]] {
  let sum = 0n;
  const refs: SuiObjectRef[] = [];
  const remainRefs: SuiObjectRef[] = [];
  const _objectRefs = objectRefs.sort((a, b) => {
    return a.balance > b.balance ? 1 : a.balance < b.balance ? -1 : 0;
  });
  for (let i = 0; i < _objectRefs.length; i++) {
    if (sum < amount) {
      refs.push({
        objectId: _objectRefs[i].objectId,
        version: _objectRefs[i].version,
        digest: _objectRefs[i].digest,
      });
      sum += _objectRefs[i].balance;
    } else {
      remainRefs.push({
        objectId: _objectRefs[i].objectId,
        version: _objectRefs[i].version,
        digest: _objectRefs[i].digest,
      });
    }
  }
  return [refs, remainRefs];
}
