/* eslint-disable @typescript-eslint/no-explicit-any */
import LsdFiAPI from "api/lsdfi";
import { useQuery } from "react-query";
import { NetworkEnv } from "utils/constant";

type Props = {
  networkEnv: NetworkEnv;
  params:
    | {
        amount: string;
        tokenAType: string;
        tokenBType: string;
      }
    | {
        amount: string;
        tokenType: string;
      };
};

export function useLsdDynamicFee({ networkEnv, params }: Props): number | undefined {
  const { data: feePercent } = useQuery<number | undefined>(
    [
      "get-lsd-dynamic-fee",
      networkEnv,
      params.amount,
      (params as any).tokenType,
      (params as any).tokenAType,
      (params as any).tokenBType,
    ],
    async () => {
      const resp = await LsdFiAPI.getDynamicFee(networkEnv, params);
      const fee = resp?.data?.fee;
      if (!fee) {
        return undefined;
      }
      return Number(fee) * 10_000;
    },
    {
      enabled:
        !!params.amount &&
        ((!!(params as any).tokenAType && !!(params as any).tokenAType) || !!(params as any).tokenType),
    },
  );

  return feePercent;
}
