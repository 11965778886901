import { Amount } from "sdk/entities/amount";
import { Percent } from "sdk/entities/percent";
import { Trade } from "sdk/entities/trade";

import {
  ALLOWED_PRICE_IMPACT_HIGH,
  ALLOWED_PRICE_IMPACT_LOW,
  ALLOWED_PRICE_IMPACT_MEDIUM,
  BLOCKED_PRICE_IMPACT_NON_EXPERT,
} from "../misc";

const THIRTY_BIPS_FEE = new Percent(30n, 10000n);
const ONE_HUNDRED_PERCENT = new Percent(10000n, 10000n);
const INPUT_FRACTION_AFTER_FEE = ONE_HUNDRED_PERCENT.subtract(THIRTY_BIPS_FEE);

export function computeRealizedLPFeePercent(trade: Trade): Percent {
  const percent = ONE_HUNDRED_PERCENT.subtract(
    trade.route.pools.reduce<Percent>(
      (currentFee: Percent): Percent => currentFee.multiply(INPUT_FRACTION_AFTER_FEE),
      ONE_HUNDRED_PERCENT,
    ),
  );
  return new Percent(percent.numerator, percent.denominator);
}

export function computeRealizedLPFeeAmount(trade?: Trade | null): Amount | undefined {
  if (trade) {
    const realizedLPFee = computeRealizedLPFeePercent(trade);
    return Amount.fromRawAmount(trade.inputAmount.coin, trade.inputAmount.multiply(realizedLPFee).quotient);
  }

  return undefined;
}

const IMPACT_TIERS = [
  BLOCKED_PRICE_IMPACT_NON_EXPERT,
  ALLOWED_PRICE_IMPACT_HIGH,
  ALLOWED_PRICE_IMPACT_MEDIUM,
  ALLOWED_PRICE_IMPACT_LOW,
];

export type WarningSeverity = 0 | 1 | 2 | 3 | 4;
export function warningSeverity(priceImpact: Percent | undefined): WarningSeverity {
  if (!priceImpact) return 4;
  let impact: WarningSeverity = IMPACT_TIERS.length as WarningSeverity;
  for (const impactLevel of IMPACT_TIERS) {
    if (impactLevel.lessThan(priceImpact)) return impact;
    impact--;
  }
  return 0;
}
