import { ReactElement } from "react";

import { IconProps } from "./type";

export function ArrowLeftIcon(props: IconProps): ReactElement<IconProps> {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.828 10.9997H20V12.9997H7.828L13.192 18.3637L11.778 19.7777L4 11.9997L11.778 4.22168L13.192 5.63568L7.828 10.9997Z"
        fill="currentColor"
      />
    </svg>
  );
}
