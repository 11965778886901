import { ReactElement, useEffect, useState } from "react";
import { useWallet } from "@suiet/wallet-kit";
import { WisppointResponse } from "api/type";
import WisppointAPI from "api/wisppoint";
import clsx from "clsx";

import { InactiveUser } from "./InactiveUser";
import { WisppointDetail } from "./WisppointDetail";

export function WisppointView(): ReactElement {
  const { address } = useWallet();
  const [data, setData] = useState<WisppointResponse>();

  useEffect(() => {
    (async (): Promise<void> => {
      if (!address) {
        return;
      }
      const resp = await WisppointAPI.getWisppointData(address);
      if (resp?.data?.data) {
        setData(resp.data.data);
      } else {
        setData(undefined);
      }
    })();
  }, [address]);

  return (
    <div className={clsx("flex-1 flex justify-center items-center pb-10", "z-10 text-pNeutral-800")}>
      <div className="w-full max-w-md bg-dark-600 rounded-2xl shadow-2xl p-8">
        {!data ? <InactiveUser /> : <WisppointDetail data={data} />}
      </div>
    </div>
  );
}
