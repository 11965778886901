import { ReactElement, useState } from "react";
import { TransactionBlock } from "@mysten/sui.js";
import { useWallet } from "@suiet/wallet-kit";
import PredictionAPI from "api/prediction";
import clsx from "clsx";
import { Button } from "components/atoms/Button";
import { CheckboxGroup } from "components/atoms/CheckboxGroup";
import { CloseIcon } from "components/atoms/icons/CloseIcon";
import { CoinIcon } from "components/atoms/icons/CoinIcon";
import { ExternalLinkIcon } from "components/atoms/icons/ExternalLinkIcon";
import { HistoryIcon } from "components/atoms/icons/HistoryIcon";
import { useWalletHelper } from "contexts/WalletHelperContext";
import { useWispSettings } from "contexts/WispSettingsContext";
import { useBetHistory } from "hooks/useBetHistory";
import { toast } from "react-toastify";
import { SUI } from "utils/coins";
import { WISP_CONFIG } from "utils/constant";
import { extractGasPayment, getSuiObjectRefs } from "utils/getCoinObjectIds";

import { PREDICTION_HISTORY_FILTER } from "./constant";
import { HistoryItem } from "./HistoryItem";

const CHECKBOX_ID = "wisp-drawer-checkbox";

const FILTER_OPTIONS = [
  {
    id: PREDICTION_HISTORY_FILTER.ALL,
    label: "All",
  },
  {
    id: PREDICTION_HISTORY_FILTER.COLLECTED,
    label: "Collected",
  },
  {
    id: PREDICTION_HISTORY_FILTER.UNCOLLECTED,
    label: "Uncollected",
  },
];

export function History({ onOpenLeaderBoard }: { onOpenLeaderBoard: () => void }): ReactElement {
  const suietWallet = useWallet();
  const address = suietWallet.address;

  const { signAndExecuteTransaction } = useWalletHelper();
  const { settings } = useWispSettings();

  const [claiming, setClaiming] = useState(false);
  const [filter, setFilter] = useState(FILTER_OPTIONS[0]);
  const betRounds = useBetHistory(settings.networkEnv, address, filter.id);

  const handleClaimAll = async (): Promise<void> => {
    if (!address || claiming) {
      return;
    }
    setClaiming(true);
    try {
      const resp = await PredictionAPI.getAllUnclaimedEpochs(settings.networkEnv, address);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const epochs: string[] = (resp.data ?? []).map((i: any) => i.roundEpoch as string);
      if (!epochs.length) {
        toast.error("No unclaimed round found");
        return;
      }
      const objectRefs = await getSuiObjectRefs(settings.customRPC, address, [SUI]);
      const suiObjectRefs = objectRefs[SUI.type] ?? [];
      const [suiGas] = extractGasPayment(suiObjectRefs, 100_000_000n);
      const tx = new TransactionBlock();
      tx.moveCall({
        target: `${WISP_CONFIG.prediction.packageObject}::${WISP_CONFIG.prediction.moduleName}::${WISP_CONFIG.prediction.functions.claim}`,
        typeArguments: [WISP_CONFIG.prediction.coin],
        arguments: [tx.object(WISP_CONFIG.prediction.market), tx.pure(epochs)],
      });
      tx.setGasBudget(100_000_000);
      tx.setGasPayment(suiGas);
      await signAndExecuteTransaction(tx);
    } catch (e) {
      console.error(e);
    } finally {
      setClaiming(false);
    }
  };

  return (
    <div className="relative">
      <div className="overflow-x-auto">
        <div className="flex justify-between items-center space-x-2 max-w-[1200px] mx-auto">
          <div>
            <div
              className="flex items-center space-x-2 px-4 py-2 rounded-full mb-5 cursor-pointer bg-white text-dark-600"
              onClick={onOpenLeaderBoard}
            >
              <ExternalLinkIcon className="w-4 h-4" />
              <span className="font-semibold">Leaderboard</span>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <label
              className="flex items-center space-x-2 px-4 py-2 rounded-full bg-white text-dark-600 mb-5 cursor-pointer"
              htmlFor={CHECKBOX_ID}
            >
              <HistoryIcon className="w-6 h-6" />
              <span className="font-semibold">History</span>
            </label>
            <div
              className={clsx(
                "flex items-center space-x-2 px-4 py-2 rounded-full mb-5 cursor-pointer",
                claiming ? "bg-gray-400 text-dark-600" : "bg-btn text-white",
              )}
              onClick={handleClaimAll}
            >
              <CoinIcon className="w-6 h-6" />
              <span className="font-semibold">Collect</span>
            </div>
          </div>
        </div>
      </div>
      <input className="hidden" id={CHECKBOX_ID} type="checkbox" />
      <label
        className="prediction-history-overlay animate-fade-in hidden fixed bg-dark-600 bg-opacity-50 top-[72px] left-0 right-0 bottom-0 z-40"
        htmlFor={CHECKBOX_ID}
      />
      <div className="prediction-history z-50 translate-x-full fixed top-[72px] right-0 bottom-0 w-[340px] max-w-full bg-dark-400 transition-transform flex flex-col">
        <div className="p-5 shrink-0">
          <div className="flex items-center justify-between">
            <h5 className="font-semibold text-lg">History</h5>
            <label className="flex items-center justify-center cursor-pointer w-6 h-6" htmlFor={CHECKBOX_ID}>
              <CloseIcon className="w-3 h-3" />
            </label>
          </div>
          <div className="mt-3">
            <div className="text-pNeutral-800 font-semibold text-sm opacity-50">Filter</div>
            <CheckboxGroup
              checkedOption={filter}
              className="flex items-center space-x-4 flex-wrap mt-2"
              options={FILTER_OPTIONS}
              onChange={(v): void => setFilter(v)}
            />
          </div>
        </div>
        <div className="bg-dark-600 flex-1 overflow-y-auto divide-y divide-white divide-opacity-10 px-6">
          {betRounds.map((item) => (
            <HistoryItem betRound={item} key={item.id} />
          ))}
        </div>
        <div className="flex justify-center py-4 bg-dark-600">
          <Button className="px-6 py-2 font-medium" disabled={claiming} isLoading={claiming} onClick={handleClaimAll}>
            <CoinIcon className="w-6 h-6" />
            <span>Collect All Winning</span>
          </Button>
        </div>
      </div>
    </div>
  );
}
