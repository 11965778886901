import { ReactElement, useState } from "react";
import clsx from "clsx";

import VeWispToWisp from "./VeWispToWisp";
import WispToVeWisp from "./WispToVeWisp";

export enum Tabs {
  WISP = "WISP",
  VE_WISP = "VE_WISP",
}

function VeWispView(): ReactElement {
  const [tab, setTab] = useState<Tabs>(Tabs.WISP);

  return (
    <div className="relative max-w-7xl w-full mx-auto z-10">
      <div className="text-2xl sm:text-4xl font-extrabold pb-4 sm:pb-8">veWisp</div>
      <div className="bg-dark-600 p-4 md:p-8 rounded-2xl">
        <div className="flex justify-between items-center md:pb-4 space-x-9">
          <div className="text-base px-3 sm:text-xl font-bold flex justify-start items-center space-x-6 w-fit lg:w-auto">
            <div
              className={clsx("whitespace-nowrap cursor-pointer", tab !== Tabs.WISP && "text-gray-600")}
              onClick={(): void => setTab(Tabs.WISP)}
            >
              Wisp
            </div>
            <div
              className={clsx("whitespace-nowrap cursor-pointer", tab !== Tabs.VE_WISP && "text-gray-600")}
              onClick={(): void => setTab(Tabs.VE_WISP)}
            >
              veWisp
            </div>
          </div>
        </div>
        {tab === Tabs.WISP && <WispToVeWisp />}
        {tab === Tabs.VE_WISP && <VeWispToWisp />}
      </div>
    </div>
  );
}

export default VeWispView;
