import invariant from "tiny-invariant";

import { TradeType } from "../constants";
import { computePriceImpact } from "../utils/computePriceImpact";
import { sortedInsert } from "../utils/sortedInsert";
import { Amount } from "./amount";
import { Coin } from "./coin";
import { InsufficientReservesError } from "./error";
import { Fraction } from "./fraction";
import { Percent } from "./percent";
import { Pool } from "./pool";
import { Price } from "./price";
import { Route } from "./route";

// minimal interface so the input output comparator may be shared across types
interface InputOutput {
  readonly inputAmount: Amount;
  readonly outputAmount: Amount;
}

// comparator function that allows sorting trades by their output amounts, in decreasing order, and then input amounts
// in increasing order. i.e. the best trades have the most outputs for the least inputs and are sorted first
export function inputOutputComparator(a: InputOutput, b: InputOutput): number {
  // must have same input and output coin for comparison
  invariant(a.inputAmount.coin.equals(b.inputAmount.coin), "INPUT_CURRENCY");
  invariant(a.outputAmount.coin.equals(b.outputAmount.coin), "OUTPUT_CURRENCY");
  if (a.outputAmount.equalTo(b.outputAmount)) {
    if (a.inputAmount.equalTo(b.inputAmount)) {
      return 0;
    }
    // trade A requires less input than trade B, so A should come first
    if (a.inputAmount.lessThan(b.inputAmount)) {
      return -1;
    } else {
      return 1;
    }
  } else {
    // tradeA has less output than trade B, so should come second
    if (a.outputAmount.lessThan(b.outputAmount)) {
      return 1;
    } else {
      return -1;
    }
  }
}

// extension of the input output comparator that also considers other dimensions of the trade in ranking them
export function tradeComparator(a: Trade, b: Trade): number {
  const ioComp = inputOutputComparator(a, b);
  if (ioComp !== 0) {
    return ioComp;
  }

  // consider lowest slippage next, since these are less likely to fail
  if (a.priceImpact.lessThan(b.priceImpact)) {
    return -1;
  } else if (a.priceImpact.greaterThan(b.priceImpact)) {
    return 1;
  }

  // finally consider the number of hops since each hop costs gas
  return a.route.path.length - b.route.path.length;
}

export interface BestTradeOptions {
  // how many results to return
  maxNumResults?: number;
  // the maximum number of hops a trade should contain
  maxHops?: number;
}

/**
 * Represents a trade executed against a list of pools.
 * Does not account for slippage, i.e. trades that front run this trade and move the price.
 */
export class Trade {
  /**
   * The route of the trade, i.e. which pools the trade goes through and the input/output currencies.
   */
  public readonly route: Route;
  /**
   * The type of the trade, either exact in or exact out.
   */
  public readonly tradeType: TradeType;
  /**
   * The input amount for the trade assuming no slippage.
   */
  public readonly inputAmount: Amount;
  /**
   * The output amount for the trade assuming no slippage.
   */
  public readonly outputAmount: Amount;
  /**
   * The price expressed in terms of output amount/input amount.
   */
  public readonly executionPrice: Price;
  /**
   * The percent difference between the mid price before the trade and the trade execution price.
   */
  public readonly priceImpact: Percent;

  public constructor(route: Route, amount: Amount, tradeType: TradeType) {
    this.route = route;
    this.tradeType = tradeType;

    const coinAmounts: Amount[] = new Array(route.path.length);
    if (tradeType === TradeType.EXACT_INPUT) {
      invariant(amount.coin.equals(route.input), "INPUT");
      coinAmounts[0] = amount;
      for (let i = 0; i < route.path.length - 1; i++) {
        const pool = route.pools[i];
        const coinAmount = coinAmounts[i];
        invariant(pool !== undefined && coinAmount !== undefined, "UNDEFINED");
        const [outputAmount] = pool.getOutputAmount(coinAmount);
        coinAmounts[i + 1] = outputAmount;
      }
      const lastAmount = coinAmounts[coinAmounts.length - 1];
      invariant(lastAmount !== undefined, "UNDEFINED");
      this.inputAmount = Amount.fromFractionalAmount(route.input, amount.numerator, amount.denominator);
      this.outputAmount = Amount.fromFractionalAmount(route.output, lastAmount.numerator, lastAmount.denominator);
    } else {
      invariant(amount.coin.equals(route.output), "OUTPUT");
      coinAmounts[coinAmounts.length - 1] = amount;
      for (let i = route.path.length - 1; i > 0; i--) {
        const pool = route.pools[i - 1];
        const coinAmount = coinAmounts[i];
        invariant(pool !== undefined && coinAmount !== undefined, "UNDEFINED");
        const [inputAmount] = pool.getInputAmount(coinAmount);
        coinAmounts[i - 1] = inputAmount;
      }
      const firstAmount = coinAmounts[0];
      invariant(firstAmount !== undefined, "UNDEFINED");
      this.inputAmount = Amount.fromFractionalAmount(route.input, firstAmount.numerator, firstAmount.denominator);
      this.outputAmount = Amount.fromFractionalAmount(route.output, amount.numerator, amount.denominator);
    }
    this.executionPrice = new Price(
      this.inputAmount.coin,
      this.outputAmount.coin,
      this.inputAmount.quotient,
      this.outputAmount.quotient,
    );
    this.priceImpact = computePriceImpact(route.midPrice, this.inputAmount, this.outputAmount);
  }

  /**
   * Constructs an exact in trade with the given amount in and route
   * @param route route of the exact in trade
   * @param amountIn the amount being passed in
   */
  public static exactIn(route: Route, amountIn: Amount): Trade {
    return new Trade(route, amountIn, TradeType.EXACT_INPUT);
  }

  /**
   * Constructs an exact out trade with the given amount out and route
   * @param route route of the exact out trade
   * @param amountOut the amount returned by the trade
   */
  public static exactOut(route: Route, amountOut: Amount): Trade {
    return new Trade(route, amountOut, TradeType.EXACT_OUTPUT);
  }

  /**
   * Given a list of pools, and a fixed amount in, returns the top `maxNumResults` trades that go from an input coin
   * amount to an output coin, making at most `maxHops` hops.
   * Note this does not consider aggregation, as routes are linear. It's possible a better route exists by splitting
   * the amount in among multiple routes.
   * @param pools the pools to consider in finding the best trade
   * @param nextAmountIn exact amount of input currency to spend
   * @param currencyOut the desired currency out
   * @param maxNumResults maximum number of results to return
   * @param maxHops maximum number of hops a returned trade can make, e.g. 1 hop goes through a single pool
   * @param currentPools used in recursion; the current list of pools
   * @param currencyAmountIn used in recursion; the original value of the currencyAmountIn parameter
   * @param bestTrades used in recursion; the current list of best trades
   */
  public static bestTradeExactIn(
    pools: Pool[],
    currencyAmountIn: Amount,
    currencyOut: Coin,
    { maxNumResults = 3, maxHops = 3 }: BestTradeOptions = {},
    // used in recursion.
    currentPools: Pool[] = [],
    nextAmountIn: Amount = currencyAmountIn,
    bestTrades: Trade[] = [],
  ): Trade[] {
    invariant(pools.length > 0, "PAIRS");
    invariant(maxHops > 0, "MAX_HOPS");
    invariant(currencyAmountIn === nextAmountIn || currentPools.length > 0, "INVALID_RECURSION");

    const amountIn = nextAmountIn;
    const coinOut = currencyOut;
    for (let i = 0; i < pools.length; i++) {
      const pool = pools[i];
      invariant(pool !== undefined, "UNDEFINED");
      // pool irrelevant
      if (!pool.coin0.equals(amountIn.coin) && !pool.coin1.equals(amountIn.coin)) continue;
      if (pool.reserve0.equalTo(0n) || pool.reserve1.equalTo(0n)) continue;

      const [amountOut] = pool.getOutputAmount(amountIn);

      // we have arrived at the output coin, so this is the final trade of one of the paths
      if (amountOut.coin.equals(coinOut)) {
        sortedInsert(
          bestTrades,
          new Trade(
            new Route([...currentPools, pool], currencyAmountIn.coin, currencyOut),
            currencyAmountIn,
            TradeType.EXACT_INPUT,
          ),
          maxNumResults,
          tradeComparator,
        );
      } else if (maxHops > 1 && pools.length > 1) {
        const poolsExcludingThisPool = pools.slice(0, i).concat(pools.slice(i + 1, pools.length));

        // otherwise, consider all the other paths that lead from this coin as long as we have not exceeded maxHops
        Trade.bestTradeExactIn(
          poolsExcludingThisPool,
          currencyAmountIn,
          currencyOut,
          {
            maxNumResults,
            maxHops: maxHops - 1,
          },
          [...currentPools, pool],
          amountOut,
          bestTrades,
        );
      }
    }

    return bestTrades;
  }

  /**
   * similar to the above method but instead targets a fixed output amount
   * given a list of pools, and a fixed amount out, returns the top `maxNumResults` trades that go from an input coin
   * to an output coin amount, making at most `maxHops` hops
   * note this does not consider aggregation, as routes are linear. it's possible a better route exists by splitting
   * the amount in among multiple routes.
   * @param pools the pools to consider in finding the best trade
   * @param currencyIn the currency to spend
   * @param nextAmountOut the exact amount of currency out
   * @param maxNumResults maximum number of results to return
   * @param maxHops maximum number of hops a returned trade can make, e.g. 1 hop goes through a single pool
   * @param currentPools used in recursion; the current list of pools
   * @param currencyAmountOut used in recursion; the original value of the currencyAmountOut parameter
   * @param bestTrades used in recursion; the current list of best trades
   */
  public static bestTradeExactOut(
    pools: Pool[],
    currencyIn: Coin,
    currencyAmountOut: Amount,
    { maxNumResults = 3, maxHops = 3 }: BestTradeOptions = {},
    // used in recursion.
    currentPools: Pool[] = [],
    nextAmountOut: Amount = currencyAmountOut,
    bestTrades: Trade[] = [],
  ): Trade[] {
    invariant(pools.length > 0, "PAIRS");
    invariant(maxHops > 0, "MAX_HOPS");
    invariant(currencyAmountOut === nextAmountOut || currentPools.length > 0, "INVALID_RECURSION");

    const amountOut = nextAmountOut;
    const coinIn = currencyIn;
    for (let i = 0; i < pools.length; i++) {
      const pool = pools[i];
      invariant(pool !== undefined, "UNDEFINED");
      // pool irrelevant
      if (!pool.coin0.equals(amountOut.coin) && !pool.coin1.equals(amountOut.coin)) continue;
      if (pool.reserve0.equalTo(0n) || pool.reserve1.equalTo(0n)) continue;

      let amountIn: Amount;
      try {
        [amountIn] = pool.getInputAmount(amountOut);
      } catch (error) {
        // not enough liquidity in this pool
        if ((error as InsufficientReservesError).isInsufficientReservesError) {
          continue;
        }
        throw error;
      }
      // we have arrived at the input coin, so this is the first trade of one of the paths
      if (amountIn.coin.equals(coinIn)) {
        sortedInsert(
          bestTrades,
          new Trade(
            new Route([pool, ...currentPools], currencyIn, currencyAmountOut.coin),
            currencyAmountOut,
            TradeType.EXACT_OUTPUT,
          ),
          maxNumResults,
          tradeComparator,
        );
      } else if (maxHops > 1 && pools.length > 1) {
        const poolsExcludingThisPool = pools.slice(0, i).concat(pools.slice(i + 1, pools.length));

        // otherwise, consider all the other paths that arrive at this coin as long as we have not exceeded maxHops
        Trade.bestTradeExactOut(
          poolsExcludingThisPool,
          currencyIn,
          currencyAmountOut,
          {
            maxNumResults,
            maxHops: maxHops - 1,
          },
          [pool, ...currentPools],
          amountIn,
          bestTrades,
        );
      }
    }

    return bestTrades;
  }

  /**
   * Get the minimum amount that must be received from this trade for the given slippage tolerance
   * @param slippageTolerance tolerance of unfavorable slippage from the execution price of this trade
   */
  public minimumAmountOut(slippageTolerance: Percent): Amount {
    invariant(!slippageTolerance.lessThan(0n), "SLIPPAGE_TOLERANCE");
    if (this.tradeType === TradeType.EXACT_OUTPUT) {
      return this.outputAmount;
    } else {
      const slippageAdjustedAmountOut = new Fraction(1n)
        .add(slippageTolerance)
        .invert()
        .multiply(this.outputAmount.quotient).quotient;
      return Amount.fromRawAmount(this.outputAmount.coin, slippageAdjustedAmountOut);
    }
  }

  /**
   * Get the maximum amount in that can be spent via this trade for the given slippage tolerance
   * @param slippageTolerance tolerance of unfavorable slippage from the execution price of this trade
   */
  public maximumAmountIn(slippageTolerance: Percent): Amount {
    invariant(!slippageTolerance.lessThan(0n), "SLIPPAGE_TOLERANCE");
    if (this.tradeType === TradeType.EXACT_INPUT) {
      return this.inputAmount;
    } else {
      const slippageAdjustedAmountIn = new Fraction(1n)
        .add(slippageTolerance)
        .multiply(this.inputAmount.quotient).quotient;
      return Amount.fromRawAmount(this.inputAmount.coin, slippageAdjustedAmountIn);
    }
  }

  /**
   * Return the execution price after accounting for slippage tolerance
   * @param slippageTolerance the allowed tolerated slippage
   */
  public worstExecutionPrice(slippageTolerance: Percent): Price {
    return new Price(
      this.inputAmount.coin,
      this.outputAmount.coin,
      this.maximumAmountIn(slippageTolerance).quotient,
      this.minimumAmountOut(slippageTolerance).quotient,
    );
  }
}
