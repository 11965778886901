import { ReactElement } from "react";
import DexAPI from "api/dex";
import clsx from "clsx";
import { AssetLogo } from "components/atoms/AssetLogo";
import { ReloadIcon } from "components/atoms/icons/ReloadIcon";
import { useWispSettings } from "contexts/WispSettingsContext";
import moment from "moment";
import { useQuery } from "react-query";
import { Amount } from "sdk/entities/amount";
import { Coin } from "sdk/entities/coin";
import { toFixedDecimalPlaces } from "sdk/utils/formatNumber";
import { shortenAddress } from "utils";

type Props = {
  className?: string;
};

export function TradeHistory({ className }: Props): ReactElement {
  const { settings } = useWispSettings();

  const { data } = useQuery(["get-trade-history", settings.networkEnv], async () => {
    const resp = await DexAPI.getTradeHistory(settings.networkEnv);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (resp.data ?? []).map((piece: any) => {
      const coinA = new Coin(piece.coinAPackageAddr, piece.coinAModule, piece.coinAType, piece.coinADecimal, "", {
        imageUrl: piece.coinAIconUrl,
      });
      const coinB = new Coin(piece.coinBPackageAddr, piece.coinBModule, piece.coinBType, piece.coinBDecimal, "", {
        imageUrl: piece.coinBIconUrl,
      });
      const txId = piece?.digest?.txDigest;
      const isAInput = piece.firstAmountIn !== "0";
      const inputAmt = Amount.fromRawAmount(
        isAInput ? coinA : coinB,
        isAInput ? piece.firstAmountIn : piece.secondAmountIn,
      );
      const outputAmt = Amount.fromRawAmount(
        isAInput ? coinB : coinA,
        isAInput ? piece.secondAmountOut : piece.firstAmountOut,
      );
      return {
        txId,
        inputAmt,
        outputAmt,
        time: new Date(Number(piece.timeStampMs)),
      };
    });
  });

  return (
    <div className={clsx("flex-1 flex flex-col min-w-0 min-h-0 h-[680px]", className)}>
      <div className="font-medium text-lg">Trade History</div>
      <div className="flex-1 min-w-0 min-h-0 mt-4 bg-dark-600 rounded-2xl flex flex-col overflow-hidden">
        <div className="grid grid-cols-[2fr,2fr,1fr,1.5fr] gap-3 py-3 px-4 bg-dark-400">
          <div>From</div>
          <div>To</div>
          <div>Txn</div>
          <div className="flex items-center justify-between">
            <span>Time</span>
            <span className="rounded-md hover:animate-spin cursor-pointer">
              <ReloadIcon className="w-4 h-4 shrink-0" />
            </span>
          </div>
        </div>
        <div className="flex-1 overflow-auto min-w-0 min-h-0 divide-y divide-white divide-opacity-5 [&>div:nth-child(even)]:bg-dark-400">
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {data?.map((item: any) => {
            const time = moment(item.time).fromNow();
            return (
              <div className="grid grid-cols-[2fr,2fr,1fr,1.5fr] gap-3 py-3 px-4 text-sm" key={item.txId}>
                <div className="flex items-center justify-between space-x-2">
                  <div className="flex items-center lg:space-x-2">
                    <AssetLogo asset={item.inputAmt.coin} className="hidden lg:block !h-6 !w-6" />
                    <div className="flex-1 min-w-0 overflow-hidden">
                      <div className="truncate overflow-ellipsis text-pGreen-500">
                        {toFixedDecimalPlaces(item.inputAmt?.toExact(), 4)}
                      </div>
                      <div className="truncate overflow-ellipsis">{item.inputAmt.coin.name}</div>
                    </div>
                  </div>
                  <span className="hidden lg:block font-semibold shrink-0 text-pNeutral-500">→</span>
                </div>
                <div className="flex items-center lg:space-x-2">
                  <AssetLogo asset={item.outputAmt.coin} className="hidden lg:block !h-6 !w-6" />
                  <div className="flex-1 min-w-0 overflow-hidden">
                    <div className="truncate overflow-ellipsis text-pGreen-500">
                      {toFixedDecimalPlaces(item.outputAmt.toExact(), 4)}
                    </div>
                    <div className="truncate overflow-ellipsis">{item.outputAmt.coin.name}</div>
                  </div>
                </div>
                <div className="flex items-center justify-start text-pNeutral-500">
                  <a
                    className="text-pNeutral-500 hover:text-pGreen-400"
                    href={`https://explorer.sui.io/transaction/${item.txId}?network=${settings.networkEnv.substring(
                      4,
                    )}`}
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    {shortenAddress(item.txId, 4)}
                  </a>
                </div>
                <div className="flex items-center justify-start text-pNeutral-500">{time}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
