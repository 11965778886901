import * as React from "react";
import { useWalletHelper } from "contexts/WalletHelperContext";
import { Amount } from "sdk/entities/amount";
import { Coin } from "sdk/entities/coin";
import { tryParseAmount } from "sdk/utils/tryParseAmount";

import { Field } from "../reducers/actions";
import { State } from "../reducers/exchangeReducer";

export function useExchange(state: State): {
  assets: { [field in Field]?: Coin };
  balances: { [field in Field]?: Amount };
  parsedAmount: Amount | undefined;
  inputError?: string;
} {
  const {
    typedValue,
    [Field.INPUT]: { coin: inputCoin },
    [Field.OUTPUT]: { coin: outputCoin },
  } = state;

  const { fungibleBalances } = useWalletHelper();

  const [inputBalance, outputBalance] = React.useMemo(() => {
    let inpBalance: Amount | undefined = undefined;
    let outBalance: Amount | undefined = undefined;

    if (inputCoin) {
      inpBalance = fungibleBalances?.find((b) => b.coin.equals(inputCoin));
      if (!inpBalance) {
        inpBalance = Amount.fromRawAmount(inputCoin, 0);
      }
    }
    if (outputCoin) {
      outBalance = fungibleBalances?.find((b) => b.coin.equals(outputCoin));
      if (!outBalance) {
        outBalance = Amount.fromRawAmount(outputCoin, 0);
      }
    }
    return [inpBalance, outBalance];
  }, [fungibleBalances, inputCoin, outputCoin]);

  const parsedAmount = tryParseAmount(typedValue, inputCoin);

  const balances = {
    [Field.INPUT]: inputBalance,
    [Field.OUTPUT]: outputBalance,
  };

  const coins: { [field in Field]?: Coin } = {
    [Field.INPUT]: inputCoin ?? undefined,
    [Field.OUTPUT]: outputCoin ?? undefined,
  };

  let inputError: string | undefined;
  if (!parsedAmount) {
    inputError = inputError ?? "Enter an amount";
  }

  if (!coins[Field.INPUT] || !coins[Field.OUTPUT]) {
    inputError = inputError ?? "Select a token";
  }

  if (parsedAmount && parsedAmount.greaterThan(0) && inputBalance && inputBalance.lessThan(parsedAmount)) {
    inputError = inputError ?? `Insufficient ${inputCoin?.name} balance`;
  }

  return {
    assets: coins,
    balances,
    parsedAmount,
    inputError,
  };
}
