import { ReactElement, useMemo, useState } from "react";
import { Disclosure } from "@headlessui/react";
import { TransactionArgument, TransactionBlock } from "@mysten/sui.js";
import { useWallet } from "@suiet/wallet-kit";
import clsx from "clsx";
import { AssetLogo } from "components/atoms/AssetLogo";
import { Button } from "components/atoms/Button";
import { ChevronDownIcon } from "components/atoms/icons/ChevronDownIcon";
import { MultipleAssetLogo } from "components/atoms/MultipleAssetLogo";
import { BoostModal } from "components/molecules/BoostModal";
import { StakeLPModal } from "components/molecules/StakeLPModal";
import { UnboostModal } from "components/molecules/UnboostModal";
import { WithdrawLPModal } from "components/molecules/WithdrawLPModal";
import { useWalletHelper } from "contexts/WalletHelperContext";
import { useWispSettings } from "contexts/WispSettingsContext";
import { useAllFarms } from "hooks/useAllFarms";
import { useAllSpNfts } from "hooks/useAllSpNft";
import { useAllVeWisp } from "hooks/useAllVeWisp";
import moment from "moment";
import { Amount } from "sdk/entities/amount";
import { intlFormatNumber, toCommaSeparated } from "sdk/utils/formatNumber";
import invariant from "tiny-invariant";
import { FarmWithNft } from "types/farm";
import { SUI } from "utils/coins";
import { WISP_CONFIG } from "utils/constant";
import { extractGasPayment, getSuiObjectRefs } from "utils/getCoinObjectIds";

import { BOOST_RATE } from "./constants";

type StakeModalState = {
  farm: FarmWithNft | undefined;
  isOpen: boolean;
};

const DefaultStakeModalState: StakeModalState = {
  farm: undefined,
  isOpen: false,
};

function YieldFarming(): ReactElement {
  const { address } = useWallet();
  const { signAndExecuteTransaction } = useWalletHelper();
  const { settings } = useWispSettings();
  const nftObjects = useAllSpNfts(settings.customRPC, settings.networkEnv, address);
  const veWispData = useAllVeWisp(settings.customRPC, settings.networkEnv, address);

  const { data: farms } = useAllFarms(settings.networkEnv);

  const [stakeModalState, setStakeModalState] = useState(DefaultStakeModalState);
  const [withdrawModalState, setWithdrawModalState] = useState(DefaultStakeModalState);
  const [boostModalState, setBoostModalState] = useState(DefaultStakeModalState);
  const [unboostModalState, setUnboostModalState] = useState(DefaultStakeModalState);
  const [stakingId, setStakingId] = useState<number>();
  const [withdrawId, setWithdrawId] = useState<number>();
  const [claimingId, setClaimingId] = useState<number>();
  const [boostId, setBoostId] = useState<number>();
  const [unboostId, setUnboostId] = useState<number>();

  const farmWithNftData: FarmWithNft[] = useMemo(() => {
    return farms.map((f) => {
      const nft = nftObjects.find((obj) => {
        if (f.isLpCoin) {
          // lp coin
          return (
            (f.stakingCoin.coinA?.equals(obj.stakingCoin.coinA) &&
              f.stakingCoin.coinB?.equals(obj.stakingCoin.coinB)) ||
            (f.stakingCoin.coinB?.equals(obj.stakingCoin.coinA) && f.stakingCoin.coinA?.equals(obj.stakingCoin.coinB))
          );
        } else {
          // single coin
          return f.stakingCoin.equals(obj.stakingCoin);
        }
      });
      const unclaimedRewards = nft
        ? Amount.fromRawAmount(SUI, (nft.stakePoint * f.accWispPerShare) / 1_000_000_000_000_000_000n - nft.rewardDebt)
        : Amount.fromRawAmount(SUI, 0);
      return {
        ...f,
        spNft: nft,
        unclaimedRewards,
      };
    });
  }, [nftObjects, farms]);

  const handleOpenStakeModal = (farm: FarmWithNft): void => {
    setStakeModalState({
      farm: farm,
      isOpen: true,
    });
  };

  const handleOpenWithdrawModal = (farm: FarmWithNft): void => {
    setWithdrawModalState({
      farm: farm,
      isOpen: true,
    });
  };

  const handleOpenBoostModal = (farm: FarmWithNft): void => {
    setBoostModalState({
      farm: farm,
      isOpen: true,
    });
  };

  const handleOpenUnboostModal = (farm: FarmWithNft): void => {
    setUnboostModalState({
      farm: farm,
      isOpen: true,
    });
  };

  const handleCloseStakeModal = (): void => {
    setStakeModalState(DefaultStakeModalState);
  };

  const handleCloseWithdrawModal = (): void => {
    setWithdrawModalState(DefaultStakeModalState);
  };

  const handleCloseBoostModal = (): void => {
    setBoostModalState(DefaultStakeModalState);
  };

  const handleCloseUnboostModal = (): void => {
    setUnboostModalState(DefaultStakeModalState);
  };

  const handleStakeLP = async (amount: Amount, farm: FarmWithNft): Promise<void> => {
    setStakingId(farm.id);
    invariant(address, "Address not found");
    invariant(farm.farmAddress, "Farm address not found");
    if (farm.isLpCoin) {
      invariant(amount.coin.coinA?.type, "LP A not found");
      invariant(amount.coin.coinB?.type, "LP B not found");
    } else {
      invariant(amount.coin.type, "Coin not found");
    }
    try {
      const isNewStaking = !farm.spNft;
      const objectRefs = await getSuiObjectRefs(settings.customRPC, address, [SUI, amount.coin]);
      const suiObjectRefs = objectRefs[SUI.type] ?? [];
      const tokenObjectRefs = objectRefs[amount.coin.type] ?? [];
      const isSui = amount.coin.equals(SUI);
      const [suiGas] = extractGasPayment(suiObjectRefs, 100_000_000n);
      const tx = new TransactionBlock();
      let splitCoin = undefined;
      if (isSui) {
        splitCoin = tx.splitCoins(tx.gas, [tx.pure(amount.quotient.toString())])[0];
      }
      if (!isSui) {
        tx.setGasPayment(suiGas);
      }
      tx.setGasBudget(100_000_000);
      tx.moveCall({
        target: `${WISP_CONFIG.farm.package}::${WISP_CONFIG.farm.module}::${
          isNewStaking ? WISP_CONFIG.farm.functions.createSpNft : WISP_CONFIG.farm.functions.stake
        }`,
        typeArguments: farm.isLpCoin
          ? [`${WISP_CONFIG.dex.swapPackage}::pool::WISPLP<${amount.coin.coinA?.type}, ${amount.coin.coinB?.type}>`]
          : [amount.coin.type],
        arguments: isNewStaking
          ? [
              tx.object(WISP_CONFIG.farm.registry),
              tx.makeMoveVec({
                objects: isSui
                  ? ([splitCoin] as TransactionArgument[])
                  : tokenObjectRefs.map((i) => tx.object(i.objectId)),
              }),
              tx.pure(farm.farmAddress),
              tx.pure(amount.quotient),
              tx.object("0x6"),
            ]
          : [
              tx.object(WISP_CONFIG.farm.registry),
              tx.object(farm.spNft?.objectId ?? ""),
              tx.makeMoveVec({
                objects: isSui
                  ? ([splitCoin] as TransactionArgument[])
                  : tokenObjectRefs.map((i) => tx.object(i.objectId)),
              }),
              tx.pure(amount.quotient),
              tx.pure(0),
              tx.object("0x6"),
            ],
      });
      await signAndExecuteTransaction(tx);
      handleCloseStakeModal();
    } catch (e) {
      console.error(e);
    } finally {
      setStakingId(undefined);
    }
  };

  const handleClaimReward = async (farm: FarmWithNft): Promise<void> => {
    setClaimingId(farm.id);
    invariant(address, "Address not found");
    invariant(farm.spNft, "spNft not found");
    if (farm.isLpCoin) {
      invariant(farm.stakingCoin.coinA?.type, "LP A not found");
      invariant(farm.stakingCoin.coinB?.type, "LP B not found");
    } else {
      invariant(farm.stakingCoin.type, "Coin not found");
    }
    try {
      const objectRefs = await getSuiObjectRefs(settings.customRPC, address, [SUI]);
      const suiObjectRefs = objectRefs[SUI.type] ?? [];
      const [suiGas] = extractGasPayment(suiObjectRefs, 100_000_000n);
      const tx = new TransactionBlock();
      tx.setGasPayment(suiGas);
      tx.setGasBudget(100_000_000);
      tx.moveCall({
        target: `${WISP_CONFIG.farm.package}::${WISP_CONFIG.farm.module}::${WISP_CONFIG.farm.functions.claimReward}`,
        typeArguments: farm.isLpCoin
          ? [
              `${WISP_CONFIG.dex.swapPackage}::pool::WISPLP<${farm.stakingCoin.coinA?.type}, ${farm.stakingCoin.coinB?.type}>`,
            ]
          : [farm.stakingCoin.type],
        arguments: [tx.object(WISP_CONFIG.farm.registry), tx.object(farm.spNft.objectId), tx.object("0x6")],
      });
      await signAndExecuteTransaction(tx);
    } catch (e) {
      console.error(e);
    } finally {
      setClaimingId(undefined);
    }
  };

  const handleWithdrawLP = async (amount: Amount, farm: FarmWithNft): Promise<void> => {
    setWithdrawId(farm.id);
    invariant(address, "Address not found");
    invariant(farm.spNft, "spNft not found");
    if (farm.isLpCoin) {
      invariant(amount.coin.coinA?.type, "LP A not found");
      invariant(amount.coin.coinB?.type, "LP B not found");
    } else {
      invariant(amount.coin.type, "Coin not found");
    }
    try {
      const objectRefs = await getSuiObjectRefs(settings.customRPC, address, [SUI]);
      const suiObjectRefs = objectRefs[SUI.type] ?? [];
      const [suiGas] = extractGasPayment(suiObjectRefs, 100_000_000n);
      const tx = new TransactionBlock();
      tx.setGasPayment(suiGas);
      tx.setGasBudget(100_000_000);
      tx.moveCall({
        target: `${WISP_CONFIG.farm.package}::${WISP_CONFIG.farm.module}::${WISP_CONFIG.farm.functions.unstake}`,
        typeArguments: farm.isLpCoin
          ? [`${WISP_CONFIG.dex.swapPackage}::pool::WISPLP<${amount.coin.coinA?.type}, ${amount.coin.coinB?.type}>`]
          : [amount.coin.type],
        arguments: [
          tx.object(WISP_CONFIG.farm.registry),
          tx.object(farm.spNft.objectId),
          tx.pure(amount.quotient),
          tx.pure(0),
          tx.object("0x6"),
        ],
      });
      await signAndExecuteTransaction(tx);
      handleCloseWithdrawModal();
    } catch (e) {
      console.error(e);
    } finally {
      setWithdrawId(undefined);
    }
  };

  const handleBoostFarm = async (amount: Amount, farm: FarmWithNft, period: number): Promise<void> => {
    setBoostId(farm.id);
    invariant(address, "Address not found");
    invariant(farm.spNft, "spNft not found");

    try {
      const objectRefs = await getSuiObjectRefs(settings.customRPC, address, [SUI]);
      const suiObjectRefs = objectRefs[SUI.type] ?? [];
      const tokenObjectRefs = veWispData.objects;

      const [suiGas] = extractGasPayment(suiObjectRefs, 100_000_000n);
      const tx = new TransactionBlock();
      tx.setGasPayment(suiGas);
      tx.setGasBudget(100_000_000);
      tx.moveCall({
        target: `${WISP_CONFIG.farm.package}::${WISP_CONFIG.farm.module}::${WISP_CONFIG.farm.functions.boost}`,
        typeArguments: [farm.spNft.stakingCoin.type],
        arguments: [
          tx.object(WISP_CONFIG.farm.registry),
          tx.object(farm.spNft.objectId),
          tx.makeMoveVec({ objects: tokenObjectRefs.map((i) => tx.object(i.object)) }),
          tx.pure(amount.quotient),
          tx.pure(period),
          tx.object("0x6"),
        ],
      });
      await signAndExecuteTransaction(tx);
      handleCloseBoostModal();
    } catch (e) {
      console.error(e);
    } finally {
      setBoostId(undefined);
    }
  };

  const handleUnboostFarm = async (amount: Amount, farm: FarmWithNft): Promise<void> => {
    setUnboostId(farm.id);
    invariant(address, "Address not found");
    invariant(farm.spNft, "spNft not found");

    try {
      const objectRefs = await getSuiObjectRefs(settings.customRPC, address, [SUI]);
      const suiObjectRefs = objectRefs[SUI.type] ?? [];
      const [suiGas] = extractGasPayment(suiObjectRefs, 100_000_000n);

      const tx = new TransactionBlock();
      tx.setGasPayment(suiGas);
      tx.setGasBudget(100_000_000);
      tx.moveCall({
        target: `${WISP_CONFIG.farm.package}::${WISP_CONFIG.farm.module}::${WISP_CONFIG.farm.functions.unboost}`,
        typeArguments: [farm.spNft.stakingCoin.type],
        arguments: [
          tx.object(WISP_CONFIG.farm.registry),
          tx.object(farm.spNft.objectId),
          tx.pure(amount.quotient),
          tx.pure(0),
          tx.object("0x6"),
        ],
      });
      await signAndExecuteTransaction(tx);
      handleCloseUnboostModal();
    } catch (e) {
      console.error(e);
    } finally {
      setUnboostId(undefined);
    }
  };

  return (
    <div className="relative max-w-7xl w-full mx-auto z-10">
      <div className="text-2xl sm:text-4xl font-extrabold pb-4 sm:pb-8">Yield Farming</div>
      <div className="flex justify-center">
        <div className="w-full mb-10">
          <div className="bg-dark-600 rounded-2xl divide-y divide-pNeutral-800 divide-opacity-10 overflow-hidden">
            {farmWithNftData.map((farm) => {
              const isStaked = !!farm.spNft;
              const isBoosted = !!farm.spNft && farm.spNft.boostBalance.greaterThan(0);
              return (
                <div key={farm.id}>
                  <Disclosure>
                    <Disclosure.Button className="w-full">
                      {({ open }): ReactElement => {
                        return (
                          <div
                            className={clsx(
                              "relative overflow-hidden p-4 md:px-8 md:py-4 grid grid-cols-[1fr,minmax(100px,0.5fr),20px] md:grid-cols-[1fr,minmax(100px,0.5fr),1fr,1fr,1fr,20px] gap-3",
                              open ? "border-b border-pNeutral-800 border-opacity-10 border-dashed" : "",
                            )}
                          >
                            {isStaked &&
                              (isBoosted ? (
                                <div className="absolute -right-[40px] -top-[40px] w-20 h-20 rotate-45 bg-gradient-to-r from-violet-600 to-yellow-400 text-white text-xs font-medium flex items-end justify-center pb-0.5">
                                  Boosted
                                </div>
                              ) : (
                                <div className="absolute -right-[40px] -top-[40px] w-20 h-20 rotate-45 bg-gradient-to-r from-pGreen-400 to-pGreen-500 text-white text-xs font-medium flex items-end justify-center pb-0.5">
                                  Staked
                                </div>
                              ))}
                            <div className="flex items-center space-x-2 md:space-x-4">
                              {farm.isLpCoin ? (
                                <>
                                  <MultipleAssetLogo
                                    assets={[farm.stakingCoin.coinA, farm.stakingCoin.coinB]}
                                    itemCls="!w-6 !h-6"
                                  />
                                  <div className="text-pNeutral-800 font-medium">
                                    {farm.stakingCoin.coinA?.name}-{farm.stakingCoin.coinB?.name}
                                  </div>
                                </>
                              ) : (
                                <>
                                  <AssetLogo asset={farm.stakingCoin} className="!w-6 !h-6" />
                                  <div className="text-pNeutral-800 font-medium">{farm.stakingCoin.name}</div>
                                </>
                              )}
                            </div>
                            <div className="text-right flex items-end justify-center flex-col">
                              <div className="text-sm text-pNeutral-500">APR</div>
                              <div className="font-medium mt-1">{toCommaSeparated((farm.apy * 100).toFixed(2))}%</div>
                            </div>
                            <div className="hidden md:flex text-right items-end justify-center flex-col">
                              <div className="text-sm text-pNeutral-500">Deposited</div>
                              <div className="font-medium mt-1">
                                {farm.spNft ? (
                                  <>
                                    <span className="break-all">
                                      {farm.spNft ? farm.spNft?.stakingBalance.toExact() : 0}
                                    </span>{" "}
                                    {farm.isLpCoin ? "LP" : farm.spNft.stakingCoin.name}
                                  </>
                                ) : (
                                  <div>
                                    <span className="text-sm bg-gray-600 rounded px-2 py-0.5 text-white">
                                      Not deposited
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="hidden md:flex text-right items-end justify-center flex-col">
                              <div className="text-sm text-pNeutral-500">
                                {farm.spNft?.unlockTime ? "Lock boosting" : "Boosting"}
                              </div>
                              <div className="font-medium mt-1 text-right">
                                {isBoosted ? (
                                  <>
                                    <div>
                                      {farm.spNft?.boostMultiplier
                                        ? toCommaSeparated((farm.spNft.boostMultiplier / 100).toString())
                                        : "-"}
                                      %
                                    </div>
                                    <div className="ml-auto w-full bg-gradient-to-l from-pGreen-400 to-transparent h-px"></div>
                                    <div>{farm.spNft?.boostBalance.toExact(undefined, 4)} veWisp</div>
                                  </>
                                ) : (
                                  <div>
                                    <span className="text-sm bg-gray-600 rounded px-2 py-0.5 text-white">
                                      Unboosted
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="hidden md:flex text-right items-end justify-center flex-col">
                              <div className="text-sm text-pNeutral-500">Pending Rewards</div>
                              <div className="font-medium mt-1">
                                <span className="break-all">{farm.unclaimedRewards.toExact(undefined, 4)}</span> WISP
                              </div>
                            </div>
                            <div className="flex items-center justify-center">
                              <ChevronDownIcon
                                className={clsx("w-3 h-3 transition-transform", open ? "rotate-180" : "")}
                              />
                            </div>
                          </div>
                        );
                      }}
                    </Disclosure.Button>
                    <Disclosure.Panel>
                      <div className="p-5 text-sm grid grid-cols-1 md:grid-cols-2 gap-8 bg-dark-500">
                        <div className="space-y-3">
                          {!!farm.spNft?.unlockTime && (
                            <div className="flex items-center justify-between space-x-4">
                              <div className="text-sm text-pNeutral-500">Lock Period</div>
                              <div className="font-medium text-right">
                                {BOOST_RATE.find((rate) => rate.name === farm.spNft?.lockPeriod)?.string}
                              </div>
                            </div>
                          )}
                          {!!farm.spNft?.unlockTime && (
                            <div className="flex items-center justify-between space-x-4">
                              <div className="text-sm text-pNeutral-500">Unlock Time</div>
                              <div className="font-medium text-right">
                                {moment(farm.spNft.unlockTime).format("MMMM Do YYYY, h:mm:ss a")}
                              </div>
                            </div>
                          )}

                          <div className="flex items-center justify-between space-x-4">
                            <div className="text-sm text-pNeutral-500">TVL</div>
                            <div className="font-medium text-right">
                              {intlFormatNumber(farm.tvlBySui.toExactNumber())} SUI
                            </div>
                          </div>
                          <div className="flex items-center justify-between space-x-4">
                            <div className="text-pNeutral-500">Deposited</div>
                            <div className="font-medium">
                              {farm.spNft ? (
                                <>
                                  <span className="break-all">
                                    {farm.spNft ? farm.spNft?.stakingBalance.toExact() : 0}
                                  </span>{" "}
                                  {farm.isLpCoin ? "LP" : farm.spNft.stakingCoin.name}
                                </>
                              ) : (
                                <div>
                                  <span className="text-sm bg-gray-600 rounded px-2 py-0.5 text-white">
                                    Not deposited
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                          {isBoosted && (
                            <div className="flex items-center justify-between space-x-4">
                              <div className="text-pNeutral-500">Boost multiplier</div>
                              <div className="font-medium text-right">
                                <div>
                                  {farm.spNft?.boostMultiplier
                                    ? toCommaSeparated((farm.spNft.boostMultiplier / 100).toString())
                                    : "-"}
                                  %
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="flex items-center justify-between space-x-4">
                            <div className="text-pNeutral-500">Boost amount</div>
                            <div className="font-medium text-right">
                              {farm.spNft ? (
                                <div>{farm.spNft.boostBalance.toExact(undefined, 4)} veWisp</div>
                              ) : (
                                <div>
                                  <span className="text-sm bg-gray-600 rounded px-2 py-0.5 text-white">Unboosted</span>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="flex items-center justify-between space-x-4">
                            <div className="text-sm text-pNeutral-500">Pending Rewards</div>
                            <div className="font-medium text-right">
                              <span className="break-all">{farm.unclaimedRewards.toExact(undefined, 4)}</span> WISP
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center justify-center flex-wrap">
                          {isBoosted ? (
                            <div className="w-full grid grid-cols-2 gap-4 md:px-16">
                              <Button
                                className="px-4 py-1 font-medium rounded-md"
                                disabled={stakingId === farm.id}
                                isLoading={stakingId === farm.id}
                                onClick={(): void => handleOpenStakeModal(farm)}
                              >
                                Stake
                              </Button>
                              <Button
                                className="px-4 py-1 font-medium rounded-md !bg-gradient-to-r !from-violet-600 !to-orange-500"
                                disabled={boostId === farm.id}
                                isLoading={boostId === farm.id}
                                onClick={(): void => handleOpenBoostModal(farm)}
                              >
                                Boost
                              </Button>
                              <Button
                                className="px-4 py-1 font-medium rounded-md !bg-none !bg-transparent border border-white border-opacity-10 hover:text-white"
                                disabled={unboostId === farm.id}
                                isLoading={unboostId === farm.id}
                                onClick={(): void => handleOpenUnboostModal(farm)}
                              >
                                Unboost
                              </Button>
                              <Button
                                className="px-4 py-1 font-medium rounded-md !bg-gradient-to-r !from-orange-500 !to-yellow-900"
                                disabled={claimingId === farm.id}
                                isLoading={claimingId === farm.id}
                                onClick={(): Promise<void> => handleClaimReward(farm)}
                              >
                                Claim
                              </Button>
                            </div>
                          ) : isStaked ? (
                            <div className="w-full grid grid-cols-2 gap-4 md:px-16">
                              <Button
                                className="px-4 py-1 font-medium rounded-md"
                                disabled={stakingId === farm.id}
                                isLoading={stakingId === farm.id}
                                onClick={(): void => handleOpenStakeModal(farm)}
                              >
                                Stake
                              </Button>
                              <Button
                                className="px-4 py-1 font-medium rounded-md !bg-gradient-to-r !from-violet-600 !to-orange-500"
                                disabled={boostId === farm.id}
                                isLoading={boostId === farm.id}
                                onClick={(): void => handleOpenBoostModal(farm)}
                              >
                                Boost
                              </Button>
                              <Button
                                className="px-4 py-1 font-medium rounded-md !bg-none !bg-transparent border border-white border-opacity-10 hover:text-white"
                                disabled={withdrawId === farm.id}
                                isLoading={withdrawId === farm.id}
                                onClick={(): void => handleOpenWithdrawModal(farm)}
                              >
                                Withdraw
                              </Button>
                              <Button
                                className="px-4 py-1 font-medium rounded-md !bg-gradient-to-r !from-orange-500 !to-yellow-900"
                                disabled={claimingId === farm.id}
                                isLoading={claimingId === farm.id}
                                onClick={(): Promise<void> => handleClaimReward(farm)}
                              >
                                Claim
                              </Button>
                            </div>
                          ) : (
                            <div className="w-full max-w-[250px]">
                              <Button
                                className="w-full px-4 py-1 font-medium rounded-md"
                                disabled={stakingId === farm.id}
                                isLoading={stakingId === farm.id}
                                onClick={(): void => handleOpenStakeModal(farm)}
                              >
                                Stake
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </Disclosure.Panel>
                  </Disclosure>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <StakeLPModal
        farm={stakeModalState.farm}
        isLoading={stakingId === stakeModalState.farm?.id}
        isOpen={stakeModalState.isOpen}
        onClose={handleCloseStakeModal}
        onStakeClick={handleStakeLP}
      />
      <WithdrawLPModal
        farm={withdrawModalState.farm}
        isLoading={withdrawId === withdrawModalState.farm?.id}
        isOpen={withdrawModalState.isOpen}
        onClose={handleCloseWithdrawModal}
        onWithdrawClick={handleWithdrawLP}
      />
      <BoostModal
        farm={boostModalState.farm}
        isLoading={boostId === boostModalState.farm?.id}
        isOpen={boostModalState.isOpen}
        veWispBalance={veWispData.totalBalance}
        onBoostClick={handleBoostFarm}
        onClose={handleCloseBoostModal}
      />
      <UnboostModal
        farm={unboostModalState.farm}
        isLoading={unboostId === unboostModalState.farm?.id}
        isOpen={unboostModalState.isOpen}
        onClose={handleCloseUnboostModal}
        onUnboostClick={handleUnboostFarm}
      />
    </div>
  );
}

export default YieldFarming;
