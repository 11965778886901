import { Coin } from "sdk/entities/coin";

import { Field } from "./liquidityReducer";

export enum ActionType {
  TYPE_INPUT = "TYPE_INPUT",
  SET_COINS = "SET_COINS",
  SET_2_COINS = "SET_2_COINS",
  RESET_FORM = "RESET_FORM",
  REVERT_RATE = "REVERT_RATE",
}

type TypeInputAction = {
  type: ActionType.TYPE_INPUT;
  field: Field;
  typedValue: string;
  noLiquidity: boolean;
};

type SetCoinsAction = {
  type: ActionType.SET_COINS;
  field: Field;
  coin?: Coin;
};

type Set2CoinsAction = {
  type: ActionType.SET_2_COINS;
  coinA: Coin | undefined;
  coinB: Coin | undefined;
};

type ResetFormAction = {
  type: ActionType.RESET_FORM;
};

type RevertRateAction = { type: ActionType.REVERT_RATE };

export type Action = TypeInputAction | SetCoinsAction | ResetFormAction | RevertRateAction | Set2CoinsAction;
