import * as React from "react";
import clsx from "clsx";

import { ToggleButtonContext, ToggleButtonContextValue } from "./toggleButtonContext";

type Props<T> = {
  value: T | undefined;
  onChange: (value: T) => void;
  children: React.ReactNode;
  "aria-label"?: string;
  className?: string;
};

export function ToggleButtonGroup<T>({
  "aria-label": ariaLabel,
  children,
  value,
  className,
  onChange,
}: Props<T>): React.ReactElement<Props<T>> {
  const onChangeRef = React.useRef<(value: T) => void>(onChange);

  React.useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  const handleChange = React.useCallback((value: T): void => {
    onChangeRef.current(value);
  }, []);

  const contextInitialValue: ToggleButtonContextValue = React.useMemo(
    () => ({
      value,
      setValue: handleChange,
    }),
    [handleChange, value],
  );

  return (
    <ToggleButtonContext.Provider value={contextInitialValue}>
      <div
        aria-label={ariaLabel}
        className={clsx("flex flex-row items-center justify-between space-x-2", className)}
        role="group"
      >
        {children}
      </div>
    </ToggleButtonContext.Provider>
  );
}
