import * as React from "react";
import { useWallet } from "@suiet/wallet-kit";
import { useWalletHelper } from "contexts/WalletHelperContext";
import { useWispSettings } from "contexts/WispSettingsContext";
import { usePool } from "hooks/usePools";
import { useTradeExactIn, useTradeExactOut } from "hooks/useTrade";
import { Amount } from "sdk/entities/amount";
import { Coin } from "sdk/entities/coin";
import { Pool } from "sdk/entities/pool";
import { Trade } from "sdk/entities/trade";
import { tryParseAmount } from "sdk/utils/tryParseAmount";

import { Field } from "../reducers/actions";
import { State } from "../reducers/swapReducer";

export function useSwap(state: State): {
  pool: Pool | null;
  assets: { [field in Field]?: Coin };
  balances: { [field in Field]?: Amount };
  parsedAmount: Amount | undefined;
  inputError?: string;
  trade: Trade | undefined;
  isFetching: boolean;
} {
  const {
    independentField,
    typedValue,
    [Field.INPUT]: { coin: inputCoin },
    [Field.OUTPUT]: { coin: outputCoin },
  } = state;
  const suietWallet = useWallet();
  const { settings } = useWispSettings();
  const address = suietWallet.address;

  const { fungibleBalances } = useWalletHelper();
  const { pool, isFetching: isPoolFetching } = usePool({
    coinA: inputCoin ?? null,
    coinB: outputCoin ?? null,
    rpcEndpoint: settings.customRPC,
    networkEnv: settings.networkEnv,
  });

  const [inputBalance, outputBalance] = React.useMemo(() => {
    let inpBalance: Amount | null = null;
    let outBalance: Amount | null = null;

    if (inputCoin) {
      if (!fungibleBalances?.length) {
        inpBalance = Amount.fromRawAmount(inputCoin, 0);
      } else {
        inpBalance = fungibleBalances.find((b) => b.coin.equals(inputCoin)) ?? null;
      }
    }
    if (outputCoin) {
      if (!fungibleBalances?.length) {
        outBalance = Amount.fromRawAmount(outputCoin, 0);
      } else {
        outBalance = fungibleBalances.find((b) => b.coin.equals(outputCoin)) ?? null;
      }
    }
    return [inpBalance, outBalance];
  }, [fungibleBalances, inputCoin, outputCoin]);

  const isExactIn: boolean = independentField === Field.INPUT;
  const parsedAmount = tryParseAmount(typedValue, (isExactIn ? inputCoin : outputCoin) ?? undefined);

  const { trade: tradeExactIn, amountTooSmall } =
    useTradeExactIn(pool, isExactIn ? parsedAmount : undefined, outputCoin) ?? undefined;
  const { trade: tradeExactOut } =
    useTradeExactOut(pool, inputCoin ?? undefined, isExactIn ? undefined : parsedAmount) ?? undefined;

  const trade = isExactIn ? tradeExactIn : tradeExactOut;

  const balances = {
    [Field.INPUT]: inputBalance ?? undefined,
    [Field.OUTPUT]: outputBalance ?? undefined,
  };

  const coins: { [field in Field]?: Coin } = {
    [Field.INPUT]: inputCoin ?? undefined,
    [Field.OUTPUT]: outputCoin ?? undefined,
  };

  const [balanceIn, amountIn] = [balances[Field.INPUT], trade?.maximumAmountIn(settings.slippageTolerance)];

  let inputError: string | undefined;
  if (!parsedAmount) {
    inputError = inputError ?? "Enter an amount";
  }

  if (!coins[Field.INPUT] || !coins[Field.OUTPUT]) {
    inputError = inputError ?? "Select a token";
  }

  if (address && amountIn && balanceIn && balanceIn?.lessThan(amountIn)) {
    inputError = inputError ?? `Insufficient ${inputCoin?.name} balance`;
  }

  if (!trade) {
    if (amountTooSmall) {
      inputError = inputError ?? `${inputCoin?.name} amount is too small`;
    }
    inputError = inputError ?? "No pool found for this pair";
  }

  return {
    assets: coins,
    balances,
    parsedAmount,
    inputError,
    pool,
    trade: trade ?? undefined,
    isFetching: isPoolFetching,
  };
}
