import { ReactElement, useMemo, useReducer, useState } from "react";
import { TransactionArgument, TransactionBlock } from "@mysten/sui.js";
import { useWallet } from "@suiet/wallet-kit";
import clsx from "clsx";
import { Button } from "components/atoms/Button";
import { ArrowLeftIcon } from "components/atoms/icons/ArrowLeftIcon";
import { LiveStatusIcon } from "components/atoms/icons/LiveStatusIcon";
import { AssetInput } from "components/molecules/AssetInput";
import { useWalletHelper } from "contexts/WalletHelperContext";
import { useWispSettings } from "contexts/WispSettingsContext";
import { toCommaSeparated } from "sdk/utils/formatNumber";
import { isTooManyDecimals, tryParseAmount } from "sdk/utils/tryParseAmount";
import { SUI, WISP_SUI } from "utils/coins";
import { WISP_CONFIG } from "utils/constant";
import { extractGasPayment, getSuiObjectRefs } from "utils/getCoinObjectIds";

import { PRICE_STATUS, USER_POSITION } from "../constant";
import * as ActionHandlers from "../reducers/actionHandlers";
import { BET_TYPE } from "../reducers/actions";
import { initialState, reducer } from "../reducers/nextFormReducer";
import { Round } from "../types";
import { getStatusText } from "../utils";
import { Payout } from "./Payout";

type Props = {
  round: Round;
  className?: string;
};

export function NextPayoutCardContent({ round, className }: Props): ReactElement {
  const suietWallet = useWallet();

  const adapter = suietWallet.adapter;

  const { settings } = useWispSettings();
  const { fungibleBalances, setOpenSelectWallet, signAndExecuteTransaction } = useWalletHelper();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isBuildingTx, setIsBuildingTx] = useState<boolean>(false);

  const assetBalance = useMemo(() => {
    return fungibleBalances?.find((fb) => fb.coin.equals(WISP_SUI));
  }, [fungibleBalances]);

  const amount = useMemo(() => {
    if (!assetBalance?.coin || !state.typedValue) {
      return undefined;
    }
    return tryParseAmount(state.typedValue, WISP_SUI);
  }, [state.typedValue]);

  const errorMsg: string | null = useMemo(() => {
    if (!assetBalance || assetBalance.equalTo(0)) {
      return "Empty balance";
    }
    if (!amount) {
      return "Enter an amount";
    }
    if (assetBalance?.lessThan(amount)) {
      return "Insufficient balance";
    }
    return null;
  }, [amount, assetBalance]);

  const setBetType = (type: BET_TYPE | null): void => {
    ActionHandlers.setBetType(dispatch, type);
  };

  const handleChangeInput = (value: string): void => {
    if (isTooManyDecimals(value, WISP_SUI)) {
      return;
    }
    ActionHandlers.typeInput(dispatch, value);
  };

  const handleMaxInput = (): void => {
    if (!assetBalance) {
      return;
    }
    ActionHandlers.typeInput(dispatch, assetBalance.toExact({ groupSeparator: "" }));
  };

  const handleBet = async (): Promise<void> => {
    if (!state.betType || !amount || !suietWallet.address) {
      return;
    }
    setIsBuildingTx(true);
    try {
      const objectRefs = await getSuiObjectRefs(settings.customRPC, suietWallet.address, [SUI, amount.coin]);
      const suiObjectRefs = objectRefs[SUI.type] ?? [];
      const tokenObjectRefs = objectRefs[amount.coin.type] ?? [];
      const [suiGas] = extractGasPayment(suiObjectRefs, 100_000_000n);
      const isSui = amount.coin.equals(SUI);
      const tx = new TransactionBlock();
      let splitCoin = undefined;
      if (isSui) {
        splitCoin = tx.splitCoins(tx.gas, [tx.pure(amount.quotient.toString())])[0];
      }
      if (!isSui) {
        tx.setGasPayment(suiGas);
      }
      tx.setGasBudget(100_000_000);
      tx.moveCall({
        target: `${WISP_CONFIG.prediction.packageObject}::prediction::${
          state.betType === BET_TYPE.BULL
            ? WISP_CONFIG.prediction.functions.betBull
            : WISP_CONFIG.prediction.functions.betBear
        }`,
        typeArguments: [amount.coin.type],
        arguments: [
          tx.object(WISP_CONFIG.prediction.market),
          tx.pure(round.epoch.toString()),
          tx.makeMoveVec({
            objects: isSui
              ? ([splitCoin] as TransactionArgument[])
              : tokenObjectRefs.map((i) => {
                  return tx.object(i.objectId);
                }),
          }),
          tx.pure(amount.quotient.toString()),
        ],
      });
      await signAndExecuteTransaction(tx);
      handleChangeInput("");
    } catch (e) {
      console.error(e);
    } finally {
      setIsBuildingTx(false);
    }
  };

  const handleUnbet = async (): Promise<void> => {
    if (!suietWallet.address) {
      return;
    }
    setIsBuildingTx(true);
    try {
      const objectRefs = await getSuiObjectRefs(settings.customRPC, suietWallet.address, [SUI]);
      const suiObjectRefs = objectRefs[SUI.type] ?? [];
      const [suiGas] = extractGasPayment(suiObjectRefs, 100_000_000n);
      const tx = new TransactionBlock();
      tx.moveCall({
        target: `${WISP_CONFIG.prediction.packageObject}::${WISP_CONFIG.prediction.moduleName}::${WISP_CONFIG.prediction.functions.unBet}`,
        typeArguments: [WISP_CONFIG.prediction.coin],
        arguments: [tx.object(WISP_CONFIG.prediction.market), tx.pure(round.epoch.toString())],
      });
      tx.setGasBudget(100_000_000);
      tx.setGasPayment(suiGas);
      await signAndExecuteTransaction(tx);
    } catch (e) {
      console.error(e);
    } finally {
      setIsBuildingTx(false);
    }
  };

  const isBearlish = state.betType === BET_TYPE.BEAR;
  const isOvertime = round.lockTime.getTime() < Date.now();

  if (!isOvertime && state.betType) {
    return (
      <div className={clsx("rounded-2xl bg-dark-600 overflow-hidden p-5 space-y-6", className)}>
        <div className="flex items-center justify-between">
          <div className="text-white flex items-center space-x-4 cursor-pointer" onClick={(): void => setBetType(null)}>
            <ArrowLeftIcon className="w-6 h-6" />
            <span className="font-semibold text-lg">Set Position</span>
          </div>
          <div
            className={clsx(
              "text-sm text-white rounded px-2 py-1 font-medium cursor-pointer min-w-[80px] text-center",
              isBearlish ? "bg-pRed-500" : "bg-pGreen-500",
            )}
            onClick={(): void => {
              if (!round.position) {
                setBetType(isBearlish ? BET_TYPE.BULL : BET_TYPE.BEAR);
              }
            }}
          >
            {isBearlish ? "DOWN" : "UP"}
          </div>
        </div>
        <AssetInput
          asset={WISP_SUI}
          balance={assetBalance}
          label="commit"
          otherAsset={undefined}
          value={toCommaSeparated(state.typedValue)}
          fixedCoin
          maxAmount
          onInputChange={handleChangeInput}
          onMaxAmount={handleMaxInput}
          onSelect={(): void => {}}
        />
        <div>
          {!adapter ? (
            <Button
              className="px-6 py-3 w-full text-xl font-Poppins whitespace-pre-wrap"
              onClick={(): void => setOpenSelectWallet(true)}
            >
              Connect Wallet
            </Button>
          ) : (
            <Button
              className="px-6 py-3 w-full text-xl font-Poppins whitespace-pre-wrap"
              disabled={Boolean(errorMsg || isBuildingTx)}
              isLoading={isBuildingTx}
              onClick={handleBet}
            >
              {errorMsg ?? (isBuildingTx ? "Processing" : "Confirm")}
            </Button>
          )}
          <p className="text-sm mt-2">You won’t be able to remove or change your position once it placed</p>
        </div>
      </div>
    );
  }

  return (
    <div className={clsx("rounded-2xl bg-dark-600 overflow-hidden", className)}>
      <div className={"flex justify-between items-center space-x-2 p-4 bg-btn"}>
        <span>
          <LiveStatusIcon />
        </span>
        <span className="flex-1 uppercase font-semibold">{getStatusText(round.status)}</span>
        <div className="font-semibold text-sm ml-2">#{round.epoch}</div>
      </div>
      <div className="px-6 space-y-4">
        <Payout
          isActive={round.priceStatus === PRICE_STATUS.BULL}
          payout={round.upPayout ? round.upPayout.toFixed(2) : "0"}
          type="up"
        />

        {isOvertime ? (
          <div className="border-2 border-white border-opacity-20 rounded-3xl px-5 py-7 mx-5 text-center">
            Round processing...
          </div>
        ) : (
          <>
            <div className="space-y-1">
              <div className="font-semibold opacity-30 text-pNeutral-800 text-sm">Your Commit Status</div>
              <div className="flex space-x-2 justify-between items-center">
                <span className="grow font-semibold opacity-30 text-pNeutral-800 text-sm">You Bet</span>
                <span
                  className={clsx("font-semibold text-sm px-1.5 py-0.5 rounded", {
                    "text-pGreen-500 bg-pGreen-500 bg-opacity-10": round.position === USER_POSITION.BULL,
                    "text-pRed-500 bg-pRed-500 bg-opacity-10": round.position === USER_POSITION.BEAR,
                    "text-white bg-white bg-opacity-10": !round.position,
                  })}
                >
                  {round.position ?? "--"}
                </span>
              </div>
              <div className="flex space-x-2 justify-between items-center">
                <span className="grow font-semibold opacity-30 text-pNeutral-800 text-sm">Amount</span>
                <span
                  className={clsx("font-semibold text-sm", {
                    "text-pGreen-500": round.position === USER_POSITION.BULL,
                    "text-pRed-500": round.position === USER_POSITION.BEAR,
                    "text-white": !round.position,
                  })}
                >
                  {round.amount ? round.amount.toExact() : "0"} {round.amount?.coin?.metadata?.ticker ?? "WispSUI"}
                </span>
              </div>
            </div>

            <div className="flex space-x-2 justify-between items-center">
              <span className="grow font-semibold opacity-30 text-pNeutral-800 text-sm">Prize Amount</span>
              <span className="font-semibold text-sm text-pNeutral-800">
                {round.rewardAmount ? round.rewardAmount.toExact() : "0"}{" "}
                {round.amount?.coin?.metadata?.ticker ?? "WispSUI"}
              </span>
            </div>

            <div className="space-y-4">
              {!round.position ? (
                <>
                  <button
                    className="w-full rounded-3xl py-2 bg-pGreen-500 text-dark-600 font-semibold text-xl"
                    onClick={(): void => setBetType(BET_TYPE.BULL)}
                  >
                    Enter UP
                  </button>
                  <button
                    className="w-full rounded-3xl py-2 bg-pRed-500 text-dark-600 font-semibold text-xl"
                    onClick={(): void => setBetType(BET_TYPE.BEAR)}
                  >
                    Enter DOWN
                  </button>
                </>
              ) : (
                <>
                  {round.position === USER_POSITION.BULL ? (
                    <button
                      className="w-full rounded-3xl py-2 bg-pGreen-500 text-dark-600 font-semibold text-xl"
                      onClick={(): void => setBetType(BET_TYPE.BULL)}
                    >
                      Enter UP
                    </button>
                  ) : (
                    <button
                      className="w-full rounded-3xl py-2 bg-pRed-500 text-dark-600 font-semibold text-xl"
                      onClick={(): void => setBetType(BET_TYPE.BEAR)}
                    >
                      Enter DOWN
                    </button>
                  )}
                  <button
                    className="w-full rounded-3xl py-2 bg-orange-900 text-yellow-200 font-semibold text-xl"
                    disabled={isBuildingTx}
                    onClick={handleUnbet}
                  >
                    Remove Position
                  </button>
                </>
              )}
            </div>
          </>
        )}
        <Payout
          isActive={round.priceStatus === PRICE_STATUS.BEAR}
          payout={round.downPayout ? round.downPayout.toFixed(2) : "0"}
          type="down"
        />
      </div>
    </div>
  );
}
