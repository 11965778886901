import * as React from "react";

import { IconProps } from "./type";

export function PlusIcon(props: IconProps): React.ReactElement<IconProps> {
  return (
    <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M8.29333 18V2H12.0978V18H8.29333ZM2 11.7778V8.22222H18.3911V11.7778H2Z" fill="currentColor" />
    </svg>
  );
}
